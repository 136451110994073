import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import auth from './auth/moduleAuth'
import info from './info/moduleInfo'
import patient from './patient/modulePatient'
import todo from './todo/moduleTodo'
import application from './application/moduleApplication'
import schedule from './schedule/moduleSchedule'
import employee from './employee/moduleEmployee'
import reports from './reports/moduleReports'
import revenue from './revenue/moduleRevenue'
import analytics from './analytics/moduleAnalytics'
import notification from './notification/moduleNotification'
import wage from './wage/moduleWage'
import chat from './chat/moduleChat'
import lead from './lead/moduleLead'
import settings from './settings/moduleSettings'
import serviceCalculation from './service-calculation/moduleServiceCalculation'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth,
    info,
    patient,
    todo,
    schedule,
    application,
    employee,
    revenue,
    reports,
    analytics,
    notification,
    wage,
    chat,
    lead,
    settings,
    serviceCalculation,
    'app-ecommerce': ecommerceStoreModule,
  },
  strict: process.env.DEV,
})
