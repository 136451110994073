export default {
  designations: [],
  designation: null,
  cabinets: [],
  cabinet: null,
  schedulePatientList: [],
  schedulePatient: null,
  calendarColors: [
    'primary', 'danger', 'warning', 'success', 'info'
  ],
  calendarOptions: [],
  selectedCalendars: [],
  scheduleCalendarView: null,
  schedulePatientStatuses: [
    {
      key: 'wait',
      label: 'Ожидаем подтверждения',
      color: 'yellow',
    },
    {
      key: 'accept',
      label: 'Подтвердил прием',
      color: 'info',
    },
    {
      key: 'arrive',
      label: 'Клиент пришел',
      color: 'success',
    },
    {
      key: 'change',
      label: 'Перенос',
      color: 'orange',
    },
    {
      key: 'abort',
      label: 'Отменен',
      color: 'danger',
    },
    {
      key: 'not-arrive',
      label: 'Не пришел',
      color: 'warning',
    },
    {
      key: 'online-registration',
      label: 'Online-запись',
      color: 'purple',
    }
  ],
  eventColors: {
    primary: '#7367f0',
    success: '#79f8b1',
    info: '#a3fcfc',
    warning: '#fb8197',
    danger: '#f38a8b',
    orange: '#ffd099',
    yellow: '#eefa70',
    purple: '#e993e7',
  },
  employeeSchedule: [],
  scheduleToEdit: null,
  shift: null,
  shifts: [],
  schedulerDoctorsGroups: [],
  doctorShiftsForAppointmentCalendar: [],
  doctorWeekendDates: [],
  selectedServiceTime: null,
  reserves: [],
  loadingDoctors: [],
  reservesCount: 0,
  loadingDoctorsCount : 0,
  patientAwaitStatus: [],
  calendarDateSelected: null,
  cabinetsForUser: [],
  activeRange: null,
  listDayExtendedCalendarDateState: null,
  componentToRender: null,
  selectedDoctorId: null,
  selectedDoctors: [],
  activeTab: null,
  newPatientAppointmentInfo: null,
  newPatientAppointmentInfoWatcher: null,
  doctors: [],
  selectedDaysInEmployeeScheduleCalendar: [],
  copiedEmployeeScheduleStatusInCalendar: null,
}
