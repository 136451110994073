export default {
  roleTypes: state => state.roleTypes,
  doctors: state => state.doctors,
  admins: state => state.admins,
  labTechnicians: state => state.lab_technicians,
  nurses: state => state.nurses,
  operators: state => state.operators,
  linesOfWork: state => state.linesOfWork,
  linesOfWorkTypes: state => state.linesOfWorkTypes,
  directions: state => state.directions,
  directionsPerPage: state => state.directionsPerPage,
  directionsPerPageCount: state => state.directionsPerPageCount,
  departmentsPerPage: state => state.departmentsPerPage,
  departmentsPerPageCount: state => state.departmentsPerPageCount,
  departments: state => state.departments,
}
