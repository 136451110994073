<template>
  <div
    class="toastification"
    @click="$emit('click-toast')"
  >
    <div class="d-flex align-items-start">
      <b-avatar
        :variant="variant"
        size="1.8rem"
        class="mr-75 flex-shrink-0"
      >
        <feather-icon
          :icon="icon"
          size="15"
        ></feather-icon>
      </b-avatar>
      <div class="d-flex flex-grow-1">
        <div>
          <h5
            v-if="title"
            class="mb-0 font-weight-bolder toastification-title"
            :class="`text-${variant}`"
            v-text="title"
          ></h5>
          <h5
            v-if="serviceName"
            class="mt-1 mb-1 text-body font-weight-bold"
          >{{ serviceName }}</h5>
          <hr>
          <div
            v-if="doctorFullName"
            class="d-flex align-items-center"
          >
            <span class="text-body mr-50 font-weight-bold">Мастер:</span>
            <small
              class="d-inline-block text-body"
              v-text="doctorFullName"
            ></small>
          </div>
          <div
            v-if="patientFullName"
            class="d-flex align-items-center"
          >
            <span class="text-body mr-50 font-weight-bold">Клиент:</span>
            <b-link @click.stop="goToPatientView"
            >
              <small
                class="d-inline-block"
                v-text="patientFullName"
              ></small>
            </b-link>
          </div>
          <div
            v-if="formattedPhone"
            class="d-flex align-items-center"
          >
            <span class="text-body mr-50 font-weight-bold">Телефон:</span>
            <small
              class="d-inline-block text-body"
              v-text="formattedPhone"
            ></small>
          </div>
          <div
            v-if="time"
            class="d-flex align-items-center"
          >
            <span class="text-body mr-50 font-weight-bold">Время:</span>
            <small
              class="d-inline-block text-body"
              v-text="time"
            ></small>
          </div>
        </div>
        <span
          class="cursor-pointer toastification-close-icon ml-auto "
          @click="$emit('close-toast')"
        >
          <feather-icon
            v-if="!hideClose"
            icon="XIcon"
            class="text-body"
          ></feather-icon>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { BAvatar, BLink } from 'bootstrap-vue'
import {computed} from '@vue/composition-api'
import router from '@/router'

export default {
  name: 'ToastificationOnlineAppointment',
  components: {
    BAvatar, BLink
  },
  props: {
    variant: {
      type: String,
      default: 'primary',
    },
    icon: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    serviceName: String,
    patientId: Number,
    doctorFullName: String,
    patientFullName: String,
    patientPhoneCode: String,
    patientPhoneNumber: String,
    time: String,
    hideClose: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {

    const formattedPhone = computed(() => {
      if (props.patientPhoneCode && props.patientPhoneNumber) {
        const number = props.patientPhoneNumber
        let result = props.patientPhoneNumber
        if (number.length === 10) {
          result = '(' + number.substring(0, 3) + ') ' + number.substring(3, 6) + '-' + number.substring(6, 8)
            + '-' + number.substring(8, 10)
        } else if (number.length === 9) {
          result = '(' + number.substring(0, 2) + ') ' + number.substring(2, 5) + '-' + number.substring(5, 7)
            + '-' + number.substring(7, 9)
        }
        return '+' + props.patientPhoneCode + ' ' + result
      }
      return null
    })

    const goToPatientView = () => {
      if (router.currentRoute.name !== 'app-user-view') {
        router.push({
          name: 'app-user-view',
          params: {
            patientId: props.patientId,
            routerBreadcrumbParams: {sourceItemLabel: props.patientFullName}
          }
        })
      }
    }

    return {
      formattedPhone,
      goToPatientView
    }
  }
}
</script>

<style lang="scss" scoped>
.toastification-close-icon,
.toastification-title {
  line-height: 26px;
}

.toastification-title {
  color: inherit;
}
</style>
