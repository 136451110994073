import Vue from 'vue'

export default {
  // Role types
  SET_ROLE_TYPES(state, roleTypes) {
    state.roleTypes = roleTypes
  },
  // Doctors
  SET_DOCTORS(state, doctors) {
    state.doctors = doctors
  },
  CLEAR_DOCTORS(state) {
    state.doctors = []
  },
  // Admins
  SET_ADMINS(state, items) {
    state.admins = items
  },
  // Lab Technicians
  SET_LAB_TECHNICIANS(state, items) {
    state.lab_technicians = items
  },
  // Nurses
  SET_NURSES(state, items) {
    state.nurses = items
  },
  // Operators
  SET_OPERATORS(state, items) {
    state.operators = items
  },
  // Lines of work
  SET_EMPLOYEE_LINES_OF_WORK(state, linesOfWork) {
    state.linesOfWork = linesOfWork
  },
  ADD_LINE_OF_WORK(state, lineOfWork) {
    state.linesOfWork.push(lineOfWork)
  },
  EDIT_LINE_OF_WORK(state, lineOfWork) {
    const index = state.linesOfWork.findIndex(pd => pd.id === lineOfWork.id)
    state.linesOfWork[index].name = lineOfWork.name
    state.linesOfWork[index].type = lineOfWork.type
  },
  REMOVE_LINE_OF_WORK(state, itemId) {
    const index = state.linesOfWork.findIndex(lineOfWork => lineOfWork.id === itemId)
    state.linesOfWork.splice(index, 1)
  },
  // Lines of work types
  SET_EMPLOYEE_LINES_OF_WORK_TYPES(state, linesOfWorkTypes) {
    state.linesOfWorkTypes = linesOfWorkTypes
  },
  // Direction
  SET_DIRECTIONS_PER_PAGE: (state, items) => state.directionsPerPage = items,
  SET_DIRECTIONS_PER_PAGE_COUNT: (state, count) => state.directionsPerPageCount = count,
  SET_DIRECTIONS: (state, items) => state.directions = items,
  ADD_DIRECTION(state, direction) {
    state.directionsPerPage.push(direction)
    state.directionsPerPageCount += 1
  },
  EDIT_DIRECTION(state, item) {
    const index = state.directionsPerPage.findIndex(d => d.id === item.id)
    Vue.set(state.directionsPerPage, index, item)
  },
  REMOVE_DIRECTION(state, itemId) {
    const index = state.directionsPerPage.findIndex(direction => direction.id === itemId)
    state.directionsPerPage.splice(index, 1)
    state.directionsPerPageCount -= 1
  },
  // Department
  SET_DEPARTMENTS_PER_PAGE: (state, items) => state.departmentsPerPage = items,
  SET_DEPARTMENTS_PER_PAGE_COUNT: (state, count) => state.departmentsPerPageCount = count,
  SET_DEPARTMENTS: (state, items) => state.departments = items,
  ADD_DEPARTMENT(state, direction) {
    state.departmentsPerPage.push(direction)
    state.departmentsPerPageCount += 1
  },
  EDIT_DEPARTMENT(state, item) {
    const index = state.departmentsPerPage.findIndex(d => d.id === item.id)
    Vue.set(state.departmentsPerPage, index, item)
  },
  REMOVE_DEPARTMENT(state, itemId) {
    const index = state.departmentsPerPage.findIndex(direction => direction.id === itemId)
    state.departmentsPerPage.splice(index, 1)
    state.departmentsPerPageCount -= 1
  },
}
