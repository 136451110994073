export default {
  patients_payments: [],
  materials_write_off: [],
  cash_register_total: null,
  total_by_line_of_work: null,
  total_by_snapshot: null,
  total_by_selling: null,
  total_by_discount: null,
  debtors: null,
  insurances: [],
  cashlessPayments: [],
  cashRegisterSections: [
    {label: 'Итого по направлениям', section: 'line-of-work'},
    {label: 'Итого по продажам', section: 'selling'},
    {label: 'Итого по скидкам', section: 'discount'},
    // {label: 'Итого по снимкам', section: 'snapshot'},
  ],
}
