export default {
  status: Object.freeze({
    WAIT: 'wait',
    ACCEPT: 'accept',
    ARRIVE: 'arrive',
    CHANGE: 'change',
    ABORT: 'abort',
    NOT_ARRIVE: 'not-arrive',
    ONLINE_REGISTRATION: 'online-registration',
  }),
}
