export default {
  SET_USERS_CHATS(state, chats) {
    state.usersChats = chats
  },
  SET_PATIENTS_CHATS(state, chats) {
    state.patientsChats = chats
  },
  SET_CONTACTS(state, contacts) {
    state.contacts = contacts
  },
  SET_ACTIVE_CHAT_INFO(state, info) {
    state.activeChatInfo = info
  },
  SET_NEW_MESSAGE(state, message) {
    state.newMessage = message
  },
  CLEAR_USER_CHAT_UNSEEN_MESSAGES_COUNT(state, chatId) {
    const idx = state.usersChats.findIndex(ch => ch.id === chatId)
    if (idx > -1) {
      state.usersChats[idx].unread_messages_number = 0
    }
  },
  CLEAR_PATIENT_CHAT_UNSEEN_MESSAGES_COUNT(state, chatId) {
    const idx = state.patientsChats.findIndex(ch => ch.id === chatId)
    if (idx > -1) {
      state.patientsChats[idx].unread_messages_number = 0
    }
  }
}
