import {
  schedulerpatient, dictionarydesignationsscheduler, workshift, schedulercalendar, scheduler
} from '@/libs/acl/entities'

export default [
  {
    header: 'Schedule',
    icon: 'CalendarIcon',
    route: 'schedule',
    action: 'read',
    children: [
      {
        route: 'schedule-cabinets',
        title: 'Cabinet',
        resource: dictionarydesignationsscheduler,
        action: 'read',
      },
      {
        route: 'schedule-designations',
        title: 'Designations',
        resource: dictionarydesignationsscheduler,
        action: 'read',
      },
      {
        route: 'schedule-calendar',
        title: 'Schedule of reception',
        resource: schedulerpatient,
        action: 'read',
      },
      {
        route: 'employee-schedule',
        title: 'Employee schedule',
        resource: [schedulercalendar, scheduler],
        action: 'read',
      },
      {
        route: 'shifts',
        title: 'Shifts',
        resource: workshift,
        action: 'read',
      }
    ]
  }
]
