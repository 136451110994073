import ability from './ability'

export const canNavigate = to => to.matched.some(route => {
  if (route.meta.resource === 'faq') {
    return true
  }
  const user = JSON.parse(localStorage.getItem('user'))
  if (route.meta.resource === 'importexport' && user.role_type === 'sys_admin') {
    return true
  }
  if (route.meta.resource === 'pricingtypevalue') {
    return user && ['sys_admin', 'root'].includes(user.role_type)
  }
  return Array.isArray(route.meta.resource)
    ? route.meta.resource.some(r => ability.can(route.meta.action || 'read', r))
    : ability.can(route.meta.action || 'read', route.meta.resource)
})

export const _ = undefined
