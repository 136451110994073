import {entityGroup} from '@/libs/acl/entities'

export default [
  {
    header: 'Analytics and reporting',
    icon: 'BarChartIcon',
    action: 'read',
    route: 'analytics-and-reporting',
    children: [
      {
        route: 'apps-analytics',
        title: 'Analytics',
        resource: entityGroup,
        action: 'read',
      },
    ]
  },
]
