import axios from "@/libs/axios";

export default {
  fetchSuppliers({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `dictionary-suppliers/`, method: 'GET',
        params: params
      })
        .then(res => {
          commit('set_suppliers', res.data.results)
          commit('set_suppliers_count', res.data.count)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  fetchOrders({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `orders/`, method: 'GET',
        params: params
      })
        .then(res => {
          commit('set_orders', res.data.results)
          commit('set_orders_count', res.data.count)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  updateSupplier({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `dictionary-suppliers/${data.id}/`, method: 'PATCH',
        data: data
      })
        .then(res => {
          commit('supplier_updated', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  createSupplier({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `dictionary-suppliers/`, method: 'POST',
        data: data
      })
        .then(res => {
          commit('supplier_created', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  fetchProducts({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `dictionary-products/`, method: 'GET',
        params: params
      })
        .then(res => {
          commit('set_products', res.data.results)
          commit('set_products_count', res.data.count)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  updateProduct({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `dictionary-products/${data.id}/`, method: 'PATCH',
        data: data
      })
        .then(res => {
          commit('product_updated', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  createProduct({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `dictionary-products/`, method: 'POST',
        data: data
      })
        .then(res => {
          commit('product_created', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },

  fetchBucketWithoutParams({commit}) {
    return new Promise((resolve, reject) => {
      axios({
        url: `bucket-product/`, method: 'GET',
      })
        .then(res => {
          commit('setBucket', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  fetchBucket({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `bucket-product/`, method: 'GET',
        params: params
      })
        .then(res => {
          commit('setBucket', res.data.results)
          commit('setBucketCount', res.data.count)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  addToBucket({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `bucket-product/`, method: 'POST',
        data: data
      })
        .then(res => {
          commit('addedToBucket', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  removeBucketItem({commit}, product) {
    return new Promise((resolve, reject) => {
      axios({
        url: `bucket-product/${product.id}`, method: 'DELETE'
      })
        .then(res => {
          commit('removeFromBucket', product)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  editBucketItem({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `bucket-product/${data.id}/`, method: 'PATCH',
        data: data
      })
        .then(res => {
          commit('editedBucketItem', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  confirmOrder({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `orders/`, method: 'POST',
        data: data
      })
        .then(res => {
          commit('orderConfirmed', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  fetchOrder({commit}, id) {
    return new Promise((resolve, reject) => {
      axios({
        url: `orders/${id}/`, method: 'GET'
      })
        .then(res => {
          commit('setOrder', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  parseExcel({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `parse-excel/`, method: 'POST', data: data
      })
        .then(res => {
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  repeatOrder({commit}, id) {
    return new Promise((resolve, reject) => {
      axios({
        url: `repeat-order/${id}/`, method: 'POST'
      })
        .then(res => {
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  fetchEmailSettings({commit}) {
    return new Promise((resolve, reject) => {
      axios({
        url: `email-settings/`, method: 'GET'
      })
        .then(res => {
          commit('setEmailSettings', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  emailSettingsAdd({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `email-settings/`, method: 'POST', data: data
      })
        .then(res => {
          commit('emailSettingsAdded', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  emailSettingsEdit({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `email-settings/${data.id}/`, method: 'PATCH', data: data
      })
        .then(res => {
          commit('emailSettingsUpdated', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  export({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: data.url + `-xlsx/`,
        method: 'GET',
        responseType: 'blob',
        params: data.queryParams
      })
        .then(res => {
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(new Blob([res.data]))
          link.setAttribute('download', data.file_name + '.xlsx')
          document.body.appendChild(link)
          link.click()
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  import({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: data.url + `-xlsx/`, method: 'POST', data: data.form_data
      })
        .then(res => resolve(res))
        .catch(error => reject({message: error}))
    })
  }
}
