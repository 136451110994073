import {entityPatientPayment, entityInsurance} from '@/libs/acl/entities'

export default [
  {
    header: 'Revenue',
    icon: 'DollarSignIcon',
    action: 'read',
    route: 'revenue',
    children: [
      {
        title: 'Cash register',
        route: 'apps-cash-register',
        resource: entityPatientPayment,
        action: 'read'
      },
      {
        title: 'Insurance',
        route: 'apps-insurance',
        resource: entityInsurance,
        action: 'read'
      },
      {
        title: 'Cashless payment',
        route: 'apps-cashless-payment',
        resource: entityPatientPayment,
        action: 'read'
      },
      {
        title: 'WriteOff',
        route: 'apps-write-off',
        resource: entityPatientPayment,
        action: 'read'
      },
      {
        title: 'Debtors',
        route: 'apps-debtors',
        resource: entityPatientPayment,
        action: 'read'
      },
    ],
  },
]
