export default {
  // Patients
  SET_PATIENTS (state, patients) {
    state.patients = patients
  },
  patient_deleted(state, id) {
    const idx = state.patients.findIndex(p => p.id === id)
    state.patients.splice(idx, 1)
  },
  SET_PATIENT (state, patient) {
    state.patient = patient
  },
  SET_PATIENTS_FOR_DROPDOWN(state, patients) {
    state.patientsForDropdown = patients
  },
  SET_PATIENT_VISUAL_INSPECTION (state, inspection) {
    state.visualInspection = inspection
  },
  SET_PATIENT_DISEASES (state, diseases) {
    state.diseases = diseases
  },
  set_patients_total_count (state, total) {
    state.totalCount = total
  },
  cache_patient_personal_data (state, patient) {
    state.cachedPersonalData = patient
  },
  patient_added (state, patient) {
    state.patient = patient
    state.patients.push(patient)
  },
  patient_edited (state, patient) {
    const index = state.patients.findIndex(p => p.id === patient.id)
    if (index > -1) {
      Object.keys(state.patients[index]).forEach(key => state.patients[index][key] = patient[key])
    }
    state.patient = patient
  },
  CLEAR_PATIENT(state) {
    state.patient = {}
  },
  clear_cached_patient (state) {
    state.cachedPersonalData = {}
  },
  clear_visual_inspection (state) {
    state.visualInspection = {}
  },
  clear_diseases (state) {
    state.diseases = {}
  },
  patient_visual_inspection_added (state, inspection) {
    state.visualInspection = inspection
  },
  patient_visual_inspection_edited (state, inspection) {
    state.visualInspection = inspection
  },
  patient_common_diseases_added (state, diseases) {
    state.diseases = diseases
  },
  patient_common_diseases_edited (state, diseases) {
    state.diseases = diseases
  },
  SET_HISTORY (state, histories) {
    state.histories = histories
  },
  // Pictures
  SET_PICTURES (state, pictures) {
    state.pictures = pictures
  },
  SET_PICTURE (state, picture) {
    state.picture = picture
  },
  picture_added (state, picture) {
    state.pictures.push(picture)
  },
  picture_edited (state, picture) {
    const index = state.pictures.findIndex(p => p.id === picture.id)
    Object.keys(state.pictures[index]).forEach(key => {
      state.pictures[index][key] = picture[key]
    })
  },
  picture_deleted (state, id) {
    const index = state.pictures.findIndex(p => p.id === id)
    state.pictures.splice(index, 1)
  },
  // Teeth
  set_teeth(state, teeth) {
    state.teeth = teeth
  },
  prepare_teeth_map(state, rowNumbers) {
    state.teethMap = []
    rowNumbers.forEach(row => {
      const teethRow = []
      row.forEach(number => {
        const tooth = state.teeth.find(tooth => tooth.number === number)
        if (tooth) {
          teethRow.push(tooth)
        }
      })
      state.teethMap.push(teethRow)
    })
  },
  set_teeth_status(state, statuses) {
    state.teethStatuses = statuses
  },
  updated_teeth_status(state, status) {
    const index = state.teethStatuses.findIndex(s => s.id === status.id)
    Object.keys(status).forEach(key => {
      state.teethStatuses[index][key] = status[key]
    })
  },
  added_teeth_status(state, status) {
    state.teethStatuses.push(status)
  },
  set_patient_dental_status_list(state, list) {
    state.patientDentalStatusList = list
  },
  added_dental_status(state, status) {
    state.patientDentalStatusList.push(status)
  },
  edited_dental_status(state, status) {
    const index = state.patientDentalStatusList.findIndex(s => status.id === s.id)
    Object.keys(state.patientDentalStatusList[index]).forEach(key => {
      state.patientDentalStatusList[index][key] = status[key]
    })
  },
  DELETE_DENTAL_STATUS(state, id) {
    const idx = state.patientDentalStatusList.findIndex(status => status.id === id)
    state.patientDentalStatusList.splice(idx, 1)
  },
  set_patient_active_tab(state, tab) {
    state.patientActiveTab = tab
  },
  // Treatment plans
  SET_PATIENT_TREATMENT_PLAN(state, plan) {
    state.patientTreatmentPlan = plan
  },
  // Diaries
  SET_PATIENT_TREATMENT_DIARY(state, diaries) {
    state.patientTreatmentDiary = diaries
  },
  ADD_PATIENT_TREATMENT_DIARY(state, diary) {
    state.patientTreatmentDiary.push(diary)
  },
  TREATMENT_DIARY_EDIT(state, diary) {
    const idx = state.patientTreatmentDiary.findIndex(d => d.id === diary.id)
    Object.keys(diary).forEach(key => {
      state.patientTreatmentDiary[idx][key] = diary[key]
    })
  },
  SET_TREATMENT_DIARY_TO_EDIT(state, diary) {
    state.treatmentDiaryToEdit = diary
  },
  CLEAT_TREATMENT_DIARY_TO_EDIT(state) {
    state.treatmentDiaryToEdit = {}
  },
  DELETE_TREATMENT_DIARY(state, treatment_diary_id) {
    const idx = state.patientTreatmentDiary.findIndex(treatment_diary => treatment_diary.id === treatment_diary_id)
    state.patientTreatmentDiary.splice(idx, 1)
  },
  SET_PATIENT_TREATMENT_DIARIES_PER_PAGE(state, diaries) {
    state.patientTreatmentDiariesPerPage = diaries
  },
  SET_PATIENT_TREATMENT_DIARIES_PER_PAGE_TOTAL_COUNT(state, count) {
    state.patientTreatmentDiariesPerPageTotalCount = count
  },
  // advisory opinion
  ADD_ADVISORY_OPINION(state, advisory_opinion) {
    state.advisoryOpinions.push(advisory_opinion)
  },
  EDIT_ADVISORY_OPINION(state, advisory_opinion) {
    const idx = state.advisoryOpinions.findIndex(ao => ao.id === advisory_opinion.id)
    Object.keys(advisory_opinion).forEach(key => {
      state.advisoryOpinions[idx][key] = advisory_opinion[key]
    })
  },
  SET_ADVISORY_OPINIONS(state, advisory_opinions) {
    state.advisoryOpinions = advisory_opinions
  },
  ADVISORY_OPINION_DELETE(state, advisoryOpinionId) {
    const index = state.advisoryOpinions.findIndex(ad => ad.id === advisoryOpinionId)
    state.advisoryOpinions.splice(index, 1)
  },
  // payment history
  SET_PAYMENT_HISTORY(state, payment_history) {
    state.paymentHistory = payment_history
  },
  // insurance history
  SET_INSURANCE_HISTORY(state, insuranceHistory) {
    state.insuranceHistory = insuranceHistory
  },
  // Lead history
  SET_LEAD_HISTORY_PER_PAGE(state, items) {
    state.leadHistoryPerPage = items
  },
  SET_LEAD_HISTORY_PER_PAGE_TOTAL_COUNT(state, count) {
    state.leadHistoryPerPageTotalCount = count
  },
  // Physician protocol history
  SET_PHYSICIAN_PROTOCOL_HISTORY(state, items) {
    state.physicianProtocolHistory = items
  },
  PHYSICIAN_PROTOCOL_DELETE(state, id) {
    const index = state.physicianProtocolHistory.findIndex(ad => ad.id === id)
    state.physicianProtocolHistory.splice(index, 1)
  },
  // Synevo analyses order
  SET_SYNEVO_ANALYSES_ORDERS(state, items) {
    state.synevoAnalysesOrders = items
  },
  ADD_SYNEVO_ANALYSIS_ORDER(state, item) {
    state.synevoAnalysesOrders.push(item)
  },
  EDIT_SYNEVO_ANALYSIS_ORDER(state, item) {
    const idx = state.synevoAnalysesOrders.findIndex(order => order.id === item.id)
    Object.keys(item).forEach(key => {
      state.synevoAnalysesOrders[idx][key] = item[key]
    })
  },
  SYNEVO_ANALYSIS_ORDER_DELETE(state, id) {
    const index = state.synevoAnalysesOrders.findIndex(ad => ad.id === id)
    state.synevoAnalysesOrders.splice(index, 1)
  },
  // Outpatient surgery
  ADD_OUTPATIENT_SURGERY(state, item) {
    state.outpatientSurgeryHistory.push(item)
  },
  EDIT_OUTPATIENT_SURGERY(state, item) {
    const idx = state.outpatientSurgeryHistory.findIndex(row => row.id === item.id)
    Object.keys(item).forEach(key => state.outpatientSurgeryHistory[idx][key] = item[key])
  },
  SET_OUTPATIENT_SURGERY_HISTORY(state, items) {
    state.outpatientSurgeryHistory = items
  },
  OUTPATIENT_SURGERY_DELETE(state, id) {
    const index = state.outpatientSurgeryHistory.findIndex(item => item.id === id)
    state.outpatientSurgeryHistory.splice(index, 1)
  },
}
