export default {
  // Document settings
  getDocumentSettings: state => state.documentSettings,
  // Patient reception schedule settings
  getPatientReceptionScheduleSettings: state => state.patientReceptionScheduleSettings,
  // Cash register settings
  getCashRegisterSettings: state => state.cashRegisterSettings,
  // Price settings
  getPriceSettings: state => state.priceSettings,
  // Treatment diary settings
  getTreatmentDiarySettings: state => state.treatmentDiarySettings,
  // Employee settings
  getEmployeeSettings: state => state.employeeSettings,
}
