import Vue from 'vue'
import store from '@/store'
import { getId } from '@/composables/currentUser'
import ToastificationCustom from '@/views/components/toasts/ToastificationCustom.vue'

export const usersLimitNotify = () => {
  showCenteredNotify(
    'users-limit-notify',
    'Внимание!',
    'Вы превысили максимально доступное количество пользователей для вашего тарифа.',
    require('@/assets/images/sign/warning.png')
  )
}

export const subscriptionExpiredNotify = text => {
  showCenteredNotify(
    'subscription-expired-notify',
    'Уведомление!',
    text,
    require('@/assets/images/sign/warning.png')
  )
}

export const systemNotify = notif => {
  showCenteredNotify(
    'system-notify',
    'Уведомление!',
    notif.text,
    notif.imageSrc ? notif.imageSrc : require('@/assets/images/illustration/faq-illustrations.svg'),
    '200px',
    notif.id ? notif.id : null
  )
}

const showCenteredNotify = (type, title, text, imageSrc, imageHeight = '50px', notifId = null) => {
  Vue.$toast({
    component: ToastificationCustom,
    props: {
      title: title,
      text: text,
      imageSrc: imageSrc,
      imageHeight: imageHeight,
    },
    listeners: {
      'click-toast': () => processToastClick(type, notifId)
    },
  }, {closeOnClick: true, timeout: false, position: 'top-center', toastClassName: 'centered'})
}

const processToastClick = (type, notifId) => {
  const toastClassName = 'Vue-Toastification__toast centered'
  const toastID = `toast-centered-${document.getElementsByClassName(toastClassName).length}`
  const elems = document.getElementsByClassName(toastClassName)
  if (elems.length) {
    const elem = elems[0]
    const className = 'margin-correction'
    if (!elem.classList.contains(className)) {
      elem.classList.add(className)
    }
  }
  Vue.$toast.dismiss(toastID)
  if (type === 'subscription-expired-notify') {
    store.dispatch('notification/confirmSeenSubscriptionExpiredNotification', getId())
  } else if (type === 'system-notify' && notifId) {
    store.dispatch('notification/confirmSeenNotification', notifId)
  }
}
