import axios from '@/libs/axios'

export default {
  // Lead history
  getLeadHistoryPerPage({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'lead-history/',
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_LEAD_HISTORY_PER_PAGE', res.data.results)
          commit('SET_LEAD_HISTORY_TOTAL_COUNT', res.data.count)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  getLeadHistoryByLeadIdPerPage({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `lead/${params.id}/history`,
        method: 'GET',
        params: params.queryParams
      })
        .then(res => {
          commit('SET_LEAD_HISTORY_BY_LEAD_ID_PER_PAGE', res.data.results)
          commit('SET_LEAD_HISTORY_BY_LEAD_ID_TOTAL_COUNT', res.data.count)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  addLeadHistoryItem({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `lead-history/`,
        method: 'POST',
        data: params
      })
        .then(res => {
          commit('ADD_LEAD_HISTORY_ITEM', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  editLeadHistoryItem({commit}, item) {
    return new Promise((resolve, reject) => {
      axios({
        url: `lead-history/${item.id}/`,
        method: 'PATCH',
        data: item
      })
        .then(res => {
          commit('EDIT_LEAD_HISTORY_ITEM', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  deleteLeadHistoryItem({commit}, id) {
    return new Promise((resolve, reject) => {
      axios({
        url: `lead-history/${id}/`,
        method: 'DELETE'
      })
        .then(res => {
          commit('DELETE_LEAD_HISTORY_ITEM', id)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  // Lead
  fetchLeads({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'lead/',
        method: 'GET',
        params: params.queryParams
      })
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  getLead({commit}, id) {
    return new Promise((resolve, reject) => {
      axios({
        url: `lead/${id}/`,
        method: 'GET'
      })
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  addLead({commit}, item) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'lead/',
        method: 'POST',
        data: item
      })
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  editLead({commit}, item) {
    return new Promise((resolve, reject) => {
      axios({
        url: `lead/${item.id}/`,
        method: 'PATCH',
        data: item
      })
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  createPatientFromLead({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `lead-to-patient/`,
        method: 'POST',
        data: data
      })
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  }
}
