import Vue from 'vue'

export default {
  // Lead history
  SET_LEAD_HISTORY_PER_PAGE(state, items) {
    state.leadHistoryPerPage = items
  },
  SET_LEAD_HISTORY_TOTAL_COUNT(state, count) {
    state.leadHistoryTotalCount = count
  },
  SET_LEAD_HISTORY_BY_LEAD_ID_PER_PAGE(state, items) {
    state.leadHistoryByLeadIdPerPage = items
  },
  SET_LEAD_HISTORY_BY_LEAD_ID_TOTAL_COUNT(state, count) {
    state.leadHistoryByLeadIdTotalCount = count
  },
  ADD_LEAD_HISTORY_ITEM(state, item) {
    state.leadHistoryByLeadIdPerPage.unshift(item)
    state.leadHistoryByLeadIdTotalCount += 1
  },
  EDIT_LEAD_HISTORY_ITEM(state, item) {
    let index = state.leadHistoryByLeadIdPerPage.findIndex(element => element.id === item.id)
    Vue.set(state.leadHistoryByLeadIdPerPage, index, item)
  },
  DELETE_LEAD_HISTORY_ITEM(state, id) {
    let index = state.leadHistoryByLeadIdPerPage.findIndex(ad => ad.id === id)
    state.leadHistoryByLeadIdPerPage.splice(index, 1)
    state.leadHistoryByLeadIdTotalCount -= 1
    index = state.leadHistoryPerPage.findIndex(ad => ad.id === id)
    state.leadHistoryPerPage.splice(index, 1)
    state.leadHistoryTotalCount -= 1
  },
}
