import {entityPatientPayment, entityInsurance} from '@/libs/acl/entities'

export default [
  {
    path: '/apps/cash-register',
    name: 'apps-cash-register',
    component: () => import('@/views/apps/revenue/cash-register/CashRegister.vue'),
    meta: {
      contentClass: 'cash-register-application',
      resource: entityPatientPayment,
      action: 'read',
      title: 'Cash register'
    },
  },
  {
    path: '/apps/write-off',
    name: 'apps-write-off',
    component: () => import('@/views/apps/revenue/write_off/WriteOff.vue'),
    meta: {
      contentClass: 'write-off-application',
      resource: entityPatientPayment,
      action: 'read',
      title: 'WriteOff'
    },
  },
  {
    path: '/apps/debtors',
    name: 'apps-debtors',
    component: () => import('@/views/apps/revenue/debtors/Debtors.vue'),
    meta: {
      contentClass: 'debtors-application',
      resource: entityPatientPayment,
      action: 'read',
      title: 'Debtors'
    },
  },
  {
    path: '/apps/insurance',
    name: 'apps-insurance',
    component: () => import('@/views/apps/revenue/insurance/Insurance.vue'),
    meta: {
      contentClass: 'insurance-application',
      resource: entityInsurance,
      action: 'read',
      title: 'Insurance'
    },
  },
  {
    path: '/apps/cashless-payment',
    name: 'apps-cashless-payment',
    component: () => import('@/views/apps/revenue/сashless-payment/CashlessPayment.vue'),
    meta: {
      contentClass: 'cashless-payment-application',
      resource: entityPatientPayment,
      action: 'read',
      title: 'Cashless payment'
    },
  },
]
