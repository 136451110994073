import appConstants from '@/constants/constants'
import router from '@/router'
import store from '@/store'
import {mixins} from '@/mixins'

export const processNewChatMessage = data => {
  if (
    [
      appConstants.router.chat.APP_USER_CHAT, appConstants.router.chat.APP_PATIENT_CHAT
    ].includes(router.currentRoute.name)
  ) {
    store.commit('chat/SET_NEW_MESSAGE', data)
  } else {
    const message = data.message
    if (message.type === 'Viber') {
      mixins.methods.newMessageViberNotify(
        'Viber: ' + message.sender_full_name, message.text, 'primary', {timeout: false}
      )
    } else {
      mixins.methods.newMessageNotify(message.sender_full_name, message.text, 'info', {timeout: false})
    }
  }
}
