export default {
  // Analytics -> Revenue
  analyticsRevenueTotalByDirections: null,
  // Analytics -> Patient appointment schedule
  analyticsPatientAppointmentScheduleTotalByHowDidKnow: null,
  analyticsPatientAppointmentScheduleTotalByHowToContact: null,
  analyticsPatientAppointmentScheduleTotalByStatuses: null,
  // Analytics -> Patients
  analyticsPatientsTotalByDentalServices: null,
  analyticsPatientsTotalPatientsCount: null,
  // Analytics -> Leads
  analyticsLeadsInfo: null,
}
