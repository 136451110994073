export default {
  roleTypes: [],
  doctors: [],
  admins: [],
  lab_technicians: [],
  nurses: [],
  operators: [],
  linesOfWork: [],
  linesOfWorkTypes: [],
  directionsPerPage: [],
  directionsPerPageCount: 0,
  directions: [],
  departmentsPerPage: [],
  departmentsPerPageCount: 0,
  departments: [],
}
