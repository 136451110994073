import { entityIntegrator } from '@/libs/acl/entities'

export default [
  {
    path: '/apps/analytics',
    name: 'apps-analytics',
    component: () => import('@/views/analytics/Analytics.vue'),
    meta: {
      contentClass: 'analytics-application',
      resource: entityIntegrator,
      action: 'read',
      title: 'Analytics'
    },
  },
  {
    path: '/apps/analytics/revenue/total-by-directions',
    name: 'apps-analytics-revenue-total-by-directions',
    component: () => import('@/views/analytics/sections/revenue/TotalByDirections.vue'),
    meta: {
      contentClass: 'analytics-revenue-total-by-directions-application',
      resource: entityIntegrator,
      action: 'read',
      title: 'Total by directions'
    },
  },
  {
    path: '/apps/analytics/patient-appointment-schedule/total-by-how-did-know',
    name: 'apps-analytics-patient-appointment-schedule-total-by-how-did-know',
    component: () => import('@/views/analytics/sections/patient-appointment-schedule/TotalByHowDidKnow.vue'),
    meta: {
      contentClass: 'analytics-patient-appointment-schedule-total-by-how-did-know-application',
      resource: entityIntegrator,
      action: 'read',
      title: 'Total by how did know'
    },
  },
  {
    path: '/apps/analytics/patient-appointment-schedule/total-by-how-to-contact',
    name: 'apps-analytics-patient-appointment-schedule-total-by-how-to-contact',
    component: () => import('@/views/analytics/sections/patient-appointment-schedule/TotalByHowToContact.vue'),
    meta: {
      contentClass: 'analytics-patient-appointment-schedule-total-by-how-to-contact-application',
      resource: entityIntegrator,
      action: 'read',
      title: 'Total by how to contact'
    },
  },
  {
    path: '/apps/analytics/patient-appointment-schedule/total-by-statuses',
    name: 'apps-analytics-patient-appointment-schedule-total-by-statuses',
    component: () => import('@/views/analytics/sections/patient-appointment-schedule/TotalByStatuses.vue'),
    meta: {
      contentClass: 'analytics-patient-appointment-schedule-total-by-statuses-application',
      resource: entityIntegrator,
      action: 'read',
      title: 'Total by statuses'
    },
  },
  {
    path: '/apps/analytics/patients/total-by-dental-services',
    name: 'apps-analytics-patients-total-by-dental-services',
    component: () => import('@/views/analytics/sections/patients/TotalByDentalServices.vue'),
    meta: {
      contentClass: 'analytics-patients-total-by-dental-services-application',
      resource: entityIntegrator,
      action: 'read',
      title: 'Total by services'
    },
  },
  {
    path: '/apps/analytics/patients/total-patients-count',
    name: 'apps-analytics-patients-total-patients-count',
    component: () => import('@/views/analytics/sections/patients/TotalPatientsCount.vue'),
    meta: {
      contentClass: 'analytics-patients-total-patients-count-application',
      resource: entityIntegrator,
      action: 'read',
      title: 'Total by patients count'
    },
  },
  {
    path: '/apps/analytics/leads/info',
    name: 'apps-analytics-leads-info',
    component: () => import('@/views/analytics/sections/leads/LeadsInfo.vue'),
    meta: {
      contentClass: 'analytics-leads-info-application',
      resource: entityIntegrator,
      action: 'read',
      title: 'Total by leads'
    },
  },
]
