export default {
  getPatients: state => state.patients,
  getCachedPersonalData: state => state.cachedPersonalData,
  getPatientVisualInspectionList: state => state.patientVisualInspectionList,
  getVisualInspection: state => state.visualInspection,
  getPatientCommonDiseasesList: state => state.patientCommonDiseasesList,
  getPatientDentalStatusList: state => state.patientDentalStatusList,
  getPatientActiveTab: state => state.patientActiveTab,
  getDiseases: state => state.diseases,
  getTotalCount: state => state.totalCount,
  getPatient: state => state.patient,
  getPatientsForDropdown: state => state.patientsForDropdown,
  getPicture: state => state.picture,
  getTeeth: state => state.teeth,
  getTeethMap: state => state.teethMap,
  getTeethStatuses: state => state.teethStatuses,
  getRowNumbers: state => state.rowNumbers,
  getPatientTreatmentPlan: state => state.patientTreatmentPlan,
  getPatientTreatmentDiary: state => state.patientTreatmentDiary,
  getPatientTreatmentDiariesPerPage: state => state.patientTreatmentDiariesPerPage,
  getPatientTreatmentDiariesPerPageTotalCount: state => state.patientTreatmentDiariesPerPageTotalCount,
  getTreatmentDiaryToEdit: state => state.treatmentDiaryToEdit,
  getMobilePhoneCodes: state => state.mobilePhoneCodes,
  getDocumentTypes: state => state.documentTypes,
  getPatientAdvisoryOpinions: state => state.advisoryOpinions,
  getLeadHistoryPerPage: state => state.leadHistoryPerPage,
  getLeadHistoryPerPageTotalCount: state => state.leadHistoryPerPageTotalCount,
  getPhysicianProtocolHistory: state => state.physicianProtocolHistory,
  getSynevoAnalysesOrders: state => state.synevoAnalysesOrders,
  getPatientSynevoAnalysisOrderStatus: state => state.patientSynevoAnalysisOrderStatus,
  getOutpatientSurgeryHistory: state => state.outpatientSurgeryHistory,
}
