<template>
  <div
    class="toastification-system"
    @click="$emit('click-toast')"
  >
    <div class="toastification">
      <div class="d-flex align-items-center flex-column justify-content-center">
        <b-img
          :src="imageSrc"
          :height="imageHeight"
        ></b-img>
        <div class="d-flex flex-grow-1">
          <div class="d-flex align-items-center justify-content-center flex-column">
            <div
              class="mb-1 toastification-title"
              v-text="title"
            ></div>
            <div class="mb-1 text-body text-center">{{ text }}</div>
            <b-button variant="primary">Ок</b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {BAvatar, BImg, BLink, BButton} from 'bootstrap-vue'

export default {
  name: 'ToastificationCustom',
  components: {
    BImg, BAvatar, BLink, BButton
  },
  emits: ['click-toast'],
  props: {
    title: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    imageSrc: {
      type: String,
      required: true
    },
    imageHeight: {
      type: String,
      required: true
    },
  }
}
</script>

<style lang='scss' scoped>

.toastification-system {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  .toastification {
    display: flex;
    flex-direction: column;
    .toastification-title {
      margin-top: 1rem;
      font-size: 1.5rem;
      font-weight: inherit;
      color: #5e5873;
      margin-bottom: 0.5rem;
    }
  }
}

</style>
