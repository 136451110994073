export default [
    {
        path: '/profit-cash',
        name: 'profit-cash',
        component: () => import('@/views/profit/Cash'),
    },
    {
        path: '/profit-insurance',
        name: 'profit-insurance',
        component: () => import('@/views/profit/Insurance'),
    },
    {
        path: '/profit-cashless',
        name: 'profit-cashless',
        component: () => import('@/views/profit/Cashless'),
    }
]
