import {getUserData} from '@/auth/utils'
import {$themeConfig} from '@themeConfig'

export const getAvatarSrc = () => {
  const userData = getUserData()
  return userData && userData.avatar ? userData.avatar : null
}

export const getLogoSrc = () => {
  const userData = getUserData()
  let logo = $themeConfig.app.appLogoHorizontalProfmanHealth
  if (userData && userData.logo_svg && userData.logo_svg.url) {
    logo = userData.logo_svg.url
  } else if (userData && userData.program_version === 'profman_dental') {
    logo = $themeConfig.app.appLogoHorizontalProfmanDental
  }
  return logo
}

export const getId = () => {
  const userData = getUserData()
  return userData && userData.id ? userData.id : null
}
