import axios from '@/libs/axios'

export default {
  // Reports
  getReportForm039({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'report-form-039/',
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_REPORT_FORM_039', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  getReportForm037({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'report-form-037/' + params.doctor_id + '/',
        method: 'GET',
        params: params.query_params
      })
        .then(res => {
          commit('SET_REPORT_FORM_037', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  exportReportForm037({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'report-form-037-export/' + params.doctor_id + '/',
        method: 'GET',
        params: params.query_params,
        responseType: 'blob',
      })
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  exportReportForm039({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'report-form-039-export/',
        method: 'GET',
        params: params,
        responseType: 'blob',
      })
        .then(res => resolve(res))
        .catch(error => reject({message: error}))
    })
  },
  // Outpatient Surgeries
  getOutpatientSurgeries({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'outpatient-surgery/',
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_OUTPATIENT_SURGERIES', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  getOutpatientSurgeriesExportExcel({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `outpatient-surgery-export/`, method: 'GET',
        params: params,
        responseType: 'blob',
      })
        .then(res => resolve(res))
        .catch(error => reject({message: error}))
    })
  },
}
