import axios from '@/libs/axios'

export default {
  // Patients
  getPatientsPerPage({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'patients/',
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_PATIENTS', res.data.results)
          commit('set_patients_total_count', res.data.count)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  getPatients({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'patients/',
        method: 'GET',
        params: params
      })
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  getPatientsList({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'patients-list/',
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_PATIENTS_FOR_DROPDOWN', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  addPatient({commit}, patient) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'patients/', method: 'POST', data: patient
      })
        .then(res => {
          commit('patient_added', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  editPatient({commit}, patient) {
    return new Promise((resolve, reject) => {
      axios({
        url: `patients/${patient.id}/`, method: 'PATCH', data: patient
      })
        .then(res => {
          commit('patient_edited', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  addPatientVisualInspection({commit}, inspection) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'visual-inspection-patient/', method: 'POST', data: inspection
      })
        .then(res => {
          commit('patient_visual_inspection_added', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  editPatientVisualInspection({commit}, inspection) {
    return new Promise((resolve, reject) => {
      axios({
        url: `visual-inspection-patient/${inspection.id}/`, method: 'PATCH', data: inspection
      })
        .then(res => {
          commit('patient_visual_inspection_edited', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  addPatientVisualDiseases({commit}, diseases) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'common-diseases-patient/', method: 'POST', data: diseases
      })
        .then(res => {
          commit('patient_common_diseases_added', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  editPatientVisualDiseases({commit}, diseases) {
    return new Promise((resolve, reject) => {
      axios({
        url: `common-diseases-patient/${diseases.id}/`, method: 'PATCH', data: diseases
      })
        .then(res => {
          commit('patient_common_diseases_edited', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  getPatient({commit}, id) {
    return new Promise((resolve, reject) => {
      axios({
        url: `patients/${id}/`, method: 'GET'
      })
        .then(res => {
          commit('SET_PATIENT', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  deletePatient({commit}, id) {
    return new Promise((resolve, reject) => {
      axios({
        url: `patients/${id}/`, method: 'DELETE'
      })
        .then(res => {
          commit('patient_deleted', id)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  getPatientVisualInspection({commit}, id) {
    return new Promise((resolve, reject) => {
      axios({
        url: `patient/visual-inspection/${id}`, method: 'GET'
      })
        .then(res => {
          if (res.data && res.data.length > 0) {
            commit('SET_PATIENT_VISUAL_INSPECTION', res.data[0])
          } else {
            commit('SET_PATIENT_VISUAL_INSPECTION', {})
          }
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  getPatientDiseases({commit}, id) {
    return new Promise((resolve, reject) => {
      axios({
        url: `patient/common-diseases/${id}`, method: 'GET'
      })
        .then(res => {
          if (res.data && res.data.length > 0) {
            commit('SET_PATIENT_DISEASES', res.data[0])
          } else {
            commit('SET_PATIENT_DISEASES', {})
          }
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  getDentalPictures({commit}) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'dental-pictures/', method: 'GET'
      })
        .then(res => {
          commit('SET_PICTURES', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  getPatientDentalPictures({commit}, patientId) {
    return new Promise((resolve, reject) => {
      axios({
        url: `patient/dental-pictures/${patientId}`, method: 'GET'
      })
        .then(res => {
          commit('SET_PICTURES', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  getPatientHistory({commit}, patientId) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'patient/history-request', method: 'GET', params: {
          patient_id: patientId
        }
      })
        .then(res => {
          commit('SET_HISTORY', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  getDentalPicture({commit}, id) {
    return new Promise((resolve, reject) => {
      axios({
        url: `dental-pictures/${id}/`, method: 'GET'
      })
        .then(res => {
          commit('SET_PICTURES', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  addDentalPicture({commit}, formData) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'dental-pictures/',
        method: 'POST',
        data: formData,
        headers: {'Content-Type': 'multipart/form-data'}
      })
        .then(res => {
          commit('picture_added', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  editDentalPicture({commit}, formData) {
    return new Promise((resolve, reject) => {
      axios({
        url: `dental-pictures/${formData.get('id')}/`,
        method: 'PATCH',
        data: formData,
        headers: {'Content-Type': 'multipart/form-data'}
      })
        .then(res => {
          commit('picture_edited', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  deleteDentalPicture({commit}, id) {
    return new Promise((resolve, reject) => {
      axios({
        url: `dental-pictures/${id}/`,
        method: 'DELETE',
      })
        .then(res => {
          commit('picture_deleted', id)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  // Outpatient surgery
  addPatientOutpatientSurgery({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'patient-outpatient-surgery/',
        method: 'POST',
        data: data
      })
        .then(res => {
          commit('ADD_OUTPATIENT_SURGERY', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  editPatientOutpatientSurgery({commit}, item) {
    return new Promise((resolve, reject) => {
      axios({
        url: `patient-outpatient-surgery/${item.id}/`,
        method: 'PATCH',
        data: item
      })
        .then(res => {
          commit('EDIT_OUTPATIENT_SURGERY', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  deletePatientOutpatientSurgery({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`patient-outpatient-surgery/${id}/`)
        .then(res => {
          commit('OUTPATIENT_SURGERY_DELETE', id)
          resolve(res)
        }).catch(error => reject({message: error}))
    })
  },
  getPatientOutpatientSurgeryHistory({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `patient/${params.patient_id}/outpatient-surgery/?type=${params.type}`,
        method: 'GET'
      })
        .then(res => {
          commit('SET_OUTPATIENT_SURGERY_HISTORY', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  // Teeth
  getTeeth({commit}) {
    return new Promise((resolve, reject) => {
      axios({
        url: `teeth/`,
        method: 'GET',
      })
        .then(res => {
          commit('set_teeth', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  getTeethStatus({commit}) {
    return new Promise((resolve, reject) => {
      axios({
        url: `status-teeth/`,
        method: 'GET',
      })
        .then(res => {
          commit('set_teeth_status', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  editTeethStatus({commit}, status) {
    return new Promise((resolve, reject) => {
      axios({
        url: `status-teeth/${status.id}/`,
        method: 'PATCH',
        data: status
      })
        .then(res => {
          commit('updated_teeth_status', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  getPatientDentalStatusList({commit}, patientId) {
    return new Promise((resolve, reject) => {
      axios({
        url: `patient/dental-status-patient/${patientId}`, method: 'GET'
      })
        .then(res => {
          commit('set_patient_dental_status_list', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  addDentalStatusPatient({commit}, vision) {
    return new Promise((resolve, reject) => {
      axios({
        url: `dental-vision-patient/`,
        method: 'POST',
        data: vision
      })
        .then(res => {
          commit('added_dental_status', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  editDentalStatusPatient({commit}, status) {
    return new Promise((resolve, reject) => {
      axios({
        url: `dental-vision-patient/${status.id}/`,
        method: 'PATCH',
        data: status
      })
        .then(res => {
          commit('edited_dental_status', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  deleteDentalStatus({commit}, id) {
    return new Promise((resolve, reject) => {
      axios({
        url: `dental-vision-patient/${id}/`,
        method: 'DELETE'
      })
        .then(res => {
          commit('DELETE_DENTAL_STATUS', id)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  getPatientDentalStatusTeethHistory({commit}, patientId) {
    return new Promise((resolve, reject) => {
      axios({
        url: `patient/dental-status-teeth-history/${patientId}/`,
        method: 'GET'
      })
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  // Treatment Plans
  getPatientTreatmentPlan({commit}, patientId) {
    return new Promise((resolve, reject) => {
      axios({
        url: `patient/general-treatment-plan-patient/${patientId}`,
        method: 'GET',
      })
        .then(res => {
          let data = null
          if (res.data) {
            data = res.data
          }
          commit('SET_PATIENT_TREATMENT_PLAN', data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  createTreatmentPlan({commit}, plan) {
    return new Promise((resolve, reject) => {
      axios({
        url: `general-treatment-plan/`,
        method: 'POST',
        data: plan
      })
        .then(res => {
          commit('SET_PATIENT_TREATMENT_PLAN', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  editTreatmentPlan({commit}, plan) {
    return new Promise((resolve, reject) => {
      axios({
        url: `general-treatment-plan/${plan.id}/`,
        method: 'PATCH',
        data: plan
      })
        .then(res => {
          commit('SET_PATIENT_TREATMENT_PLAN', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  getTreatmentPlanExportExcel({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `treatment-plan-export/`, method: 'GET',
        params: params,
        responseType: 'blob',
      })
        .then(res => resolve(res))
        .catch(error => reject({message: error}))
    })
  },
  // Treatment Diaries
  addTreatmentDiary({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios({
        url: `treatment-diary/`,
        method: 'POST',
        data: payload
      })
        .then(res => {
          commit('ADD_PATIENT_TREATMENT_DIARY', res.data)
          commit('SET_TREATMENT_DIARY_TO_EDIT', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  getTreatmentDiary({commit}, id) {
    return new Promise((resolve, reject) => {
      axios({
        url: `treatment-diary/${id}/`,
        method: 'GET'
      })
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  editTreatmentDiary({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios({
        url: `treatment-diary/${payload.id}/`,
        method: 'PATCH',
        data: payload
      })
        .then(res => {
          commit('TREATMENT_DIARY_EDIT', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  getPatientTreatmentDiaries({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios({
        url: `patient/treatment-diary-patient/${payload.patientId}`,
        method: 'GET',
        params: payload
      })
        .then(res => {
          commit('SET_PATIENT_TREATMENT_DIARY', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  getPatientTreatmentDiariesPerPage({commit}, params) {
    if (params.id) {
      return new Promise((resolve, reject) => {
        axios({
          url: `patient/treatment-diary-patient-per-page/${params.id}`,
          method: 'GET',
          params: params.queryParams
        })
          .then(res => {
            commit('SET_PATIENT_TREATMENT_DIARIES_PER_PAGE', res.data.results)
            commit('SET_PATIENT_TREATMENT_DIARIES_PER_PAGE_TOTAL_COUNT', res.data.count)
            resolve(res)
          })
          .catch(error => {
            reject({message: error})
          })
      })
    }
    return null
  },
  deletePatientTreatmentDiary({commit}, id) {
    return new Promise((resolve, reject) => {
      axios({
        url: `treatment-diary/${id}/`,
        method: 'DELETE'
      })
        .then(res => {
          commit('DELETE_TREATMENT_DIARY', id)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  closePatientTreatmentDiary({commit}, id) {
    return new Promise((resolve, reject) => {
      axios({
        url: `patient/treatment-diary/${id}/close`,
        method: 'PATCH'
      })
        .then(res => {
          commit('TREATMENT_DIARY_EDIT', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  copyTreatmentDiary({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'copy-treatment-diary/',
        method: 'POST',
        data: data
      })
        .then(res => {
          commit('ADD_PATIENT_TREATMENT_DIARY', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  // Advisory opinion
  getAdvisoryOpinions({commit}, patientId) {
    return new Promise((resolve, reject) => {
      axios({
        url: `patient/advisory-opinion/${patientId}`,
        method: 'GET'
      })
        .then(res => {
          commit('SET_ADVISORY_OPINIONS', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  addAdvisoryOpinion({commit}, advisory_opinion) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'patient-advisory-opinion/',
        method: 'POST',
        data: advisory_opinion
      })
        .then(res => {
          commit('ADD_ADVISORY_OPINION', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  editAdvisoryOpinion({commit}, advisory_opinion) {
    return new Promise((resolve, reject) => {
      axios({
        url: `patient-advisory-opinion/${advisory_opinion.id}/`,
        method: 'PATCH',
        data: advisory_opinion
      })
        .then(res => {
          commit('EDIT_ADVISORY_OPINION', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  deleteAdvisoryOpinion({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`patient-advisory-opinion/${id}`)
        .then(res => {
          commit('ADVISORY_OPINION_DELETE', id)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  // Payment history
  getPaymentHistory({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `patient/payment-history/${params.patientId}/`,
        method: 'GET',
        params: params.queryParams
      })
        .then(res => {
          commit('SET_PAYMENT_HISTORY', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  // Insurance history
  getInsuranceHistory({commit}, patientId) {
    return new Promise((resolve, reject) => {
      axios({
        url: `patient/insurance-history/${patientId}/`,
        method: 'GET'
      })
        .then(res => {
          commit('SET_INSURANCE_HISTORY', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  // Lead history
  getPatientLeadHistoryPerPage({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `patient/lead-history/${params.id}/`,
        method: 'GET',
        params: params.queryParams
      })
        .then(res => {
          commit('SET_LEAD_HISTORY_PER_PAGE', res.data.results)
          commit('SET_LEAD_HISTORY_PER_PAGE_TOTAL_COUNT', res.data.count)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  // Physician protocol history
  getPhysicianProtocolHistory({commit}, patientId) {
    return new Promise((resolve, reject) => {
      axios({
        url: `patient/${patientId}/physician-protocol/`,
        method: 'GET'
      })
        .then(res => {
          commit('SET_PHYSICIAN_PROTOCOL_HISTORY', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  // Physician protocol
  addPhysicianProtocol({commit}, formData) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'patient-physician-protocol/',
        method: 'POST',
        data: formData,
        headers: {'Content-Type': 'multipart/form-data'}
      })
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  editPhysicianProtocol({commit}, formData) {
    return new Promise((resolve, reject) => {
      axios({
        url: `patient-physician-protocol/${formData.get('id')}/`,
        method: 'PATCH',
        data: formData,
        headers: {'Content-Type': 'multipart/form-data'}
      })
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  getPhysicianProtocol({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.get(`patient-physician-protocol/${id}/`)
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  deletePhysicianProtocol({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`patient-physician-protocol/${id}/`)
        .then(res => {
          commit('PHYSICIAN_PROTOCOL_DELETE', id)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  copyPhysicianProtocol({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'copy-patient-physician-protocol/',
        method: 'POST',
        data: data
      })
        .then(res => resolve(res))
        .catch(error => reject({message: error}))
    })
  },
  // Synevo analysis order
  getSynevoAnalysisOrder({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `patient/${params.id}/synevo-analysis-order/`,
        method: 'GET',
        params: params.queryParams
      })
        .then(res => {
          commit('SET_SYNEVO_ANALYSES_ORDERS', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  addSynevoAnalysisOrder({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('patient-synevo-analysis-order/', payload)
        .then(res => {
          commit('ADD_SYNEVO_ANALYSIS_ORDER', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  editSynevoAnalysisOrder({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.patch(`patient-synevo-analysis-order/${item.id}/`, item)
        .then(res => {
          commit('EDIT_SYNEVO_ANALYSIS_ORDER', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  cancelSynevoAnalysisOrder({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.patch(`patient-synevo-analysis-order/${item.id}/cancel`, item)
        .then(res => {
          commit('EDIT_SYNEVO_ANALYSIS_ORDER', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  resumeSynevoAnalysisOrder({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.patch(`patient-synevo-analysis-order/${item.id}/resume`, item)
        .then(res => {
          commit('EDIT_SYNEVO_ANALYSIS_ORDER', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  deleteSynevoAnalysisOrder({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`patient-synevo-analysis-order/${id}/`)
        .then(res => {
          commit('SYNEVO_ANALYSIS_ORDER_DELETE', id)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  // Show patient phone request
  addShowPatientPhoneRequest({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('send-pass-for-show-patient-phone/', payload)
        .then(res => resolve(res))
        .catch(error => reject({message: error}))
    })
  },
  editShowPatientPhoneRequest({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.patch(`send-pass-for-show-patient-phone/${item.id}/`, item)
        .then(res => resolve(res))
        .catch(error => reject({message: error}))
    })
  },
}
