import patients from './patients'
import schedule from './schedule'
import dictionary from './dictionary'
import application from './application'
import todo from './todo'
import analyticsReporting from './analyticsReporting'
import sendingOutNotifications from './sendingOutNotifications'
import wage from './wage'
import revenue from './revenue'
import lead from './lead'
import serviceCalculation from './serviceCalculation'

// Array of sections
export default [
  ...patients,
  ...lead,
  ...dictionary,
  ...application,
  ...schedule,
  ...analyticsReporting,
  ...revenue,
  ...sendingOutNotifications,
  ...wage,
  ...serviceCalculation,
  ...todo,
]
