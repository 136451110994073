import moment from 'moment';

export const treatmentDiaryMixins = {
  methods: {
    init() {
      this.initMoment()
    },
    initMoment() {
      moment.locale('ru')
    },
    treatmentDiaryTitle(treatmentDiary, selectedTreatmentPlansNames) {
      this.init()
      const diaryDate = moment(parseInt(treatmentDiary.date)).format('lll')
      const masterFullName = treatmentDiary.master ? treatmentDiary.master.full_name : ''
      const items = []
      if (diaryDate) {
        items.push(diaryDate)
      }
      if (masterFullName) {
        items.push(masterFullName)
      }
      if (selectedTreatmentPlansNames) {
        items.push(selectedTreatmentPlansNames)
      }
      return items.join(' | ')
    }
  }
}
