import ToastificationContent from '@core/components/toastification/ToastificationContent'
import moment from 'moment'
import 'jspdf-autotable'
import {jsPDF} from 'jspdf'
import store from '@/store'
import {getUserData} from '@/auth/utils'
import Vue from 'vue'
import ToastificationOnlineAppointment from '@/views/components/toasts/ToastificationOnlineAppointment.vue'
import {format} from '@vicimpa/rubles'
import {processNewChatMessage} from '@/composables/chat'
import {subscriptionExpiredNotify, systemNotify} from '@/composables/notificationHelper'

export const mixins = {
  methods: {
    notify(title, msg, type, options = {}, events = {}) {
      Vue.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: 'BellIcon',
          text: msg,
          variant: type,
        },
        listeners: events
      }, options)
    },
    oldSystemNotify(notify) {
      this.notify(
        'Уведомление!', notify.text, 'info',
        {closeOnClick: true, timeout: false},
        {
          'click-toast': () => store.dispatch('notification/confirmSeenNotification', notify.id),
          'close-toast': () => store.dispatch('notification/confirmSeenNotification', notify.id),
        }
      )
    },
    onlineAppointmentNotify(data) {
      Vue.$toast({
        component: ToastificationOnlineAppointment,
        props: {
          title: data.title,
          icon: 'GlobeIcon',
          serviceName: data.service_name,
          patientId: data.patient_id,
          doctorFullName: data.doctor_full_name,
          patientFullName: data.patient_full_name,
          patientPhoneCode: data.patient_phone_code,
          patientPhoneNumber: data.patient_phone_number,
          time: data.time,
          variant: 'info',
        },
        listeners: {}
      }, {closeOnClick: true, timeout: false})
    },
    newMessageNotify(title, msg, type, options = {}) {
      Vue.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: 'MessageSquareIcon',
          text: msg,
          variant: type,
        }
      }, options)
    },
    newMessageViberNotify(title, msg, type, options = {}) {
      Vue.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: 'MessageSquareIcon',
          text: msg,
          variant: type,
        }
      }, options)
    },
    successOperationNotify(msg) {
      this.notify('Операция выполнена', msg, 'success', {timeout: 4000})
    },
    failedOperationNotify(msg) {
      this.notify('Операция не выполнена', msg, 'danger', {timeout: 4000})
    },
    failedOperationEternalNotify(msg, title = 'Операция не выполнена') {
      this.notify(title, msg, 'danger', {closeOnClick: true, timeout: false})
    },
    warningOperationNotify(msg) {
      this.notify('Операция не выполнена', msg, 'warning', {timeout: 4000})
    },
    findIndexesWithoutCalculation(countIndex, maxIndex, divideDigit, compareTo) {
      let calculated = (compareTo / (divideDigit / countIndex)) / countIndex
      if (calculated > maxIndex) {
        calculated = calculated / 2
      }
      if (Number.isInteger(calculated)) {
        return Array.from({length: countIndex}, () => calculated)
      }
      return []
    },
    findDentalIndexesByCalculation(countIndex, maxIndex, divideDigit, compareTo) {
      let result = this.findIndexesWithoutCalculation(countIndex, maxIndex, divideDigit, compareTo)
      if (result.length > 0) {
        return result
      }

      for (let n = 0; n < maxIndex; n++) {
        for (let i = 1; i <= countIndex; i++) {
          result = Array.from({length: countIndex - i}, () => n)
          for (let j = 0; j < i; j++) {
            result.push(n + 1)
            if ((result.reduce((a, b) => a + b, 0) * (divideDigit / countIndex)).toFixed(1) == compareTo) {
              return result
            }
          }
        }
      }
      return Array.from({length: countIndex}, () => 0)
    },
    getOhiDescription (index) {
      if (index === 0) {
        return ''
      }
      if (index < 0.5) {
        return 'Хорошая'
      }
      if (index <= 1.5 && index >= 0.5) {
        return 'Удовлетворительная'
      }
      if (index <= 2.5 && index >= 1.5) {
        return 'Неудовлетворительная'
      }
      if (index > 2.5) {
        return 'Плохая'
      }
    },
    getKpiDescription (index) {
      if (index == 0) {
        return 'Отличный'
      }
      if (index <= 1 && index != 0) {
        return 'Риск к заболеванию'
      }
      if (index > 1 && index <= 2) {
        return 'Легкий'
      }
      if (index > 2 && index <= 3.5) {
        return 'Средний'
      }
      if (index > 5) {
        return 'Тяжелый'
      }
    },
    formatPrice(price, withCurrency) {
      const defaultNumberOfDecimalPlaces = 2
      let numberOfDecimalPlaces = this.getNumberOfDecimalPlaces()
      let isRoundingDownEnabled = this.isRoundingDownEnabled()
      price = price ? parseFloat(price) : 0
      numberOfDecimalPlaces = numberOfDecimalPlaces ? numberOfDecimalPlaces : defaultNumberOfDecimalPlaces
      if (price && isRoundingDownEnabled) {
        const parts = price.toString().split('.')
        if (parts.length === 2) {
          const fractionalPart = parts[1]
          if (fractionalPart.length > numberOfDecimalPlaces) {
            price = `${parts[0]}.${fractionalPart.slice(0, numberOfDecimalPlaces)}`
          } else {
            price = price ? parseFloat(price).toFixed(numberOfDecimalPlaces) : 0
          }
        }
      } else {
        if (price) {
          let stringPrice = price.toString()
          const priceLength = stringPrice.length
          const index = priceLength - 1
          const lastNumber = parseInt(stringPrice[index])
          if (numberOfDecimalPlaces > defaultNumberOfDecimalPlaces && lastNumber === 5) {
            stringPrice = stringPrice.slice(0, index) + '6'
          }
          stringPrice = parseFloat(stringPrice)
          price = stringPrice ? stringPrice.toFixed(numberOfDecimalPlaces) : 0
        }
      }
      price = numberOfDecimalPlaces > defaultNumberOfDecimalPlaces ? parseFloat(price) : price
      return withCurrency ? this.getPriceWithCurrency(price) : price.toString()
    },
    formatDate(date) {
      return moment(parseInt(date)).format('YYYY-MM-DD HH:mm')
    },
    formatDateToSeconds(date) {
      return moment(parseInt(date)).format('YYYY-MM-DD HH:mm:ss')
    },
    formatAsDate(date) {
      return moment(parseInt(date)).format('L')
    },
    formatDateAsDayMonthYear(date) {
      return moment(parseInt(date)).format('DD.MM.YYYY')
    },
    getPriceWithCurrency(price) {
      return price.toString() + ' руб.'
    },
    getPdfDocumentHeader(document, integrator, font, pdfMargin) {
      let currentYPosition = 10
      const fontSize = 8
      const rowHeight = 4
      document.setFont(font)
      document.setFontSize(fontSize)

      if (integrator.logo_png) {
        const img = new Image()
        img.src = integrator.logo_png.url
        const height = 20
        const scaling_y = height / integrator.logo_png.height
        const width = integrator.logo_png.width * scaling_y
        document.addImage(img, 'png', pdfMargin, currentYPosition, width, height)
        currentYPosition += height + rowHeight
      }

      if (integrator.name) {
        const integratorName = this.formatText(integrator.name)
        document.text(integratorName, pdfMargin, currentYPosition)
        currentYPosition += rowHeight
      }

      if (integrator.license_number) {
        document.text('Лиц. МЗ РБ: ' + integrator.license_number, pdfMargin, currentYPosition)
        currentYPosition += rowHeight
      }

      if (integrator.address) {
        document.text('Адрес: ' + this.formatText(integrator.address), pdfMargin, currentYPosition)
        currentYPosition += rowHeight
      }

      if (integrator.phone) {
        const phone = integrator.phone[0] !== '+' && integrator.phone.slice(0, 3) === '375'
          ? '+' + integrator.phone : integrator.phone
        document.text('Тел.: ' + phone, pdfMargin, currentYPosition)
        currentYPosition += rowHeight
      }

      if (integrator.current_account) {
        document.text('Расчетный счет: № ' + integrator.current_account, pdfMargin, currentYPosition)
        currentYPosition += rowHeight
      }

      if (integrator.bank) {
        document.text(integrator.bank, pdfMargin, currentYPosition)
        currentYPosition += rowHeight
      }

      let credentials = []
      if (integrator.bic) {
        credentials.push('БИК ' + integrator.bic)
      }

      if (integrator.unp) {
        credentials.push('УНП ' + integrator.unp)
      }

      if (credentials.length) {
        document.text(credentials.join(', '), pdfMargin, currentYPosition)
        currentYPosition += rowHeight
      }

      return document
    },
    getPdfDocumentHeaderCredentialsCount(integrator) {
      let counter = 0
      const credentials = [
        'name', 'phone', 'address', 'current_account', 'bank', 'bic', 'license_number'
      ]
      for (let index = 0; index < credentials.length; index++) {
        if (integrator[credentials[index]]) {
          ++counter
        }
      }
      return counter
    },
    getPositionAfterHeader(integrator, minYPosition, hasRightHeader) {
      const lineHeight = 4
      const headerCredentialsCount = this.getPdfDocumentHeaderCredentialsCount(integrator)

      let pdfCurrentPositionY = 0
      if (!integrator.logo_png) {
        pdfCurrentPositionY = minYPosition + ((headerCredentialsCount - 1) * lineHeight) - 24
        pdfCurrentPositionY = pdfCurrentPositionY < minYPosition && hasRightHeader ? minYPosition : pdfCurrentPositionY
      } else if (headerCredentialsCount > 1) {
        pdfCurrentPositionY = minYPosition + ((headerCredentialsCount - 1) * lineHeight)
      } else {
        pdfCurrentPositionY = minYPosition
      }

      return pdfCurrentPositionY
    },
    async createContractAboutProvisionOfPaidDentalCareForTreatmentDiary(treatmentDiary, patient) {
      const userData = getUserData()
      if (userData) {
        await this.$store.dispatch('info/getIntegrator', userData.integrator_id).then(response => {
          const integrator = response.data
          if (!treatmentDiary || !patient || !integrator) {
            this.notify(
              'Ошибка формирования договора о предоставлении услуг',
              'Пожалуйста, обратитесь в службу технической поддержки',
              'danger'
            )
            return false
          }
          const pdfFontNormal = 'OpenSans-Regular'
          const pdfFontBold = 'OpenSans-Bold'
          const pdfMargin = 8
          const pdfFontSize = 10
          const pdfWidth = 210
          let pdfRowHeight = 7
          const clinicName = integrator.name ? this.formatText(integrator.name) : ''
          let pdfCurrentPositionY = 0
          let pdfFile = new jsPDF({orientation: 'portrait', unit: 'mm', format: 'a4', putOnlyUsedFonts: true})

          // header
          pdfFile = this.getPdfDocumentHeader(pdfFile, integrator, pdfFontNormal, pdfMargin)
          pdfCurrentPositionY = this.getPositionAfterHeader(integrator, 42, false)
          pdfFile.setFontSize(pdfFontSize)
          pdfFile.setFont(pdfFontBold)
          pdfFile.text(
            'Договор на оказание услуг',
            pdfWidth / 2,
            pdfCurrentPositionY,
            'center'
          )
          pdfCurrentPositionY += pdfRowHeight
          pdfFile.setFont(pdfFontNormal)
          pdfFile.text(moment(parseInt(treatmentDiary.date)).format('L'), pdfMargin, pdfCurrentPositionY)

          pdfCurrentPositionY += (pdfRowHeight * 2)

          let citizen = 'Гражданин '
          let citizenWidth = pdfFile.getTextWidth(citizen)
          pdfFile.text(citizen, pdfMargin, pdfCurrentPositionY)

          pdfFile.setFont(pdfFontBold)
          pdfRowHeight = 5
          let patientName = patient.full_name ? patient.full_name : ''
          let patientNameWidth = pdfFile.getTextWidth(patientName + ', ')
          pdfFile.text(patientName + ', ', pdfMargin + citizenWidth, pdfCurrentPositionY)
          pdfFile.setFont(pdfFontNormal)

          pdfFile.text(
            'именуемый в дальнейшем "Заказчик", с одной стороны и',
            pdfMargin + citizenWidth + patientNameWidth,
            pdfCurrentPositionY
          )

          pdfCurrentPositionY += pdfRowHeight
          pdfFile.setFont(pdfFontBold)
          let clinicWord = clinicName + " "
          let clinicWordWidth = pdfFile.getTextWidth(clinicWord)
          pdfFile.text(clinicWord, pdfMargin, pdfCurrentPositionY)
          pdfFile.setFont(pdfFontNormal)

          pdfFile.text(
            'именуемое в дальнейшем "Исполнитель" с другой стороны, заключили',
            pdfMargin + clinicWordWidth,
            pdfCurrentPositionY
          )
          pdfCurrentPositionY += pdfRowHeight

          pdfFile.text('договор о нижеследующем:', pdfMargin, pdfCurrentPositionY)
          pdfCurrentPositionY += (pdfRowHeight * 2)

          pdfFile.text('1. ПРЕДМЕТ ДОГОВОРА.', pdfWidth / 2, pdfCurrentPositionY, 'center')
          pdfCurrentPositionY += (pdfRowHeight + 1)
          let lines = pdfFile.splitTextToSize(
            'Заказчик полностью доверяет, а Исполнитель принимает на себя оказание услуг, гарантирует качество услуг при выполнении следующих взаимных обязательств:',
            pdfWidth - (pdfMargin * 2)
          )
          pdfFile.text(lines, pdfMargin, pdfCurrentPositionY)

          pdfCurrentPositionY += (pdfRowHeight * lines.length)

          pdfFile.text('2. ОБЯЗАННОСТИ СТОРОН.', pdfWidth / 2, pdfCurrentPositionY, 'center')
          pdfCurrentPositionY += pdfRowHeight

          pdfFile.setFont(pdfFontBold)
          pdfFile.text('Исполнитель обязан:', pdfMargin, pdfCurrentPositionY)
          pdfFile.setFont(pdfFontNormal)

          pdfCurrentPositionY += pdfRowHeight

          lines = pdfFile.splitTextToSize(
            '2.1. Информировать Заказчика о состоянии его зубочелюстной системы и возможных методах лечения выявленных нарушений.',
            pdfWidth - (pdfMargin * 2)
          )

          pdfFile.text(lines, pdfMargin, pdfCurrentPositionY)

          pdfCurrentPositionY += (pdfRowHeight * lines.length)

          pdfFile.text(
            '2.2. Информировать Заказчика о сути предлагаемого лечения и возможных осложнениях.',
            pdfMargin,
            pdfCurrentPositionY
          )

          pdfCurrentPositionY += pdfRowHeight

          lines = pdfFile.splitTextToSize(
            '2.3. Исполнитель гарантирует выполнение операций и манипуляций в соответствии с современными стандартами качества лечения.',
            pdfWidth - (pdfMargin * 2)
          )

          pdfCurrentPositionY += (pdfRowHeight * lines.length)

          pdfFile.text('2.4. Проводить регулярные осмотры в течение всего лечения.', pdfMargin, pdfCurrentPositionY)

          pdfCurrentPositionY += pdfRowHeight

          pdfFile.text(
            '2.5. Согласовывать дату проведения операций, дальнейших осмотров и этапов лечения.',
            pdfMargin,
            pdfCurrentPositionY
          )

          pdfCurrentPositionY += (pdfRowHeight * 2)

          pdfFile.setFont(pdfFontBold)
          pdfFile.text('Заказчик обязан:', pdfMargin, pdfCurrentPositionY)
          pdfFile.setFont(pdfFontNormal)

          pdfCurrentPositionY += pdfRowHeight

          pdfFile.text('2.6. Выполнять врачебные рекомендации.', pdfMargin, pdfCurrentPositionY)

          pdfCurrentPositionY += pdfRowHeight

          lines = pdfFile.splitTextToSize(
            '2.7. Посещать мастера в назначенное время для проведения контрольных осмотров и продолжения лечения.',
            pdfWidth - (pdfMargin * 2)
          )

          pdfFile.text(lines, pdfMargin, pdfCurrentPositionY)

          pdfCurrentPositionY += (pdfRowHeight * lines.length)

          lines = pdfFile.splitTextToSize(
            '2.8. В случае возникновения осложнений или иных проблем, связанных с лечением, незамедлительно обратиться к Исполнителю, не прибегая к помощи других лечебных учреждений.',
            pdfWidth - (pdfMargin * 2)
          )

          pdfFile.text(lines, pdfMargin, pdfCurrentPositionY)

          pdfCurrentPositionY += (pdfRowHeight * lines.length)

          lines = pdfFile.splitTextToSize(
            '2.9. Предоставить достоверную информацию о состоянии здоровья, перенесенных заболеваниях и их хронических формах.',
            pdfWidth - (pdfMargin * 2)
          )

          pdfFile.text(lines, pdfMargin, pdfCurrentPositionY)

          pdfCurrentPositionY += (pdfRowHeight * (lines.length + 1))

          pdfFile.text('3. СТОИМОСТЬ РАБОТ И ПОРЯДОК РАСЧЕТОВ.', pdfWidth / 2, pdfCurrentPositionY, 'center')

          pdfCurrentPositionY += pdfRowHeight

          lines = pdfFile.splitTextToSize(
            '3.1. Заказчик производит оплату за лечение в зависимости от объема выполненных работ, изложенных в приложении 1.',
            pdfWidth - (pdfMargin * 2)
          )

          pdfFile.text(lines, pdfMargin, pdfCurrentPositionY)

          pdfCurrentPositionY += (pdfRowHeight * lines.length)

          lines = pdfFile.splitTextToSize(
            '3.2. Расчет производится через кассовый аппарат исполнителя или перечисление на расчетный счет Исполнителя согласно выставленного Исполнителем счета.',
            pdfWidth - (pdfMargin * 2)
          )

          pdfFile.text(lines, pdfMargin, pdfCurrentPositionY)

          pdfCurrentPositionY += (pdfRowHeight * (lines.length + 1))

          pdfFile.text('4. ОСОБЫЕ УСЛОВИЯ.', pdfWidth / 2, pdfCurrentPositionY, 'center')

          pdfCurrentPositionY += pdfRowHeight

          lines = pdfFile.splitTextToSize(
            '4.1. При угрожающих жизни и здоровью клиента состояния, изменение объема и соответственно стоимости медицинской помощи, производится по усмотрению Исполнителя, а в остальных  случаях - по согласованию сторон.',
            pdfWidth - (pdfMargin * 2)
          )

          pdfFile.text(lines, pdfMargin, pdfCurrentPositionY)

          pdfCurrentPositionY += (pdfRowHeight * lines.length)

          lines = pdfFile.splitTextToSize(
            '4.2. По вине Заказчика (не явка на осмотр, не соблюдение рекомендаций мастера, гигиенических мероприятий и т.п.) - Исполнитель финансовой ответственности не несет.',
            pdfWidth - (pdfMargin * 2)
          )

          pdfFile.text(lines, pdfMargin, pdfCurrentPositionY)

          pdfCurrentPositionY += (pdfRowHeight * lines.length)


          lines = pdfFile.splitTextToSize(
            '4.3. Заказчик выражает полное согласие на связь, в том числе направление уведомлений, запросов и информации, предложений, акций которые касаются непосредственной деятельности Исполнителя посредством e-mail, мессенджеров Viber, Telegram, WhatsApp, почтовой связи и иных каналов связи.',
            pdfWidth - (pdfMargin * 2)
          )

          pdfFile.text(lines, pdfMargin, pdfCurrentPositionY)

          pdfCurrentPositionY += (pdfRowHeight * (lines.length + 1))

          let executor = 'Исполнитель '
          pdfFile.text(executor, pdfMargin, pdfCurrentPositionY)
          pdfFile.line(
            pdfMargin + pdfFile.getTextWidth(executor),
            pdfCurrentPositionY,
            pdfMargin + pdfFile.getTextWidth(executor) + 40,
            pdfCurrentPositionY
          )

          let customer = 'Заказчик '
          let marginFromCenter = 15
          pdfFile.text(customer, marginFromCenter + (pdfWidth / 2), pdfCurrentPositionY)
          pdfFile.line(
            marginFromCenter + (pdfWidth / 2) + pdfFile.getTextWidth(customer),
            pdfCurrentPositionY,
            pdfWidth - pdfMargin,
            pdfCurrentPositionY
          )

          pdfFile.save('Договор на оказание услуг - ' + patient.full_name + '.pdf')
        })
      }
    },
    async createActOfServicesRenderedForTreatmentDiary(treatmentDiary, patient, discountPercent) {
      const userData = getUserData()
      if (userData) {
        await this.$store.dispatch('info/getIntegrator', userData.integrator_id).then(response => {
          const integrator = response.data
          if (!treatmentDiary || !patient || !integrator) {
            this.notify(
              'Ошибка формирования акта оказанных услуг',
              'Пожалуйста, обратитесь в службу технической поддержки',
              'danger'
            )
            return false
          }
          const pdfFontNormal = 'OpenSans-Regular'
          const pdfFontBold = 'OpenSans-Bold'
          const pdfMargin = 8
          const pdfFontSize = 10
          const pdfRowHeight = 7
          const pdfWidth = 210
          const pdfHeight = 297
          const clinicName = integrator.name ? this.formatText(integrator.name) : ''

          let pdfCurrentPositionY = 0
          let pdfFile = new jsPDF({orientation: 'portrait', unit: 'mm', format: 'a4', putOnlyUsedFonts: true})

          // header
          pdfFile = this.getPdfDocumentHeader(pdfFile, integrator, pdfFontNormal, pdfMargin)
          pdfCurrentPositionY = this.getPositionAfterHeader(integrator, 42, false)

          pdfFile.setFontSize(pdfFontSize)
          let patientName = patient.full_name

          pdfFile.setFont(pdfFontBold)
          this.setActTitle(pdfFile, integrator, treatmentDiary, pdfWidth, pdfCurrentPositionY)
          pdfFile.setFont(pdfFontNormal)

          if (integrator.city) {
            pdfCurrentPositionY += pdfRowHeight
            pdfFile.text('Место составления ' + integrator.city, pdfWidth - pdfMargin, pdfCurrentPositionY, 'right')
          }

          pdfCurrentPositionY += (pdfRowHeight * 2)

          let rightDataPosition = pdfWidth / 5
          pdfFile.setFont(pdfFontBold)
          pdfFile.text('Дата:', pdfMargin, pdfCurrentPositionY)
          pdfFile.setFont(pdfFontNormal)

          let stringDate = moment(parseInt(treatmentDiary.date)).format('L')
          pdfFile.text(stringDate, rightDataPosition, pdfCurrentPositionY)
          pdfFile.line(
            rightDataPosition,
            pdfCurrentPositionY + 1,
            rightDataPosition + pdfFile.getTextWidth(stringDate),
            pdfCurrentPositionY + 1,
          )

          pdfFile.setFont(pdfFontBold)
          let actText = 'Договор №:  '
          pdfFile.text(actText, pdfWidth / 2, pdfCurrentPositionY, 'center')
          pdfFile.setFont(pdfFontNormal)
          pdfFile.text(
            treatmentDiary.act_number
              ? treatmentDiary.act_number + ''
              : '', (pdfWidth / 2) + (pdfFile.getTextWidth(actText) / 2), pdfCurrentPositionY
          )
          pdfFile.line(
            (pdfWidth / 2) + (pdfFile.getTextWidth(actText) / 2),
            pdfCurrentPositionY + 1,
            (pdfWidth / 2) + (pdfFile.getTextWidth(actText) / 2) + 20,
            pdfCurrentPositionY + 1
          )

          pdfFile.setFont(pdfFontBold)
          pdfCurrentPositionY += pdfRowHeight

          pdfFile.text('Заказчик:', pdfMargin, pdfCurrentPositionY)
          pdfFile.setFont(pdfFontNormal)
          pdfFile.text(patientName, rightDataPosition, pdfCurrentPositionY)
          pdfFile.line(rightDataPosition, pdfCurrentPositionY + 1, pdfWidth - pdfMargin, pdfCurrentPositionY + 1)

          pdfFile.setFont(pdfFontBold)
          pdfCurrentPositionY += pdfRowHeight

          pdfFile.text('Адрес:', pdfMargin, pdfCurrentPositionY)

          pdfFile.setFont(pdfFontNormal)
          pdfFile.text(patient.address ? patient.address : '', rightDataPosition, pdfCurrentPositionY)
          pdfFile.line(
            rightDataPosition,
            pdfCurrentPositionY + 1,
            pdfWidth - pdfMargin,
            pdfCurrentPositionY + 1
          )

          if (treatmentDiary.master) {
            pdfFile.setFont(pdfFontBold)
            pdfCurrentPositionY += pdfRowHeight
            pdfFile.text('Ф.И.О мастера:', pdfMargin, pdfCurrentPositionY)
            pdfFile.setFont(pdfFontNormal)
            pdfFile.text(treatmentDiary.master.full_name + '', rightDataPosition, pdfCurrentPositionY)
            pdfFile.line(rightDataPosition, pdfCurrentPositionY + 1, pdfWidth - pdfMargin, pdfCurrentPositionY + 1)
          }

          if (treatmentDiary.masseur) {
            pdfFile.setFont(pdfFontBold)
            pdfCurrentPositionY += pdfRowHeight
            pdfFile.text('Ф.И.О массажиста:', pdfMargin, pdfCurrentPositionY)
            pdfFile.setFont(pdfFontNormal)
            pdfFile.text(treatmentDiary.masseur.full_name + '', rightDataPosition, pdfCurrentPositionY)
            pdfFile.line(rightDataPosition, pdfCurrentPositionY + 1, pdfWidth - pdfMargin, pdfCurrentPositionY + 1)
          }

          pdfCurrentPositionY += (pdfRowHeight * 2)

          // Table services title
          const title = this.getServicesTableTitleForActOfServicesRendered(clinicName)
          const titleLength = title.length
          let splittedTitleItemsCount = 0
          pdfCurrentPositionY += pdfRowHeight
          pdfFile.text(title, pdfMargin, pdfCurrentPositionY)

          let rows = [
            [
              {content: 'Номер', styles: {halign: 'center'}},
              {content: 'Наименование', styles: {halign: 'center'}},
              {content: 'Кол', styles: {halign: 'center'}},
              {content: 'Цена', styles: {halign: 'center'}},
              {content: 'Сумма', styles: {halign: 'center'}}
            ]
          ]

          treatmentDiary.dental_services_history.forEach(service => {
            rows.push([
              {content: service.number, styles: {halign: 'center'}},
              {content: service.name},
              {content: service.count, styles: {halign: 'center'}},
              {
                content: this.formatPrice(service.total_price, true),
                styles: {halign: 'center'}
              },
              {
                content: this.formatPrice(service.total_price * service.count, true),
                styles: {halign: 'center'}
              }
            ])
          })
          let totalPrice = treatmentDiary.dental_services_history.reduce(
            (totalPrice, service) =>
              totalPrice + parseFloat(this.formatPrice(service.total_price * service.count, false)),
            0
          )
          let salePrice = discountPercent ? (totalPrice - ((discountPercent / 100) * totalPrice)) : totalPrice
          rows.push([
            {content: 'Итого', styles: {halign: 'left'}, colSpan: 4},
            {content: this.formatPrice(totalPrice, true), styles: { halign: 'center' }}
          ])
          pdfFile.autoTable({
            theme: 'grid',
            startY: pdfCurrentPositionY + pdfRowHeight,
            body: rows,
            bodyStyles: {
              cellPadding: 1,
              lineColor: [0, 0, 0],
              textColor: [0, 0, 0]
            },
            styles: {
              font: pdfFontNormal,
              fontSize: pdfFontSize,
            },
            didParseCell: hook => {
              if (hook.row.index === 0) {
                hook.cell.styles.font = pdfFontBold
              }
            },
            didDrawPage: hook => {
              pdfCurrentPositionY = (hook.cursor.y + pdfRowHeight)
            }
          })

          if (pdfHeight - (pdfRowHeight * 7) < pdfCurrentPositionY) {
            pdfFile.addPage()
            pdfCurrentPositionY = pdfRowHeight * 2
          } else {
            pdfCurrentPositionY += pdfRowHeight
          }

          pdfFile.text(
            'Вышеперечисленные услуги выполнены полностью. Заказчик по объему, качеству и срокам оказания услуг',
            pdfMargin,
            pdfCurrentPositionY
          )
          pdfCurrentPositionY += pdfRowHeight
          pdfFile.text(
            'претензий не имеет. Настоящий акт является основанием к оплате оказанных услуг.',
            pdfMargin,
            pdfCurrentPositionY
          )
          pdfCurrentPositionY += (pdfRowHeight * 2)

          pdfFile.setFont(pdfFontBold)
          pdfFile.text('ИТОГО', pdfMargin, pdfCurrentPositionY)
          pdfCurrentPositionY += pdfRowHeight

          let totalText = 'Начислено:   '
          pdfFile.text(totalText, pdfMargin, pdfCurrentPositionY)

          pdfFile.setFont(pdfFontNormal)

          pdfFile.text(
            this.formatPrice(totalPrice, true),
            pdfMargin + pdfFile.getTextWidth(totalText),
            pdfCurrentPositionY
          )

          pdfFile.setFont(pdfFontBold)

          let discountText = 'Скидка %:   '
          pdfFile.text(discountText, (pdfWidth / 4) + 4, pdfCurrentPositionY)
          pdfFile.setFont(pdfFontNormal)
          pdfFile.text(
            discountPercent ? discountPercent.toString() : '0',
            (pdfWidth / 4) + 4 + pdfFile.getTextWidth(discountText),
            pdfCurrentPositionY
          )

          pdfFile.setFont(pdfFontBold)
          let payText = 'К оплате:   '
          pdfFile.text(payText, pdfWidth / 2, pdfCurrentPositionY)
          pdfFile.setFont(pdfFontNormal)
          pdfFile.text(
            salePrice > 0 && totalPrice
              ? this.formatPrice(salePrice, true)
              : this.formatPrice(totalPrice, true),
            (pdfWidth / 2) + pdfFile.getTextWidth(payText),
            pdfCurrentPositionY
          )

          pdfCurrentPositionY += (pdfRowHeight * 2)
          pdfFile.setFont(pdfFontBold)

          pdfFile.setDrawColor(0,0,0,1)
          let cashierText = 'Кассир: '
          pdfFile.text(cashierText, pdfMargin, pdfCurrentPositionY)

          pdfFile.line(
            pdfMargin + pdfFile.getTextWidth(cashierText),
            pdfCurrentPositionY + 1,
            pdfMargin + pdfFile.getTextWidth(cashierText) + 40,
            pdfCurrentPositionY + 1,
          )

          let patientText = 'Клиент: '
          pdfFile.text(patientText, pdfWidth / 2, pdfCurrentPositionY)

          pdfFile.line(
            (pdfWidth / 2) + pdfFile.getTextWidth(patientText),
            pdfCurrentPositionY + 1,
            pdfWidth - pdfMargin,
            pdfCurrentPositionY + 1,
          )

          pdfFile.save(this.getActTitle(integrator, treatmentDiary) + ' - ' + patient.full_name + '.pdf')
        })
      }
    },
    async createShortActOfServicesRenderedForTreatmentDiary(treatmentDiary, patient, discountPercent) {
      const userData = getUserData()
      if (userData) {
        await this.$store.dispatch('info/getIntegrator', userData.integrator_id).then(response => {
          const integrator = response.data
          if (!treatmentDiary || !patient || !integrator) {
            this.notify(
              'Ошибка формирования акта оказанных услуг',
              'Пожалуйста, обратитесь в службу технической поддержки',
              'danger'
            )
            return false
          }
          const pdfFontNormal = 'OpenSans-Regular'
          const pdfFontBold = 'OpenSans-Bold'
          const pdfMargin = 8
          const pdfFontSize = 10
          const pdfRowHeight = 7
          const pdfWidth = 210
          const clinicName = integrator.name ? this.formatText(integrator.name) : ''

          let pdfCurrentPositionY = 0
          let pdfFile = new jsPDF({orientation: 'portrait', unit: 'mm', format: 'a4', putOnlyUsedFonts: true})

          // header
          pdfFile = this.getPdfDocumentHeader(pdfFile, integrator, pdfFontNormal, pdfMargin)
          pdfCurrentPositionY = this.getPositionAfterHeader(integrator, 42, false)

          pdfFile.setFontSize(pdfFontSize)
          let patientName = patient.full_name

          pdfFile.setFont(pdfFontBold)
          this.setActTitle(pdfFile, integrator, treatmentDiary, pdfWidth, pdfCurrentPositionY)
          pdfFile.setFont(pdfFontNormal)

          if (integrator.city) {
            pdfCurrentPositionY += pdfRowHeight
            pdfFile.text('Место составления ' + integrator.city, pdfWidth - pdfMargin, pdfCurrentPositionY, 'right')
          }

          pdfCurrentPositionY += (pdfRowHeight * 2)

          let rightDataPosition = pdfWidth / 5
          pdfFile.setFont(pdfFontBold)
          pdfFile.text('Дата:', pdfMargin, pdfCurrentPositionY)
          pdfFile.setFont(pdfFontNormal)

          let stringDate = moment(parseInt(treatmentDiary.date)).format('L')
          pdfFile.text(stringDate, rightDataPosition, pdfCurrentPositionY)
          pdfFile.line(
            rightDataPosition,
            pdfCurrentPositionY + 1,
            rightDataPosition + pdfFile.getTextWidth(stringDate),
            pdfCurrentPositionY + 1,
          )

          pdfFile.setFont(pdfFontBold)
          let actText = 'Договор №:  '
          pdfFile.text(actText, pdfWidth / 2, pdfCurrentPositionY, 'center')
          pdfFile.setFont(pdfFontNormal)
          pdfFile.text(
            treatmentDiary.act_number
              ? treatmentDiary.act_number + ''
              : '', (pdfWidth / 2) + (pdfFile.getTextWidth(actText) / 2), pdfCurrentPositionY
          )
          pdfFile.line(
            (pdfWidth / 2) + (pdfFile.getTextWidth(actText) / 2),
            pdfCurrentPositionY + 1,
            (pdfWidth / 2) + (pdfFile.getTextWidth(actText) / 2) + 20,
            pdfCurrentPositionY + 1
          )

          pdfFile.setFont(pdfFontBold)
          pdfCurrentPositionY += pdfRowHeight

          pdfFile.text('Заказчик:', pdfMargin, pdfCurrentPositionY)

          pdfFile.setFont(pdfFontNormal)

          pdfFile.text(patientName, rightDataPosition, pdfCurrentPositionY)
          pdfFile.line(rightDataPosition, pdfCurrentPositionY + 1, pdfWidth - pdfMargin, pdfCurrentPositionY + 1)

          pdfFile.setFont(pdfFontBold)
          pdfCurrentPositionY += pdfRowHeight

          pdfFile.text('Адрес:', pdfMargin, pdfCurrentPositionY)

          pdfFile.setFont(pdfFontNormal)
          pdfFile.text(patient.address ? patient.address : '', rightDataPosition, pdfCurrentPositionY)
          pdfFile.line(rightDataPosition, pdfCurrentPositionY + 1, pdfWidth - pdfMargin, pdfCurrentPositionY + 1)

          if (treatmentDiary.master) {
            pdfFile.setFont(pdfFontBold)
            pdfCurrentPositionY += pdfRowHeight
            pdfFile.text('Ф.И.О мастера:', pdfMargin, pdfCurrentPositionY)
            pdfFile.setFont(pdfFontNormal)
            pdfFile.text(treatmentDiary.master.full_name + '', rightDataPosition, pdfCurrentPositionY)
            pdfFile.line(rightDataPosition, pdfCurrentPositionY + 1, pdfWidth - pdfMargin, pdfCurrentPositionY + 1)
          }

          if (treatmentDiary.masseur) {
            pdfFile.setFont(pdfFontBold)
            pdfCurrentPositionY += pdfRowHeight
            pdfFile.text('Ф.И.О массажиста:', pdfMargin, pdfCurrentPositionY)

            pdfFile.setFont(pdfFontNormal)
            pdfFile.text(treatmentDiary.masseur.full_name + '', rightDataPosition, pdfCurrentPositionY)
            pdfFile.line(rightDataPosition, pdfCurrentPositionY + 1, pdfWidth - pdfMargin, pdfCurrentPositionY + 1)
          }

          pdfCurrentPositionY += (pdfRowHeight * 2)

          // Table services title
          const title = this.getServicesTableTitleForActOfServicesRendered(clinicName)
          const titleLength = title.length
          let splittedTitleItemsCount = 0
          pdfCurrentPositionY += pdfRowHeight
          pdfFile.text(title, pdfMargin, pdfCurrentPositionY)

          let rows = [
            [
              {content: 'Услуги', styles: {halign: 'center'}},
              {content: 'Сумма', styles: {halign: 'center'}}
            ]
          ]

          let servicesNumbers = []
          let servicesTotalPrice = 0
          treatmentDiary.dental_services_history.forEach(service => {
            servicesNumbers.push(service.number)
            servicesTotalPrice += service.total_price * service.count
          })
          rows.push([
            {content: servicesNumbers.sort().join(', ')},
            {
              content: this.formatPrice(servicesTotalPrice, true),
              styles: {halign: 'center', valign: 'middle'}
            }
          ])
          let totalPrice = treatmentDiary.dental_services_history.reduce(
            (totalPrice, service) =>
              totalPrice + parseFloat(this.formatPrice(service.total_price * service.count, false)),
            0
          )
          let salePrice = discountPercent ? (totalPrice - ((discountPercent / 100) * totalPrice)) : totalPrice
          pdfFile.autoTable({
            theme: 'grid',
            startY: pdfCurrentPositionY + pdfRowHeight,
            body: rows,
            bodyStyles: {
              cellPadding: 1,
              lineColor: [0, 0, 0],
              textColor: [0, 0, 0]
            },
            styles: {
              font: pdfFontNormal,
              fontSize: pdfFontSize,
            },
            didParseCell: hook => {
              if (hook.row.index === 0) {
                hook.cell.styles.font = pdfFontBold
              }
            },
            didDrawPage: hook => {
              pdfCurrentPositionY = (hook.cursor.y + pdfRowHeight)
            }
          })

          pdfCurrentPositionY += pdfRowHeight
          pdfFile.text(
            'Вышеперечисленные услуги выполнены полностью. Заказчик по объему, качеству и срокам оказания услуг',
            pdfMargin,
            pdfCurrentPositionY
          )
          pdfCurrentPositionY += pdfRowHeight
          pdfFile.text(
            'претензий не имеет. Настоящий акт является основанием к оплате оказанных услуг.',
            pdfMargin,
            pdfCurrentPositionY
          )
          pdfCurrentPositionY += (pdfRowHeight * 2)

          pdfFile.setFont(pdfFontBold)
          pdfFile.text('ИТОГО', pdfMargin, pdfCurrentPositionY)
          pdfCurrentPositionY += pdfRowHeight

          let totalText = 'Начислено:   '
          pdfFile.text(totalText, pdfMargin, pdfCurrentPositionY)

          pdfFile.setFont(pdfFontNormal)

          pdfFile.text(
            this.formatPrice(totalPrice, true),
            pdfMargin + pdfFile.getTextWidth(totalText),
            pdfCurrentPositionY
          )

          pdfFile.setFont(pdfFontBold)

          let discountText = 'Скидка %:   '
          pdfFile.text(discountText, (pdfWidth / 4) + 4, pdfCurrentPositionY)
          pdfFile.setFont(pdfFontNormal)
          pdfFile.text(
            discountPercent ? discountPercent.toString() : '0',
            (pdfWidth / 4) + 4 + pdfFile.getTextWidth(discountText),
            pdfCurrentPositionY
          )

          pdfFile.setFont(pdfFontBold)
          let payText = 'К оплате:   '
          pdfFile.text(payText, pdfWidth / 2, pdfCurrentPositionY)
          pdfFile.setFont(pdfFontNormal)
          pdfFile.text(
            salePrice > 0 && totalPrice
              ? this.formatPrice(salePrice, true)
              : this.formatPrice(totalPrice, true),
            (pdfWidth / 2) + pdfFile.getTextWidth(payText),
            pdfCurrentPositionY
          )

          pdfCurrentPositionY += (pdfRowHeight * 2)
          pdfFile.setFont(pdfFontBold)

          pdfFile.setDrawColor(0,0,0,1)
          let cashierText = 'Кассир: '
          pdfFile.text(cashierText, pdfMargin, pdfCurrentPositionY)

          pdfFile.line(
            pdfMargin + pdfFile.getTextWidth(cashierText),
            pdfCurrentPositionY + 1,
            pdfMargin + pdfFile.getTextWidth(cashierText) + 40,
            pdfCurrentPositionY + 1,
          )

          let patientText = 'Клиент: '
          pdfFile.text(patientText, pdfWidth / 2, pdfCurrentPositionY)

          pdfFile.line(
            (pdfWidth / 2) + pdfFile.getTextWidth(patientText),
            pdfCurrentPositionY + 1,
            pdfWidth - pdfMargin,
            pdfCurrentPositionY + 1,
          )

          pdfFile.save(
            this.getActTitle(integrator, treatmentDiary) + ' (сокращенный формат) - ' + patient.full_name + '.pdf'
          )
        })
      }
    },
    async createActOfServicesRenderedWithMaterialsForTreatmentDiary(treatmentDiary, patient, discountPercent) {
      const userData = getUserData()
      if (userData) {
        await this.$store.dispatch('info/getIntegrator', userData.integrator_id).then(response => {
          const integrator = response.data
          if (!treatmentDiary || !patient || !integrator) {
            this.notify(
              'Ошибка формирования акта оказанных услуг',
              'Пожалуйста, обратитесь в службу технической поддержки',
              'danger'
            )
            return false
          }
          let pdfFile = new jsPDF({orientation: 'portrait', unit: 'mm', format: 'a4', putOnlyUsedFonts: true})
          const pdfFontNormal = 'OpenSans-Regular'
          const pdfFontBold = 'OpenSans-Bold'
          const pdfMargin = 8
          const pdfFontSize = 10
          const pdfRowHeight = 7
          const pdfWidth = 210
          const pdfHeight = pdfFile.internal.pageSize.height
          const clinicName = integrator.name ? this.formatText(integrator.name) : ''

          let pdfCurrentPositionY = 0

          // header
          pdfFile = this.getPdfDocumentHeader(pdfFile, integrator, pdfFontNormal, pdfMargin)
          pdfCurrentPositionY = this.getPositionAfterHeader(integrator, 42, false)

          pdfFile.setFontSize(pdfFontSize)
          let patientName = patient.full_name

          pdfFile.setFont(pdfFontBold)
          this.setActTitle(pdfFile, integrator, treatmentDiary, pdfWidth, pdfCurrentPositionY)
          pdfFile.setFont(pdfFontNormal)

          if (integrator.city) {
            pdfCurrentPositionY += pdfRowHeight
            pdfFile.text('Место составления ' + integrator.city, pdfWidth - pdfMargin, pdfCurrentPositionY, 'right')
          }

          pdfCurrentPositionY += (pdfRowHeight * 2)

          let rightDataPosition = pdfWidth / 5
          pdfFile.setFont(pdfFontBold)
          pdfFile.text('Дата:', pdfMargin, pdfCurrentPositionY)
          pdfFile.setFont(pdfFontNormal)

          let stringDate = moment(parseInt(treatmentDiary.date)).format('L')
          pdfFile.text(stringDate, rightDataPosition, pdfCurrentPositionY)
          pdfFile.line(
            rightDataPosition,
            pdfCurrentPositionY + 1,
            rightDataPosition + pdfFile.getTextWidth(stringDate),
            pdfCurrentPositionY + 1,
          )

          pdfFile.setFont(pdfFontBold)
          let actText = 'Договор №:  '
          pdfFile.text(actText, pdfWidth / 2, pdfCurrentPositionY, 'center')
          pdfFile.setFont(pdfFontNormal)
          pdfFile.text(
            treatmentDiary.act_number
              ? treatmentDiary.act_number + ''
              : '', (pdfWidth / 2) + (pdfFile.getTextWidth(actText) / 2), pdfCurrentPositionY
          )
          pdfFile.line(
            (pdfWidth / 2) + (pdfFile.getTextWidth(actText) / 2),
            pdfCurrentPositionY + 1,
            (pdfWidth / 2) + (pdfFile.getTextWidth(actText) / 2) + 20,
            pdfCurrentPositionY + 1
          )

          pdfFile.setFont(pdfFontBold)
          pdfCurrentPositionY += pdfRowHeight

          pdfFile.text('Заказчик:', pdfMargin, pdfCurrentPositionY)
          pdfFile.setFont(pdfFontNormal)
          pdfFile.text(patientName, rightDataPosition, pdfCurrentPositionY)
          pdfFile.line(rightDataPosition, pdfCurrentPositionY + 1, pdfWidth - pdfMargin, pdfCurrentPositionY + 1)

          pdfFile.setFont(pdfFontBold)
          pdfCurrentPositionY += pdfRowHeight

          pdfFile.text('Адрес:', pdfMargin, pdfCurrentPositionY)

          pdfFile.setFont(pdfFontNormal)
          pdfFile.text(patient.address ? patient.address : '', rightDataPosition, pdfCurrentPositionY)
          pdfFile.line(
            rightDataPosition,
            pdfCurrentPositionY + 1,
            pdfWidth - pdfMargin,
            pdfCurrentPositionY + 1
          )

          if (treatmentDiary.master) {
            pdfFile.setFont(pdfFontBold)
            pdfCurrentPositionY += pdfRowHeight
            pdfFile.text('Ф.И.О мастера:', pdfMargin, pdfCurrentPositionY)
            pdfFile.setFont(pdfFontNormal)
            pdfFile.text(treatmentDiary.master.full_name + '', rightDataPosition, pdfCurrentPositionY)
            pdfFile.line(rightDataPosition, pdfCurrentPositionY + 1, pdfWidth - pdfMargin, pdfCurrentPositionY + 1)
          }

          if (treatmentDiary.masseur) {
            pdfFile.setFont(pdfFontBold)
            pdfCurrentPositionY += pdfRowHeight
            pdfFile.text('Ф.И.О массажиста:', pdfMargin, pdfCurrentPositionY)
            pdfFile.setFont(pdfFontNormal)
            pdfFile.text(treatmentDiary.masseur.full_name + '', rightDataPosition, pdfCurrentPositionY)
            pdfFile.line(rightDataPosition, pdfCurrentPositionY + 1, pdfWidth - pdfMargin, pdfCurrentPositionY + 1)
          }

          pdfCurrentPositionY += (pdfRowHeight * 2)

          // Table services title
          const title = this.getServicesTableTitleForActOfServicesRendered(clinicName)
          pdfCurrentPositionY += pdfRowHeight
          pdfFile.text(title, pdfMargin, pdfCurrentPositionY)

          let rows = [
            [
              {content: 'Номер', styles: {halign: 'center'}},
              {content: 'Наименование', styles: {halign: 'center'}},
              {content: 'Кол', styles: {halign: 'center'}},
              {content: 'Цена', styles: {halign: 'center'}},
              {content: 'Сумма', styles: {halign: 'center'}}
            ]
          ]

          const materials = {}
          treatmentDiary.dental_services_history.forEach(service => {
            service.materials.map(material => {
              if (materials[material.id]) {
                materials[material.id].count += service.count * material.part_rate
              } else {
                materials[material.id] = {
                  name: material.material_name,
                  sku: material.sku,
                  count: service.count * material.part_rate,
                  price: material.price,
                  nds: material.nds ? material.nds.value : null,
                  unitType: material.unit_type ? material.unit_type.short_name : null,
                }
              }
            })
            rows.push([
              {content: service.number, styles: {halign: 'center'}},
              {content: service.name},
              {content: service.count, styles: {halign: 'center'}},
              {
                content: this.formatPrice(service.total_price, true),
                styles: {halign: 'center'}
              },
              {
                content: this.formatPrice(service.total_price * parseFloat(service.count), true),
                styles: {halign: 'center'}
              }
            ])
          })
          let totalPrice = treatmentDiary.dental_services_history.reduce(
            (totalPrice, service) =>
              totalPrice + parseFloat(this.formatPrice(service.total_price * service.count, false)),
            0
          )
          let salePrice = discountPercent ? (totalPrice - ((discountPercent / 100) * totalPrice)) : totalPrice
          rows.push([
            {content: 'Итого', styles: {halign: 'left'}, colSpan: 4},
            {content: this.formatPrice(totalPrice, true), styles: { halign: 'center' }}
          ])
          pdfFile.autoTable({
            theme: 'grid',
            startY: pdfCurrentPositionY + pdfRowHeight,
            body: rows,
            bodyStyles: {
              cellPadding: 1,
              lineColor: [0, 0, 0],
              textColor: [0, 0, 0]
            },
            styles: {
              font: pdfFontNormal,
              fontSize: pdfFontSize,
            },
            didParseCell: hook => {
              if (hook.row.index === 0) {
                hook.cell.styles.font = pdfFontBold
              }
            },
            didDrawPage: hook => {
              pdfCurrentPositionY = (hook.cursor.y + pdfRowHeight)
            }
          })

          let totalMaterialsPrice = 0
          let totalMaterialsNds10Price = 0
          let totalMaterialsNds20Price = 0
          let totalMaterialsPriceWithNds = 0
          if (Object.keys(materials).length) {
            pdfFile.text('Использованные материалы:', pdfMargin, pdfCurrentPositionY)
            rows = [
              [
                {content: 'Артикул', styles: {halign: 'center', valign: 'middle'}},
                {content: 'Наименование', styles: {halign: 'center', valign: 'middle'}},
                {content: 'Стоимость без НДС', styles: {halign: 'center', valign: 'middle'}},
                {content: 'Количество', styles: {halign: 'center', valign: 'middle'}},
                {content: 'Ед. измерения', styles: {halign: 'center', valign: 'middle'}},
                {content: 'Итого без НДС', styles: {halign: 'center', valign: 'middle'}},
                {content: 'Ставка НДС', styles: {halign: 'center', valign: 'middle'}},
                {content: 'Сумма НДС', styles: {halign: 'center', valign: 'middle'}},
                {content: 'Итого с НДС', styles: {halign: 'center', valign: 'middle'}}
              ]
            ]

            Object.keys(materials).map(key => {
              const material = materials[key]
              const nds = material.nds ? material.nds : null
              const pricePerUnit = material.price
              const totalPrice = pricePerUnit * material.count
              const ndsPrice = nds ? (totalPrice / 100) * nds : null
              const totalPriceWithNds = ndsPrice ? totalPrice + ndsPrice : totalPrice
              let row = [
                {content: material.sku, styles: {halign: 'center', valign: 'middle'}},
                {content: material.name, styles: {halign: 'center', valign: 'middle'}},
                {
                  content: this.formatPrice(pricePerUnit, true),
                  styles: {halign: 'center', valign: 'middle'}
                },
                {content: material.count, styles: {halign: 'center', valign: 'middle'}},
                {content: material.unitType ? material.unitType : '', styles: {halign: 'center', valign: 'middle'}},
                {
                  content: this.formatPrice(totalPrice, true),
                  styles: {halign: 'center', valign: 'middle'}
                },
                {content: nds ? `${nds}%` : '0%', styles: {halign: 'center', valign: 'middle'}},
                {
                  content: this.formatPrice(ndsPrice, true),
                  styles: {halign: 'center', valign: 'middle'}
                },
                {
                  content: this.formatPrice(totalPriceWithNds, true),
                  styles: {halign: 'center', valign: 'middle'}
                },
              ]
              rows.push(row)
              if (nds === 10) {
                totalMaterialsNds10Price += ndsPrice
              }
              if (nds === 20) {
                totalMaterialsNds20Price += ndsPrice
              }
              totalMaterialsPrice += totalPrice
              totalMaterialsPriceWithNds += totalPriceWithNds
            })

            pdfFile.autoTable({
              theme: 'grid',
              startY: pdfCurrentPositionY + (pdfRowHeight / 2),
              body: rows,
              bodyStyles: {
                cellPadding: 1,
                lineColor: [0, 0, 0],
                textColor: [0, 0, 0]
              },
              styles: {
                font: pdfFontNormal,
                fontSize: pdfFontSize,
              },
              didParseCell: hook => {
                if (hook.row.index === 0) {
                  hook.cell.styles.font = pdfFontBold
                }
              },
              didDrawPage: hook => {
                pdfCurrentPositionY = (hook.cursor.y + pdfRowHeight)
              }
            })
          }

          if (pdfHeight - (pdfRowHeight * 2) < pdfCurrentPositionY) {
            pdfFile.addPage()
            pdfCurrentPositionY = pdfRowHeight * 2
          }

          if (Object.keys(materials).length) {
            pdfFile.text(
              `Итого сумма без НДС: ${this.formatPrice(totalMaterialsPrice, true)}`,
              pdfMargin,
              pdfCurrentPositionY
            )
            if (pdfHeight - (pdfRowHeight * 2) < pdfCurrentPositionY) {
              pdfFile.addPage()
              pdfCurrentPositionY = pdfRowHeight * 2
            } else {
              pdfCurrentPositionY += pdfRowHeight
            }
            pdfFile.text(
              `в т.ч. сумма НДС 10%: ${this.formatPrice(totalMaterialsNds10Price, true)}`,
              pdfMargin,
              pdfCurrentPositionY
            )
            if (pdfHeight - (pdfRowHeight * 2) < pdfCurrentPositionY) {
              pdfFile.addPage()
              pdfCurrentPositionY = pdfRowHeight * 2
            } else {
              pdfCurrentPositionY += pdfRowHeight
            }
            pdfFile.text(
              `в т.ч. сумма НДС 20%: ${this.formatPrice(totalMaterialsNds20Price, true)}`,
              pdfMargin,
              pdfCurrentPositionY
            )
            if (pdfHeight - (pdfRowHeight * 2) < pdfCurrentPositionY) {
              pdfFile.addPage()
              pdfCurrentPositionY = pdfRowHeight * 2
            } else {
              pdfCurrentPositionY += pdfRowHeight
            }
            pdfFile.text(
              `Итого сумма с учетом НДС: ${this.formatPrice(totalMaterialsPriceWithNds, true)}`,
              pdfMargin,
              pdfCurrentPositionY
            )
            if (pdfHeight - (pdfRowHeight * 2) < pdfCurrentPositionY) {
              pdfFile.addPage()
              pdfCurrentPositionY = pdfRowHeight * 2
            } else {
              pdfCurrentPositionY += pdfRowHeight
            }
          }

          pdfFile.text(
            'Вышеперечисленные услуги выполнены полностью. Заказчик по объему, качеству и срокам оказания услуг',
            pdfMargin,
            pdfCurrentPositionY
          )
          pdfCurrentPositionY += pdfRowHeight
          pdfFile.text(
            'претензий не имеет. Настоящий акт является основанием к оплате оказанных услуг.',
            pdfMargin,
            pdfCurrentPositionY
          )
          pdfCurrentPositionY += (pdfRowHeight * 2)

          pdfFile.setFont(pdfFontBold)
          pdfFile.text('ИТОГО', pdfMargin, pdfCurrentPositionY)
          pdfCurrentPositionY += pdfRowHeight

          let totalText = 'Начислено:   '
          pdfFile.text(totalText, pdfMargin, pdfCurrentPositionY)

          pdfFile.setFont(pdfFontNormal)

          pdfFile.text(
            this.formatPrice(totalPrice, true),
            pdfMargin + pdfFile.getTextWidth(totalText),
            pdfCurrentPositionY
          )

          pdfFile.setFont(pdfFontBold)

          let discountText = 'Скидка %:   '
          pdfFile.text(discountText, (pdfWidth / 4) + 4, pdfCurrentPositionY)
          pdfFile.setFont(pdfFontNormal)
          pdfFile.text(
            discountPercent ? discountPercent.toString() : '0',
            (pdfWidth / 4) + 4 + pdfFile.getTextWidth(discountText),
            pdfCurrentPositionY
          )

          pdfFile.setFont(pdfFontBold)
          let payText = 'К оплате:   '
          pdfFile.text(payText, pdfWidth / 2, pdfCurrentPositionY)
          pdfFile.setFont(pdfFontNormal)
          pdfFile.text(
            salePrice > 0 && totalPrice
              ? this.formatPrice(salePrice, true)
              : this.formatPrice(totalPrice, true),
            (pdfWidth / 2) + pdfFile.getTextWidth(payText),
            pdfCurrentPositionY
          )

          pdfCurrentPositionY += (pdfRowHeight * 2)
          pdfFile.setFont(pdfFontBold)

          pdfFile.setDrawColor(0,0,0,1)
          let cashierText = 'Кассир: '
          pdfFile.text(cashierText, pdfMargin, pdfCurrentPositionY)

          pdfFile.line(
            pdfMargin + pdfFile.getTextWidth(cashierText),
            pdfCurrentPositionY + 1,
            pdfMargin + pdfFile.getTextWidth(cashierText) + 40,
            pdfCurrentPositionY + 1,
          )

          let patientText = 'Клиент: '
          pdfFile.text(patientText, pdfWidth / 2, pdfCurrentPositionY)

          pdfFile.line(
            (pdfWidth / 2) + pdfFile.getTextWidth(patientText),
            pdfCurrentPositionY + 1,
            pdfWidth - pdfMargin,
            pdfCurrentPositionY + 1,
          )

          pdfFile.save(
            this.getActTitle(integrator, treatmentDiary) + ' (с материалами) - ' + patient.full_name + '.pdf'
          )
        })
      }
    },
    async createStatementOfWorkPerformedForMedicalServicesRendered(treatmentDiary, patient, discountPercent) {
      const userData = getUserData()
      if (userData) {
        await this.$store.dispatch('info/getIntegrator', userData.integrator_id).then(response => {
          const integrator = response.data
          if (!treatmentDiary || !patient || !integrator) {
            this.notify(
              'Ошибка формирования акта выполненных работ по оказанным медицинским услугам',
              'Пожалуйста, обратитесь в службу технической поддержки',
              'danger'
            )
            return false
          }
          const pdfFont = 'Times-Roman'
          const pdfFontBold = 'bold'
          const pdfFontNormal = 'normal'
          const pdfLeftMargin = 16
          const pdfRightMargin = pdfLeftMargin / 2
          const pdfFontSize = 12
          const pdfRowHeight = 7
          const pdfWidth = 210
          let pdfFile = new jsPDF({orientation: 'portrait', unit: 'mm', format: 'a4', putOnlyUsedFonts: true})

          // header
          pdfFile = this.getPdfDocumentHeader(pdfFile, integrator, pdfFont, pdfLeftMargin)
          let positionY = this.getPositionAfterHeader(integrator, 44, false)

          pdfFile.setFont(pdfFont, pdfFontNormal)
          pdfFile.setFontSize(pdfFontSize)
          pdfFile.text(
            'Акт выполненных работ по оказанным услугам',
            pdfWidth / 2,
            positionY,
            'center'
          )
          positionY += pdfRowHeight * 1.5
          pdfFile.text(integrator.city ? integrator.city : '', pdfLeftMargin, positionY)
          const date = moment(parseInt(treatmentDiary.date)).format('ll')
          pdfFile.text(date, pdfWidth - pdfRightMargin - this.getTextWidth(pdfFile, date), positionY)

          positionY += pdfRowHeight * 1.5
          const patientInfo = [patient.full_name]
          if (patient.address) {
            patientInfo.push(patient.address)
          }

          if (
            patient.document_series
            && patient.document_number
            && patient.document_issued_by
            && patient.document_type === 'passport'
          ) {
            const passportInfo = [`${patient.document_series}${patient.document_number}`, patient.document_issued_by]
            patientInfo.push(`№ ${passportInfo.join(', ')}`)
          }
          let text = `${integrator.name}, в соответствии с договором возмездного оказания услуг от `
            + `${moment(parseInt(treatmentDiary.date)).format('L')} г., `
            + `были оказаны услуги клиенту ${patientInfo.join(', ')}, наименование услуг:`
          const splittedText = pdfFile.splitTextToSize(text, pdfWidth - (pdfLeftMargin + pdfRightMargin))
          const splittedTextItemsCount = splittedText.length
          pdfFile.text(splittedText, pdfLeftMargin, positionY)
          positionY += (pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor) * splittedTextItemsCount

          text = treatmentDiary.dental_services_history.map(service => service.name).join(', ')
          text = this.capitalizeFirstLetter(text)
          if (this.getTextWidth(pdfFile, text) > pdfWidth - (pdfLeftMargin + pdfRightMargin)) {
            const splittedText = pdfFile.splitTextToSize(text, pdfWidth - (pdfLeftMargin + pdfRightMargin))
            const splittedTextItemsCount = splittedText.length
            pdfFile.text(splittedText, pdfLeftMargin, positionY)
            positionY += (pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor) * (splittedTextItemsCount - 1)
          } else {
            pdfFile.text(text, pdfLeftMargin, positionY)
          }
          positionY += pdfRowHeight * 1.5

          pdfFile.setFont(pdfFont, pdfFontBold)
          let totalPrice = treatmentDiary.dental_services_history.reduce(
            (totalPrice, service) =>
              totalPrice + parseFloat(this.formatPrice(service.total_price * service.count, false)),
            0
          )
          let salePrice = discountPercent
            ? (totalPrice - ((discountPercent / 100) * totalPrice)).toFixed(2) : totalPrice
          text = `Выполнено работ на сумму с НДС: ${this.formatPrice(salePrice, false)}`
          pdfFile.text(text, pdfLeftMargin, positionY)
          let endTextPosition = pdfLeftMargin + this.getTextWidth(pdfFile, text)
          text = `(${this.amountToHumanReadableAmount(salePrice)})`
          pdfFile.setFont(pdfFont, pdfFontNormal)
          let startTextPosition = endTextPosition + 1
          pdfFile.text(text, startTextPosition, positionY)
          positionY += 1
          pdfFile.line(pdfLeftMargin, positionY, pdfWidth - pdfRightMargin, positionY)

          positionY += pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor
          text = 'Услуги без НДС (согласно ст.118 подп. 1.2  п.1 НК РБ)'
          pdfFile.text(text, pdfLeftMargin, positionY)

          const materials = {}
          let totalServicesNds10Price = 0
          let totalServicesNds20Price = 0
          treatmentDiary.dental_services_history.forEach(service => {
            const nds = service.nds ? service.nds.value : null
            const totalPrice = service.service_price * service.count
            const ndsPrice = nds ? (totalPrice / 100) * nds : null
            if (nds === 10) {
              totalServicesNds10Price += ndsPrice
            }
            if (nds === 20) {
              totalServicesNds20Price += ndsPrice
            }
            service.materials.map(material => {
              if (materials[material.id]) {
                materials[material.id].count += service.count * material.part_rate
              } else {
                materials[material.id] = {
                  count: service.count * material.part_rate,
                  price: material.price,
                  nds: material.nds ? material.nds.value : null
                }
              }
            })
          })
          let totalMaterialsNds10Price = 0
          let totalMaterialsNds20Price = 0

          if (Object.keys(materials).length || totalServicesNds10Price || totalServicesNds20Price) {
            positionY += pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor
            text = 'В том числе НДС:'
            pdfFile.text(text, pdfLeftMargin, positionY)
          }

          if (Object.keys(materials).length) {
            positionY += pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor
            text = 'на материалы'
            pdfFile.text(text, pdfLeftMargin, positionY)

            Object.keys(materials).map(key => {
              const material = materials[key]
              const nds = material.nds ? material.nds : null
              const pricePerUnit = material.price
              const totalPrice = pricePerUnit * material.count
              const ndsPrice = nds ? (totalPrice / 100) * nds : null
              if (nds === 10) {
                totalMaterialsNds10Price += ndsPrice
              }
              if (nds === 20) {
                totalMaterialsNds20Price += ndsPrice
              }
            })

            if (totalMaterialsNds10Price) {
              positionY += pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor
              totalMaterialsNds10Price = totalMaterialsNds10Price.toFixed(2)
              text = `НДС 10%: ${totalMaterialsNds10Price} (${this.amountToHumanReadableAmount(totalMaterialsNds10Price)})`
              pdfFile.text(text, pdfLeftMargin, positionY)
              positionY += 1
              pdfFile.line(pdfLeftMargin, positionY, pdfLeftMargin + this.getTextWidth(pdfFile, text), positionY)
            }

            if (totalMaterialsNds20Price) {
              positionY += pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor
              totalMaterialsNds20Price = totalMaterialsNds20Price.toFixed(2)
              text = `НДС 20%: ${totalMaterialsNds20Price} (${this.amountToHumanReadableAmount(totalMaterialsNds20Price)})`
              pdfFile.text(text, pdfLeftMargin, positionY)
              positionY += 1
              pdfFile.line(pdfLeftMargin, positionY, pdfLeftMargin + this.getTextWidth(pdfFile, text), positionY)
            }
          }

          if (totalServicesNds10Price || totalServicesNds20Price) {
            positionY += pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor
            text = 'на тариф'
            pdfFile.text(text, pdfLeftMargin, positionY)
          }

          if (totalServicesNds10Price) {
            positionY += pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor
            totalServicesNds10Price = totalServicesNds10Price.toFixed(2)
            text = `НДС 10%: ${totalServicesNds10Price} (${this.amountToHumanReadableAmount(totalServicesNds10Price)})`
            pdfFile.text(text, pdfLeftMargin, positionY)
            positionY += 1
            pdfFile.line(pdfLeftMargin, positionY, pdfLeftMargin + this.getTextWidth(pdfFile, text), positionY)
          }

          if (totalServicesNds20Price) {
            positionY += pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor
            totalServicesNds20Price = totalServicesNds20Price.toFixed(2)
            text = `НДС 20%: ${totalServicesNds20Price} (${this.amountToHumanReadableAmount(totalServicesNds20Price)})`
            pdfFile.text(text, pdfLeftMargin, positionY)
            positionY += 1
            pdfFile.line(pdfLeftMargin, positionY, pdfLeftMargin + this.getTextWidth(pdfFile, text), positionY)
          }

          positionY += pdfRowHeight * 1.5
          const master = treatmentDiary.master ? treatmentDiary.master.full_name_initials : ''
          text = `Мастер оказавший услуги: ${master}`
          pdfFile.text(text, pdfLeftMargin, positionY)
          positionY += 1
          const offset = treatmentDiary.master
            ? pdfLeftMargin + this.getTextWidth(pdfFile, text)
            : pdfLeftMargin + (this.getTextWidth(pdfFile, text) * 2)
          pdfFile.line(pdfLeftMargin, positionY, offset, positionY)

          positionY += pdfRowHeight * 1.5
          text = 'Качество оказанных услуг соответствует предъявленным требованиям, стороны претензий друг к'
          pdfFile.text(text, pdfLeftMargin, positionY)

          positionY += pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor
          text = 'другу не имеют.'
          pdfFile.text(text, pdfLeftMargin, positionY)

          const pageCenter = pdfWidth / 2
          positionY += pdfRowHeight * 1.5
          text = 'Исполнитель'
          pdfFile.text(text, pdfLeftMargin, positionY)

          positionY += 1
          pdfFile.line(
            pdfLeftMargin + this.getTextWidth(pdfFile, text) + 10, positionY, pageCenter - 10, positionY
          )
          positionY += pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor
          text = '(подпись)'
          pdfFile.text(text, pdfLeftMargin + this.getTextWidth(pdfFile, text) + 30, positionY)

          positionY -= pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor
          positionY -= 1
          text = 'Клиент'
          pdfFile.text(text, pageCenter, positionY)

          positionY += 1
          pdfFile.line(
            pageCenter + this.getTextWidth(pdfFile, text) + 10, positionY, pdfWidth - pdfLeftMargin - 10, positionY
          )
          positionY += pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor
          text = '(подпись)'
          pdfFile.text(text, pageCenter + this.getTextWidth(pdfFile, text) + 26, positionY)

          pdfFile.save(
            'Акт выполненных работ по оказанным услугам - ' + patient.full_name + '.pdf'
          )
        })
      }
    },
    async createActOfComplexServicesRenderedForTreatmentDiary(treatmentDiary, patient, discountPercent) {
      const userData = getUserData()
      if (userData) {
        await this.$store.dispatch('info/getIntegrator', userData.integrator_id).then(response => {
          const integrator = response.data
          if (!treatmentDiary || !patient || !integrator) {
            this.notify(
              'Ошибка формирования акта оказанных комплексов услуг',
              'Пожалуйста, обратитесь в службу технической поддержки',
              'danger'
            )
            return false
          }
          const pdfFontNormal = 'OpenSans-Regular'
          const pdfFontBold = 'OpenSans-Bold'
          const pdfMargin = 8
          const pdfFontSize = 10
          const pdfRowHeight = 7
          const pdfWidth = 210
          const clinicName = integrator.name ? this.formatText(integrator.name) : ''

          let pdfCurrentPositionY = 0
          let pdfFile = new jsPDF({orientation: 'portrait', unit: 'mm', format: 'a4', putOnlyUsedFonts: true})

          // header
          pdfFile = this.getPdfDocumentHeader(pdfFile, integrator, pdfFontNormal, pdfMargin)
          pdfCurrentPositionY = this.getPositionAfterHeader(integrator, 42, false)

          pdfFile.setFontSize(pdfFontSize)
          let patientName = patient.full_name

          pdfFile.setFont(pdfFontBold)
          this.setActTitle(pdfFile, integrator, treatmentDiary, pdfWidth, pdfCurrentPositionY)
          pdfFile.setFont(pdfFontNormal)

          if (integrator.city) {
            pdfCurrentPositionY += pdfRowHeight
            pdfFile.text('Место составления ' + integrator.city, pdfWidth - pdfMargin, pdfCurrentPositionY, 'right')
          }

          pdfCurrentPositionY += (pdfRowHeight * 2)

          let rightDataPosition = pdfWidth / 5
          pdfFile.setFont(pdfFontBold)
          pdfFile.text('Дата:', pdfMargin, pdfCurrentPositionY)
          pdfFile.setFont(pdfFontNormal)

          let stringDate = moment(parseInt(treatmentDiary.date)).format('L')
          pdfFile.text(stringDate, rightDataPosition, pdfCurrentPositionY)
          pdfFile.line(
            rightDataPosition,
            pdfCurrentPositionY + 1,
            rightDataPosition + pdfFile.getTextWidth(stringDate),
            pdfCurrentPositionY + 1,
          )

          pdfFile.setFont(pdfFontBold)
          let actText = 'Договор №:  '
          pdfFile.text(actText, pdfWidth / 2, pdfCurrentPositionY, 'center')
          pdfFile.setFont(pdfFontNormal)
          pdfFile.text(
            treatmentDiary.act_number
              ? treatmentDiary.act_number + ''
              : '', (pdfWidth / 2) + (pdfFile.getTextWidth(actText) / 2), pdfCurrentPositionY
          )
          pdfFile.line(
            (pdfWidth / 2) + (pdfFile.getTextWidth(actText) / 2),
            pdfCurrentPositionY + 1,
            (pdfWidth / 2) + (pdfFile.getTextWidth(actText) / 2) + 20,
            pdfCurrentPositionY + 1
          )

          pdfFile.setFont(pdfFontBold)
          pdfCurrentPositionY += pdfRowHeight

          pdfFile.text('Заказчик:', pdfMargin, pdfCurrentPositionY)

          pdfFile.setFont(pdfFontNormal)

          pdfFile.text(patientName, rightDataPosition, pdfCurrentPositionY)
          pdfFile.line(rightDataPosition, pdfCurrentPositionY + 1, pdfWidth - pdfMargin, pdfCurrentPositionY + 1)

          pdfFile.setFont(pdfFontBold)
          pdfCurrentPositionY += pdfRowHeight

          pdfFile.text('Адрес:', pdfMargin, pdfCurrentPositionY)

          pdfFile.setFont(pdfFontNormal)
          pdfFile.text(patient.address ? patient.address : '', rightDataPosition, pdfCurrentPositionY)
          pdfFile.line(rightDataPosition, pdfCurrentPositionY + 1, pdfWidth - pdfMargin, pdfCurrentPositionY + 1)

          if (treatmentDiary.master) {
            pdfFile.setFont(pdfFontBold)
            pdfCurrentPositionY += pdfRowHeight
            pdfFile.text('Ф.И.О мастера:', pdfMargin, pdfCurrentPositionY)
            pdfFile.setFont(pdfFontNormal)
            pdfFile.text(treatmentDiary.master.full_name + '', rightDataPosition, pdfCurrentPositionY)
            pdfFile.line(rightDataPosition, pdfCurrentPositionY + 1, pdfWidth - pdfMargin, pdfCurrentPositionY + 1)
          }

          if (treatmentDiary.masseur) {
            pdfFile.setFont(pdfFontBold)
            pdfCurrentPositionY += pdfRowHeight
            pdfFile.text('Ф.И.О лаборанта:', pdfMargin, pdfCurrentPositionY)

            pdfFile.setFont(pdfFontNormal)
            pdfFile.text(treatmentDiary.masseur.full_name + '', rightDataPosition, pdfCurrentPositionY)
            pdfFile.line(rightDataPosition, pdfCurrentPositionY + 1, pdfWidth - pdfMargin, pdfCurrentPositionY + 1)
          }

          pdfCurrentPositionY += (pdfRowHeight * 2)

          // Table services title
          const title = this.getServicesTableTitleForActOfServicesRendered(clinicName)
          pdfCurrentPositionY += pdfRowHeight
          pdfFile.text(title, pdfMargin, pdfCurrentPositionY)

          const additionalServices = []
          const tPCount = {}
          if (treatmentDiary.treatment_plans) {
            treatmentDiary.treatment_plans.map(
              tp => tp.id in tPCount
                ? tPCount[tp.id] += tp.system_column_count : tPCount[tp.id] = tp.system_column_count
            )
          }
          const treatmentPlansTableData = {}
          treatmentDiary.dental_services_history.map(service => {
            if (service.treatment_plan && treatmentDiary.treatment_plans) {
              const index = treatmentDiary.treatment_plans.findIndex(plan => plan.id === service.treatment_plan.id)
              if (index > -1) {
                const plan = treatmentDiary.treatment_plans[index]
                if (service.treatment_plan.id in treatmentPlansTableData) {
                  treatmentPlansTableData[service.treatment_plan.id].amount += service.total_price * service.count
                } else {
                  treatmentPlansTableData[service.treatment_plan.id] = {
                    number: plan.number,
                    name: plan.name,
                    count: service.treatment_plan.id in tPCount ? tPCount[service.treatment_plan.id] : 0,
                    price: plan.user_price ? plan.user_price : plan.price,
                    amount: service.total_price * service.count
                  }
                }
              }
            } else {
              additionalServices.push(service)
            }
          })

          pdfCurrentPositionY += pdfRowHeight
          pdfFile.text('Комплексы услуг:', pdfMargin, pdfCurrentPositionY)

          const tablesHeader = [
            {content: 'Номер', styles: {halign: 'center'}},
            {content: 'Наименование', styles: {halign: 'center'}},
            {content: 'Количество', styles: {halign: 'center'}},
            {content: 'Цена', styles: {halign: 'center'}},
            {content: 'Сумма', styles: {halign: 'center'}}
          ]
          let rows = [tablesHeader]

          let totalPlansPrice = 0
          Object.values(treatmentPlansTableData).map(plan => {
            totalPlansPrice += plan.amount
            rows.push([
              {content: plan.number, styles: {halign: 'center'}},
              {content: plan.name, styles: {halign: 'left'}},
              {content: plan.count, styles: {halign: 'center'}},
              {content: this.formatPrice(plan.price, true), styles: {halign: 'center'}},
              {content: this.formatPrice(plan.amount, true), styles: {halign: 'center'}},
            ])
          })

          rows.push([
            {content: 'Итого', styles: {halign: 'left'}, colSpan: 4},
            {
              content: this.formatPrice(totalPlansPrice, true), styles: { halign: 'center' }
            }
          ])
          let totalPrice = treatmentDiary.dental_services_history.reduce(
            (totalPrice, service) =>
              totalPrice + parseFloat(this.formatPrice(service.total_price * service.count, false)),
            0
          )
          let salePrice = discountPercent
            ? (totalPrice - ((discountPercent / 100) * totalPrice)) : totalPrice
          pdfFile.autoTable({
            theme: 'grid',
            startY: pdfCurrentPositionY + (pdfRowHeight / 2),
            body: rows,
            bodyStyles: {
              cellPadding: 1,
              lineColor: [0, 0, 0],
              textColor: [0, 0, 0]
            },
            styles: {
              font: pdfFontNormal,
              fontSize: pdfFontSize,
            },
            didParseCell: hook => {
              if (hook.row.index === 0) {
                hook.cell.styles.font = pdfFontBold
              }
            },
            didDrawPage: hook => {
              pdfCurrentPositionY = (hook.cursor.y + pdfRowHeight)
            }
          })

          if (additionalServices.length) {
            pdfFile.text('Дополнительные услуги:', pdfMargin, pdfCurrentPositionY)
            rows = [tablesHeader]

            let totalServicesPrice = 0
            additionalServices.map(service => {
              totalServicesPrice += service.total_price * service.count
              rows.push([
                {content: service.number, styles: {halign: 'center'}},
                {content: service.name},
                {content: service.count, styles: {halign: 'center'}},
                {
                  content: this.formatPrice(service.total_price, true),
                  styles: {halign: 'center'}
                },
                {
                  content: this.formatPrice(service.total_price * service.count, true),
                  styles: {halign: 'center'}
                }
              ])
            })

            rows.push([
              {content: 'Итого', styles: {halign: 'left'}, colSpan: 4},
              {
                content: this.formatPrice(totalServicesPrice, true),
                styles: { halign: 'center' }
              }
            ])

            pdfFile.autoTable({
              theme: 'grid',
              startY: pdfCurrentPositionY + (pdfRowHeight / 2),
              body: rows,
              bodyStyles: {
                cellPadding: 1,
                lineColor: [0, 0, 0],
                textColor: [0, 0, 0]
              },
              styles: {
                font: pdfFontNormal,
                fontSize: pdfFontSize,
              },
              didParseCell: hook => {
                if (hook.row.index === 0) {
                  hook.cell.styles.font = pdfFontBold
                }
              },
              didDrawPage: hook => {
                pdfCurrentPositionY = (hook.cursor.y + pdfRowHeight)
              }
            })
          }

          pdfCurrentPositionY += pdfRowHeight
          pdfFile.text(
            'Вышеперечисленные услуги выполнены полностью. Заказчик по объему, качеству и срокам оказания услуг',
            pdfMargin,
            pdfCurrentPositionY
          )
          pdfCurrentPositionY += pdfRowHeight
          pdfFile.text(
            'претензий не имеет. Настоящий акт является основанием к оплате оказанных услуг.',
            pdfMargin,
            pdfCurrentPositionY
          )
          pdfCurrentPositionY += (pdfRowHeight * 2)

          pdfFile.setFont(pdfFontBold)
          pdfFile.text('ИТОГО', pdfMargin, pdfCurrentPositionY)
          pdfCurrentPositionY += pdfRowHeight

          let totalText = 'Начислено:   '
          pdfFile.text(totalText, pdfMargin, pdfCurrentPositionY)

          pdfFile.setFont(pdfFontNormal)

          pdfFile.text(
            this.formatPrice(totalPrice, true),
            pdfMargin + pdfFile.getTextWidth(totalText),
            pdfCurrentPositionY
          )

          pdfFile.setFont(pdfFontBold)

          let discountText = 'Скидка %:   '
          pdfFile.text(discountText, (pdfWidth / 4) + 4, pdfCurrentPositionY)
          pdfFile.setFont(pdfFontNormal)
          pdfFile.text(
            discountPercent ? discountPercent.toString() : '0',
            (pdfWidth / 4) + 4 + pdfFile.getTextWidth(discountText),
            pdfCurrentPositionY
          )

          pdfFile.setFont(pdfFontBold)
          let payText = 'К оплате:   '
          pdfFile.text(payText, pdfWidth / 2, pdfCurrentPositionY)
          pdfFile.setFont(pdfFontNormal)
          pdfFile.text(
            salePrice > 0 && totalPrice
              ? this.formatPrice(salePrice, true)
              : this.formatPrice(totalPrice, true),
            (pdfWidth / 2) + pdfFile.getTextWidth(payText),
            pdfCurrentPositionY
          )

          pdfCurrentPositionY += (pdfRowHeight * 2)
          pdfFile.setFont(pdfFontBold)

          pdfFile.setDrawColor(0,0,0,1)
          let cashierText = 'Кассир: '
          pdfFile.text(cashierText, pdfMargin, pdfCurrentPositionY)

          pdfFile.line(
            pdfMargin + pdfFile.getTextWidth(cashierText),
            pdfCurrentPositionY + 1,
            pdfMargin + pdfFile.getTextWidth(cashierText) + 40,
            pdfCurrentPositionY + 1,
          )

          let patientText = 'Клиент: '
          pdfFile.text(patientText, pdfWidth / 2, pdfCurrentPositionY)

          pdfFile.line(
            (pdfWidth / 2) + pdfFile.getTextWidth(patientText),
            pdfCurrentPositionY + 1,
            pdfWidth - pdfMargin,
            pdfCurrentPositionY + 1,
          )

          pdfFile.save(
            this.getActTitle(integrator, treatmentDiary) + ' (комплексы услуг) - ' + patient.full_name + '.pdf'
          )
        })
      }
    },
    async createPurchaseOrder(treatmentDiary, patient) {
      const userData = getUserData()
      if (userData) {
        await this.$store.dispatch('info/getIntegrator', userData.integrator_id).then(response => {
          const integrator = response.data
          if (!treatmentDiary || !patient || !integrator) {
            this.notify(
              'Ошибка формирования заказ-наряда',
              'Пожалуйста, обратитесь в службу технической поддержки',
              'danger'
            )
            return false
          }
          let pdfFont = 'Times-Roman'
          const pdfFontBold = 'bold'
          const pdfFontNormal = 'normal'
          const pdfMargin = 8
          const pdfFontSize = 11
          const pdfRowHeight = 7
          const date = moment(parseInt(treatmentDiary.date)).format('LL')
          let pdfFile = new jsPDF({orientation: 'portrait', unit: 'mm', format: 'a4', putOnlyUsedFonts: true})
          const pdfHeight = pdfFile.internal.pageSize.height
          const pdfWidth = pdfFile.internal.pageSize.width

          // header
          pdfFile = this.getPdfDocumentHeader(pdfFile, integrator, pdfFont, pdfMargin)
          let positionY = this.getPositionAfterHeader(integrator, 44, false)

          pdfFont = 'Microsoft-Sans-Serif'
          pdfFile.setFont(pdfFont, pdfFontBold)
          pdfFile.setFontSize(13.5)
          pdfFile.text(
            'Заказ-наряд',
            pdfWidth / 2,
            positionY,
            'center'
          )

          pdfFont = 'Times-Roman'
          pdfFile.setFont(pdfFont, pdfFontBold)
          pdfFile.setFontSize(pdfFontSize)

          positionY += pdfRowHeight * 1.5
          let text = 'Дата:'
          pdfFile.text(text, pdfMargin, positionY)
          pdfFile.setFont(pdfFont, pdfFontNormal)
          pdfFile.text(date, pdfMargin + this.getTextWidth(pdfFile, text) + 2, positionY)

          positionY += pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor
          pdfFile.setFont(pdfFont, pdfFontBold)
          text = 'Клиент:'
          pdfFile.text(text, pdfMargin, positionY)
          pdfFile.setFont(pdfFont, pdfFontNormal)
          let patientInfo = [patient.full_name]
          if (patient.address) {
            patientInfo.push(patient.address)
          }

          if (
            patient.document_series
            && patient.document_number
            && patient.document_issued_by
            && patient.document_type === 'passport'
          ) {
            const passportInfo = [`${patient.document_series}${patient.document_number}`, patient.document_issued_by]
            patientInfo.push(`№ ${passportInfo.join(', ')}`)
          }
          patientInfo = patientInfo.join(', ')
          if (this.getTextWidth(pdfFile, patientInfo) > pdfWidth - this.getTextWidth(pdfFile, text) - pdfMargin * 2) {
            const splittedText = pdfFile.splitTextToSize(
              patientInfo, pdfWidth - this.getTextWidth(pdfFile, text) - pdfMargin * 2
            )
            const splittedTextItemsCount = splittedText.length
            pdfFile.text(splittedText[0], pdfMargin + this.getTextWidth(pdfFile, text) + 2, positionY)
            positionY += pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor
            pdfFile.text(splittedText.slice(1), pdfMargin, positionY)
            positionY += (pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor) * (splittedTextItemsCount - 1)
          } else {
            pdfFile.text(patientInfo, pdfMargin + this.getTextWidth(pdfFile, text) + 2, positionY)
            positionY += pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor
          }
          let rows = [
            [
              {content: 'Наименование услуги', styles: {cellWidth: 50, halign: 'center', valign: 'middle'}, rowSpan: 2},
              {content: 'Количество', styles: {cellWidth: 14, halign: 'center', valign: 'middle'}, rowSpan: 2},
              {content: 'Тариф услуги', styles: {halign: 'center', valign: 'middle'}, colSpan: 2},
              {content: 'Стоимость материалов', styles: {halign: 'center', valign: 'middle'}, colSpan: 5},
              {content: 'Всего', styles: {halign: 'center', valign: 'middle'}, rowSpan: 2},
            ],
            [
              {content: 'Без НДС', styles: {halign: 'center', valign: 'middle'}},
              {content: 'НДС', styles: {halign: 'center', valign: 'middle'}},
              {content: 'Освобожденных от НДС', styles: {halign: 'center', valign: 'middle'}},
              {content: 'Облагаемых НДС без учета НДС 10%', styles: {halign: 'center', valign: 'middle'}},
              {content: 'Облагаемых НДС без учета НДС 20%', styles: {halign: 'center', valign: 'middle'}},
              {content: 'Сумма НДС по ставке 10%', styles: {halign: 'center', valign: 'middle'}},
              {content: 'Сумма НДС по ставке 20%', styles: {halign: 'center', valign: 'middle'}},
            ],
          ]

          let totalServicesCount = 0
          let totalServicesPriceWithoutNds = 0
          let totalNdsPrice = 0
          let totalMaterialsPriceWithoutNds = 0
          let commonTotalMaterialsNds10Price = 0
          let commonTotalMaterialsNds20Price = 0
          let commonTotalMaterialsWithNds10Price = 0
          let commonTotalMaterialsWithNds20Price = 0
          let commonTotalPrice = 0
          let totalServicesNds10Price = 0
          let totalServicesNds20Price = 0
          treatmentDiary.dental_services_history.forEach(service => {
            let ndsPrice = 0
            const totalPrice = service.total_price * service.count
            if (service.nds) {
              const nds = service.nds ? service.nds.value : null
              ndsPrice = nds ? ((service.service_price * service.count / 100) * nds) : 0
              if (nds === 10) {
                totalServicesNds10Price += ndsPrice
              }
              if (nds === 20) {
                totalServicesNds20Price += ndsPrice
              }
            }

            let materialPriceWithoutNds = 0
            let totalMaterialsNds10Price = 0
            let totalMaterialsNds20Price = 0
            let totalMaterialsWithNds10Price = 0
            let totalMaterialsWithNds20Price = 0
            service.materials.map(material => {
              const nds = material.nds ? material.nds.value : null
              const pricePerUnit = material.price
              let materialPrice = pricePerUnit * service.count * material.part_rate
              let materialNdsPrice = nds ? (nds / 100) * materialPrice : 0
              materialPriceWithoutNds += !nds ? pricePerUnit * service.count * material.part_rate : 0
              if (nds === 10) {
                totalMaterialsNds10Price += materialNdsPrice
                totalMaterialsWithNds10Price += materialPrice
              }
              if (nds === 20) {
                totalMaterialsNds20Price += materialNdsPrice
                totalMaterialsWithNds20Price += materialPrice
              }
            })
            materialPriceWithoutNds = materialPriceWithoutNds ? materialPriceWithoutNds : 0

            let servicePriceWithoutNds = service.service_price * service.count
            servicePriceWithoutNds = servicePriceWithoutNds ? servicePriceWithoutNds : 0
            rows.push([
              {content: `${service.number} ${service.name}`, styles: {halign: 'left', valign: 'middle'}},
              {content: service.count, styles: {halign: 'center', valign: 'middle'}},
              {
                content: this.formatPrice(servicePriceWithoutNds, false),
                styles: {halign: 'center', valign: 'middle'}
              },
              {
                content: this.formatPrice(ndsPrice, false),
                styles: {halign: 'center', valign: 'middle'}
              },
              {
                content: this.formatPrice(materialPriceWithoutNds, false),
                styles: {halign: 'center', valign: 'middle'}
              },
              {
                content: this.formatPrice(totalMaterialsWithNds10Price, false),
                styles: {halign: 'center', valign: 'middle'}
              },
              {
                content: this.formatPrice(totalMaterialsWithNds20Price, false),
                styles: {halign: 'center', valign: 'middle'}
              },
              {
                content: this.formatPrice(totalMaterialsNds10Price, false),
                styles: {halign: 'center', valign: 'middle'}
              },
              {
                content: this.formatPrice(totalMaterialsNds20Price, false),
                styles: {halign: 'center', valign: 'middle'}
              },
              {
                content: this.formatPrice(totalPrice, false),
                styles: {halign: 'center', valign: 'middle'}
              }
            ])

            totalServicesCount += service.count
            totalServicesPriceWithoutNds += parseFloat(servicePriceWithoutNds)
            totalNdsPrice += ndsPrice
            totalMaterialsPriceWithoutNds += parseFloat(materialPriceWithoutNds)
            commonTotalMaterialsNds10Price += totalMaterialsNds10Price
            commonTotalMaterialsNds20Price += totalMaterialsNds20Price
            commonTotalMaterialsWithNds10Price += totalMaterialsWithNds10Price
            commonTotalMaterialsWithNds20Price += totalMaterialsWithNds20Price
            commonTotalPrice += parseFloat(totalPrice)
          })

          totalServicesPriceWithoutNds = totalServicesPriceWithoutNds ? totalServicesPriceWithoutNds : 0
          totalNdsPrice = totalNdsPrice ? totalNdsPrice : 0
          totalMaterialsPriceWithoutNds = totalMaterialsPriceWithoutNds ? totalMaterialsPriceWithoutNds : 0
          commonTotalPrice = commonTotalPrice ? commonTotalPrice : 0
          rows.push([
            {content: 'ИТОГО', styles: {halign: 'left', valign: 'middle'}},
            {content: totalServicesCount, styles: {halign: 'center', valign: 'middle'}},
            {
              content: this.formatPrice(totalServicesPriceWithoutNds, false),
              styles: {halign: 'center', valign: 'middle'}
            },
            {
              content: this.formatPrice(totalNdsPrice, false),
              styles: {halign: 'center', valign: 'middle'}
            },
            {
              content: this.formatPrice(totalMaterialsPriceWithoutNds, false),
              styles: {halign: 'center', valign: 'middle'}
            },
            {
              content: this.formatPrice(commonTotalMaterialsWithNds10Price, false),
              styles: {halign: 'center', valign: 'middle'}
            },
            {
              content: this.formatPrice(commonTotalMaterialsWithNds20Price, false),
              styles: {halign: 'center', valign: 'middle'}},
            {
              content: this.formatPrice(commonTotalMaterialsNds10Price, false),
              styles: {halign: 'center', valign: 'middle'}
            },
            {
              content: this.formatPrice(commonTotalMaterialsNds20Price, false),
              styles: {halign: 'center', valign: 'middle'}
            },
            {
              content: this.formatPrice(commonTotalPrice, false),
              styles: {halign: 'center', valign: 'middle'}
            }
          ])

          pdfFile.autoTable({
            theme: 'grid',
            startY: positionY,
            margin: pdfMargin,
            body: rows,
            bodyStyles: {
              cellPadding: 1,
              lineColor: [0, 0, 0],
              textColor: [0, 0, 0]
            },
            styles: {
              font: 'Microsoft-Sans-Serif',
              fontSize: 9,
            },
            didParseCell: hook => {
              hook.cell.styles.fontSize = hook.row.index > 1 && hook.column.index === 0 ? 7.5 : hook.cell.styles.fontSize
              hook.cell.styles.font = hook.row.index > 1 && hook.column.index === 0
                ? 'Microsoft-Sans-Serif' : hook.cell.styles.font
            },
            didDrawPage: hook => {
              positionY = (hook.cursor.y + pdfRowHeight)
            }
          })

          if (pdfHeight - 10 < positionY) {
            pdfFile.addPage()
            positionY = 10
          }

          pdfFile.setDrawColor(0,0,0,1)
          pdfFile.setFont(pdfFont, pdfFontBold)
          pdfFile.setFontSize(11)
          const mainText = `Выполнено работ на сумму с НДС: ${this.formatPrice(commonTotalPrice, false)}`
          pdfFile.text(mainText, pdfMargin, positionY)
          text = `(${this.priceToText(commonTotalPrice)})`
          pdfFile.setFont(pdfFont, pdfFontNormal)

          if (this.getTextWidth(pdfFile, text) > pdfWidth - this.getTextWidth(pdfFile, mainText) - pdfMargin * 2) {
            const splittedText = pdfFile.splitTextToSize(
              text, pdfWidth - this.getTextWidth(pdfFile, mainText) - pdfMargin * 2
            )
            const splittedTextItemsCount = splittedText.length
            pdfFile.text(splittedText[0], pdfMargin + this.getTextWidth(pdfFile, mainText) + 4, positionY)
            pdfFile.line(pdfMargin, positionY + 1, pdfWidth - pdfMargin, positionY + 1)
            positionY += pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor
            pdfFile.text(splittedText.slice(1), pdfMargin, positionY)
            positionY += 1
            pdfFile.line(pdfMargin, positionY, pdfWidth - pdfMargin, positionY)
          } else {
            pdfFile.text(text, pdfMargin + this.getTextWidth(pdfFile, mainText) + 4, positionY)
            positionY += 1
            pdfFile.line(pdfMargin, positionY, pdfWidth - pdfMargin, positionY)
          }

          if (
            commonTotalMaterialsNds20Price || commonTotalMaterialsNds10Price
            || totalServicesNds10Price || totalServicesNds20Price
          ) {
            if (pdfHeight - 10 < positionY) {
              pdfFile.addPage()
              positionY = 10
            }
            positionY += pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor
            text = 'В том числе НДС:'
            pdfFile.text(text, pdfMargin, positionY)
          }

          if (commonTotalMaterialsNds20Price || commonTotalMaterialsNds10Price) {
            if (pdfHeight - 10 < positionY) {
              pdfFile.addPage()
              positionY = 10
            }
            positionY += pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor
            text = 'на материалы'
            pdfFile.text(text, pdfMargin, positionY)

            if (commonTotalMaterialsNds10Price) {
              if (pdfHeight - 10 < positionY) {
                pdfFile.addPage()
                positionY = 10
              }
              positionY += pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor
              commonTotalMaterialsNds10Price = commonTotalMaterialsNds10Price.toFixed(2)
              text = `НДС 10%: ${commonTotalMaterialsNds10Price} (${this.priceToText(commonTotalMaterialsNds10Price)})`
              pdfFile.text(text, pdfMargin, positionY)
              positionY += 1
              pdfFile.line(pdfMargin, positionY, pdfMargin + this.getTextWidth(pdfFile, text), positionY)
            }

            if (commonTotalMaterialsNds20Price) {
              if (pdfHeight - 10 < positionY) {
                pdfFile.addPage()
                positionY = 10
              }
              positionY += pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor
              commonTotalMaterialsNds20Price = commonTotalMaterialsNds20Price.toFixed(2)
              text = `НДС 20%: ${commonTotalMaterialsNds20Price} (${this.priceToText(commonTotalMaterialsNds20Price)})`
              pdfFile.text(text, pdfMargin, positionY)
              positionY += 1
              pdfFile.line(pdfMargin, positionY, pdfMargin + this.getTextWidth(pdfFile, text), positionY)
            }
          }

          if (totalServicesNds10Price || totalServicesNds20Price) {
            if (pdfHeight - 10 < positionY) {
              pdfFile.addPage()
              positionY = 10
            }
            positionY += pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor
            text = 'на тариф'
            pdfFile.text(text, pdfMargin, positionY)
          }

          if (totalServicesNds10Price) {
            if (pdfHeight - 10 < positionY) {
              pdfFile.addPage()
              positionY = 10
            }
            positionY += pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor
            totalServicesNds10Price = totalServicesNds10Price.toFixed(2)
            text = `НДС 10%: ${totalServicesNds10Price} (${this.priceToText(totalServicesNds10Price)})`
            pdfFile.text(text, pdfMargin, positionY)
            positionY += 1
            pdfFile.line(pdfMargin, positionY, pdfMargin + this.getTextWidth(pdfFile, text), positionY)
          }

          if (totalServicesNds20Price) {
            if (pdfHeight - 10 < positionY) {
              pdfFile.addPage()
              positionY = 10
            }
            positionY += pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor
            totalServicesNds20Price = totalServicesNds20Price.toFixed(2)
            text = `НДС 20%: ${totalServicesNds20Price} (${this.priceToText(totalServicesNds20Price)})`
            pdfFile.text(text, pdfMargin, positionY)
            positionY += 1
            pdfFile.line(pdfMargin, positionY, pdfMargin + this.getTextWidth(pdfFile, text), positionY)
          }

          positionY += pdfRowHeight * 1.5

          if (pdfHeight - 10 < positionY) {
            pdfFile.addPage()
            positionY = 10
          }

          text = 'Исполнитель:'
          pdfFile.text(text, pdfMargin, positionY)
          positionY += 1
          pdfFile.line(pdfMargin, positionY, pdfWidth - pdfMargin, positionY)

          positionY += pdfRowHeight * 1.5
          if (pdfHeight - 10 < positionY) {
            pdfFile.addPage()
            positionY = 10
          }

          text = 'Администратор:'
          pdfFile.text(text, pdfMargin, positionY)
          positionY += 1
          pdfFile.line(pdfMargin, positionY, pdfWidth - pdfMargin, positionY)

          pdfFile.save('Заказ-наряд - ' + patient.full_name + `(${date}).pdf`)
        })
      }
    },
    setActTitle(pdfFile, integrator, treatmentDiary, pdfWidth, pdfCurrentPositionY) {
      pdfFile.text(this.getActTitle(integrator, treatmentDiary), pdfWidth / 2, pdfCurrentPositionY, 'center')
    },
    getActTitle(integrator, treatmentDiary) {
      let title = this.getActNameFormat() === 'invoice-for-payment-of-services'
        ? 'Счет на оплату услуг' : 'Акт оказанных услуг'
      if (this.isActNumberBNFormatEnabledInDocument()) {
        title = title + ' № б/н'
      } else if (treatmentDiary.serial_number) {
        title = title + ' №' + treatmentDiary.serial_number
      }
      return title
    },
    connectToUserSocket() {
      const user = this.getUser()
      const $globalContext = this
      if (user && user.id) {
        let ws = new WebSocket(process.env.VUE_APP_WS_HOST + '/ws/user/' + user.id)
        ws.onopen = function() {
          console.log('client connected to user socket')
          store.commit('auth/SET_USER_SOCKET_CONNECTION', ws)
        };

        ws.onmessage = function(event) {
          const data = event.data ? JSON.parse(event.data) : null
          $globalContext.processUserNotification($globalContext, data)
        };

        ws.onclose = function(event) {
          if (event.code) {
            if (event.code === 1000) {
              console.log('User socket is closed. Client logged out.');
            } else {
              console.log('User socket is closed. Reconnect will be attempted in 10 seconds.', event.reason);
              setTimeout(function() {
                $globalContext.connectToUserSocket();
              }, 10000);
            }
            store.commit('auth/CLEAR_USER_SOCKET_CONNECTION')
          }
        };

        ws.onerror = function(err) {
          console.error('Socket encountered error: ', err.message, 'Closing socket');
          store.commit('auth/CLEAR_USER_SOCKET_CONNECTION')
        };
      }
    },
    processUserNotification($globalContext, data) {
      const message = data && data.message ? data.message : null
      const actionType = data && data.msg_action_type ? data.msg_action_type : null
      if (message && actionType) {
        const chatActions = ['new-chat-message', 'update-chat-message', 'remove-chat-message']
        if (actionType === 'system-notification') {
          systemNotify(message)
        } else if (actionType === 'subscription-expired-notification') {
          subscriptionExpiredNotify(message)
        } else if (actionType === 'online-appointment') {
          $globalContext.onlineAppointmentNotify(message)
        } else if (!chatActions.includes(actionType)) {
          const imageSrc = actionType === 'new-act'
            ? require('@/assets/images/illustration/email.svg')
            : require('@/assets/images/illustration/sales.svg')
          systemNotify({
            id: null,
            text: message,
            imageSrc: imageSrc,
          })
        } else if (actionType === 'new-chat-message') {
          processNewChatMessage(data)
        }
      }
    },
    connectToIntegratorSocket() {
      if (!store.getters['auth/getIntegratorSocketConnection']) {
        const user = this.getUser()
        const $globalContext = this
        if (user && user.id) {
          let ws = new WebSocket(process.env.VUE_APP_WS_HOST + '/ws/integrator/' + user.integrator_id)
          ws.onopen = function() {
            console.log('client connected to integrator socket')
            store.commit('auth/SET_INTEGRATOR_SOCKET_CONNECTION', ws)
          };

          ws.onmessage = function(event) {
            const data = event.data ? JSON.parse(event.data) : null
            const message = data && data.message ? data.message : null
            const messageActionType = data && data.msg_action_type ? data.msg_action_type : null
            if (messageActionType === 'patient-appointment-changes' && user.id !== message.creator_id) {
              store.commit('schedule/SET_NEW_PATIENT_APPOINTMENT_INFO', message)
            }
          };

          ws.onclose = function(event) {
            if (event.code) {
              if (event.code === 1000) {
                console.log('Integrator socket is closed. Client logged out.');
              } else {
                console.log('Integrator socket is closed. Reconnect will be attempted in 10 seconds.', event.reason);
                setTimeout(function() {
                  $globalContext.connectToIntegratorSocket();
                }, 10000);
              }
              store.commit('auth/CLEAR_INTEGRATOR_SOCKET_CONNECTION')
            }
          };

          ws.onerror = function(err) {
            console.error('Socket encountered error: ', err.message, 'Closing socket');
            store.commit('auth/CLEAR_INTEGRATOR_SOCKET_CONNECTION')
          };
        }
      }
    },
    getUser() {
      const localeStorageUser = localStorage.getItem('user')
      return localeStorageUser ? JSON.parse(localeStorageUser) : null
    },
    getUserTimezone() {
      const user = this.getUser()
      return user ? user.timezone : null
    },
    getDocumentSettings() {
      const user = this.getUser()
      return user && user.document_settings ? user.document_settings : null
    },
    isDiagnosisHiddenInDocument() {
      const settings = this.getDocumentSettings()
      return settings && settings.diagnosis_hidden
    },
    isActNumberBNFormatEnabledInDocument() {
      const settings = this.getDocumentSettings()
      return settings && settings.act_number_bn_format_enabled
    },
    getActNameFormat() {
      const settings = this.getDocumentSettings()
      return settings && settings.act_name_format ? settings.act_name_format : null
    },
    getPriceSettings() {
      const user = this.getUser()
      return user && user.price_settings ? user.price_settings : null
    },
    getNumberOfDecimalPlaces() {
      const settings = this.getPriceSettings()
      return settings && settings.number_of_decimal_places
        ? parseInt(settings.number_of_decimal_places) : null
    },
    isRoundingDownEnabled() {
      const settings = this.getPriceSettings()
      return settings && settings.rounding_down_enabled
    },
    isViberApiEnabled() {
      const user = this.getUser()
      return user && user.viber_api_enabled
    },
    isAutoRecalculationWholesaleMarkupEnabled() {
      const settings = this.getPriceSettings()
      return settings && settings.auto_recalculation_wholesale_markup_enabled
    },
    getBaseValue() {
      const settings = this.getPriceSettings()
      return settings && settings.base_value ? parseFloat(settings.base_value) : 0
    },
    getDecreasingCoefficient() {
      const settings = this.getPriceSettings()
      return settings ? settings.decreasing_coefficient : null
    },
    getTreatmentDiarySettings() {
      const user = this.getUser()
      return user && user.treatment_diary_settings ? user.treatment_diary_settings : null
    },
    isProhibitPaymentsEditOlderCurrentDayEnabled() {
      const settings = this.getCashRegisterSettings()
      return settings && settings.prohibit_payments_edit_older_current_day
    },
    getCashRegisterSettings() {
      const user = this.getUser()
      return user && user.cash_register_settings ? user.cash_register_settings : null
    },
    getHiddenCashRegisterSections() {
      const settings = this.getCashRegisterSettings()
      return settings && settings.hidden_sections ? settings.hidden_sections : []
    },
    isComplexFilterByDiagnosisEnabled() {
      const settings = this.getTreatmentDiarySettings()
      return settings && settings.complex_filter_by_diagnosis_enabled
    },
    getEmployeeSettings() {
      const user = this.getUser()
      return user && user.employee_settings ? user.employee_settings : null
    },
    hidePatientPhoneNumberForDoctor() {
      const user = this.getUser()
      const settings = this.getEmployeeSettings()
      return user && (user.role_type === 'doctor') && settings && settings.hide_patient_phone_number_for_doctor
    },
    formatText(text) {
      return text.trim().replaceAll("\r", ' ').replaceAll("\n", ' ')
        .replaceAll("\t", ' ')
    },
    firstLetterIsUppercase: (str) => typeof str !== 'string' || str.length === 0
      ? false : (str[0].toUpperCase() === str[0]),
    addUserDirectionToStorage(data) {
      this.addUserDataToStorageByPrefix('user-calendar-direction', data)
    },
    addUserDoctorsToStorage(data) {
      this.addUserDataToStorageByPrefix('user-calendar-doctors', data)
    },
    addUserCalendarSettingsToStorage(data) {
      this.addUserDataToStorageByPrefix('user-calendar-settings', data)
    },
    addUserCalendarFormDataToStorage(data) {
      this.addUserDataToStorageByPrefix('user-calendar-form-data', data)
    },
    addUserMaterialPriceListSettingsToStorage(data) {
      this.addUserDataToStorageByPrefix('user-material-price-list-settings', data)
    },
    addUserServiceTimeModalSettingsToStorage(data) {
      this.addUserDataToStorageByPrefix('user-service-time-modal-settings', data)
    },
    removeCalendarSettingsInStorage() {
      this.removeItemInStorageByPrefix('user-calendar-settings')
    },
    addUserDataToStorageByPrefix(prefix, data) {
      const userData = getUserData()
      if (userData && userData.id && data) {
        localStorage.setItem(userData.id + '-' + prefix, JSON.stringify(data))
      }
    },
    getUserDirectionFromStorage() {
      return this.getUserDataFromStorageByPrefix('user-calendar-direction')
    },
    getUserDoctorsFromStorage() {
      return this.getUserDataFromStorageByPrefix('user-calendar-doctors')
    },
    getUserCalendarSettingsFromStorage() {
      return this.getUserDataFromStorageByPrefix('user-calendar-settings')
    },
    getUserMaterialPriceListSettingsFromStorage() {
      return this.getUserDataFromStorageByPrefix('user-material-price-list-settings')
    },
    getUserCalendarFormDataFromStorage() {
      return this.getUserDataFromStorageByPrefix('user-calendar-form-data')
    },
    getPatientReceptionScheduleSettings() {
      const user = this.getUser()
      return user && user.patient_reception_schedule_settings ? user.patient_reception_schedule_settings : null
    },
    pastTimePatientsRecording() {
      const settings = this.getPatientReceptionScheduleSettings()
      return settings && settings.past_time_patients_recording
    },
    getUserServiceTimeModalSettingsFromStorage() {
      return this.getUserDataFromStorageByPrefix('user-service-time-modal-settings')
    },
    getUserDataFromStorageByPrefix(prefix) {
      const userData = getUserData()
      if (userData && userData.id) {
        let item = localStorage.getItem(userData.id + '-' + prefix)
        item = item ? JSON.parse(item) : null
        return item ? item : null
      }
      return null
    },
    removeItemInStorageByPrefix(prefix) {
      const userData = getUserData()
      if (userData && userData.id) {
        localStorage.removeItem(userData.id + '-' + prefix)
      }
    },
    userHasProfmanDentalProgramVersion() {
      const userData = getUserData()
      return userData && (userData.program_version === 'profman_dental')
    },
    getUserRoleType() {
      const user = this.getUser()
      return user ? user.role_type : ''
    },
    getPatientContactViewPasswordSendingMethod() {
      const user = this.getUser()
      return user ? user.patient_contact_view_password_sending_method : ''
    },
    positiveNumberFormatter(value) {
      if (value) {
        value = value < 0 ? Math.abs(value).toString() : value
        if (value.charAt(0) === '0' && value.charAt(1) !== '.' && value.length > 1) {
          value = value.slice(1)
        }
      }
      return value
    },
    ceilPositiveNumberFormatter(value) {
      return Math.ceil(this.positiveNumberFormatter(value))
    },
    capitalizeFirstLetter(string) {
      return string ? string.charAt(0).toUpperCase() + string.slice(1) : string
    },
    amountToHumanReadableAmount(amount) {
      if (amount) {
        const ceilPart = Math.trunc(amount)
        const ceilPartLength = ceilPart.toString().length
        const fractionalPart = parseInt((amount % 1).toFixed(2).toString().split('.')[1])
        const fractionalPartLength = fractionalPart.toString().length
        const keysCeilPart = {
          '0_5+': 'белорусских рублей',
          '1': 'белорусский рубль',
          '2_4': 'белорусских рубля',
        }
        const keysFractionalPart = {
          '0_5+': 'копеек',
          '1': 'копейка',
          '2_4': 'копейки',
        }
        const result = [this.amountPartToHumanReadableAmount(ceilPart, ceilPartLength, keysCeilPart)]
        const humanReadableFractionalPartAmount = this.amountPartToHumanReadableAmount(
          fractionalPart, fractionalPartLength, keysFractionalPart
        )
        if (humanReadableFractionalPartAmount) {
          result.push(humanReadableFractionalPartAmount)
        }
        return result.join(' ')
      }
      return '0 белорусских рублей'
    },
    amountPartToHumanReadableAmount(number, numberLength, dict) {
      let result = ''
      if (numberLength === 1) {
        if (![1, 2, 3, 4].includes(number)) {
          result = `${number} ${dict['0_5+']}`
        } else if (number === 1) {
          result = `${number} ${dict['1']}`
        } else {
          result = `${number} ${dict['2_4']}`
        }
      } else {
        const lastTwoNumbers = number.toString().substring(numberLength - 2)
        const lastTwoNumbersAsInt = parseInt(lastTwoNumbers)
        if ([0, 5, 6, 7, 8, 9].includes(number) || (lastTwoNumbersAsInt >= 10 && lastTwoNumbersAsInt <= 20)) {
          result = `${number} ${dict['0_5+']}`
        } else if (lastTwoNumbersAsInt > 20) {
          const lastDigit = parseInt(lastTwoNumbers[1])
          if (![1, 2, 3, 4].includes(lastDigit)) {
            result = `${number} ${dict['0_5+']}`
          } else if (lastDigit === 1) {
            result = `${number} ${dict['1']}`
          } else {
            result = `${number} ${dict['2_4']}`
          }
        } else if (lastTwoNumbersAsInt === 1) {
          result = `${number} ${dict['1']}`
        } else {
          result = `${number} ${dict['2_4']}`
        }
      }
      return result
    },
    currentUserIsRoot() {
      const user = getUserData()
      return user && user.isRoot
    },
    getMaterialPriceFromLots(material, partRate) {
      const lots = material.lots
      partRate = parseFloat(partRate)
      let totalMaterialExistingNumber = 0
      let prevTotalMaterialExistingNumber = 0
      let price = 0
      let count = 0
      for (let i = 0; i < lots.length; i++) {
        prevTotalMaterialExistingNumber = totalMaterialExistingNumber
        totalMaterialExistingNumber += material.partial_consumption_of_material_unit
          ? lots[i].total_number_of_material_unit_type : lots[i].number_of_material
        if (totalMaterialExistingNumber >= partRate) {
          if (i === 0) {
            if (material.partial_consumption_of_material_unit) {
              let number = lots[i].number_of_material_unit_type_per_material_unit
              number = number ? number : 1
              price += lots[i].price_per_unit ? (lots[i].price_per_unit / number) * partRate : 0
            } else {
              price += lots[i].price_per_unit ? lots[i].price_per_unit * partRate : 0
            }
          } else {
            count = partRate - prevTotalMaterialExistingNumber
            if (material.partial_consumption_of_material_unit) {
              let number = lots[i].number_of_material_unit_type_per_material_unit
              number = number ? number : 1
              price += lots[i].price_per_unit ? (lots[i].price_per_unit / number) * count : 0
            } else {
              price += lots[i].price_per_unit ? lots[i].price_per_unit * count : 0
            }
          }
          break;
        } else {
          if (i === lots.length - 1) {
            count = partRate - prevTotalMaterialExistingNumber
          } else {
            count = material.partial_consumption_of_material_unit
              ? lots[i].total_number_of_material_unit_type : lots[i].number_of_material
          }
          if (material.partial_consumption_of_material_unit) {
            let number = lots[i].number_of_material_unit_type_per_material_unit
            number = number ? number : 1
            price += lots[i].price_per_unit ? (lots[i].price_per_unit / number) * count : 0
          } else {
            price += lots[i].price_per_unit ? lots[i].price_per_unit * count : 0
          }
        }
      }
      return price
    },
    getMaterialLotInfo(lot, partial_consumption_of_material_unit, part_rate) {
      const data = {
        id: lot.id,
        price: 0,
        price_per_unit: 0,
        count: part_rate,
        arrival_date: lot.arrival_date,
        delivery_order_number: lot.delivery_order_number,
        nds: lot.nds ? lot.nds.value : null,
        price_per_unit_without_nds: 0,
        price_without_nds: 0
      }
      if (partial_consumption_of_material_unit) {
        const numberOfMaterialUnitTypePerMaterialUnit = lot.number_of_material_unit_type_per_material_unit
          ? lot.number_of_material_unit_type_per_material_unit : 1
        data.price_per_unit = lot.price_per_unit ? lot.price_per_unit / numberOfMaterialUnitTypePerMaterialUnit : 0
        data.price_per_unit_without_nds = lot.price_per_unit_without_nds
          ? lot.price_per_unit_without_nds / numberOfMaterialUnitTypePerMaterialUnit : 0
      } else {
        data.price_per_unit = lot.price_per_unit ? lot.price_per_unit : 0
        data.price_per_unit_without_nds = lot.price_per_unit_without_nds
          ? lot.price_per_unit_without_nds : 0
      }
      data.price = lot.price_per_unit * part_rate
      data.price_without_nds = lot.price_per_unit_without_nds * part_rate
      return data
    },
    priceToText(price) {
      const { format } = require('@vicimpa/rubles')
      let text = format(price)
      const position = text.indexOf('руб')
      return text.substring(0, position) + 'белорусских ' + text.substring(position, text.length)
    },
    getTradeMarginPercent(type, coefficient) {
      const TYPE_MEDICAL_DEVICES = 'medical-devices'
      const TYPE_MEDICAL_EQUIPMENT = 'medical-equipment'
      const TYPE_MEDICINAL_PRODUCTS = 'medicinal-products'
      let percent = 0
      switch (type) {
        case TYPE_MEDICINAL_PRODUCTS:
          if (coefficient <= 0.5) {
            percent = 30
          } else if (coefficient > 0.5 && coefficient <= 1) {
            percent = 25
          } else if (coefficient > 1 && coefficient <= 1.5) {
            percent = 14
          } else if (coefficient > 1.5 && coefficient <= 3) {
            percent = 12
          } else if (coefficient > 3 && coefficient <= 5) {
            percent = 10
          } else if (coefficient > 5 && coefficient <= 10) {
            percent = 5
          } else if (coefficient > 10) {
            percent = 1
          }
          break;
        case TYPE_MEDICAL_DEVICES:
          if (coefficient <= 0.5) {
            percent = 30
          } else if (coefficient > 0.5 && coefficient <= 1) {
            percent = 25
          } else if (coefficient > 1 && coefficient <= 1.5) {
            percent = 21
          } else if (coefficient > 1.5 && coefficient <= 5) {
            percent = 17
          } else if (coefficient > 5) {
            percent = 6
          }
          break;
        case TYPE_MEDICAL_EQUIPMENT:
          if (coefficient <= 1000) {
            percent = 20
          } else if (coefficient > 1000 && coefficient <= 5000) {
            percent = 0
          } else if (coefficient > 5000 && coefficient <= 50000) {
            percent = 0
          } else if (coefficient > 50000 && coefficient <= 100000) {
            percent = 0
          } else if (coefficient > 100000) {
            percent = 0
          }
          break;
      }
      return percent
    },
    getWholesaleMarkupPercent(type, coefficient) {
      const TYPE_MEDICAL_DEVICES = 'medical-devices'
      const TYPE_MEDICAL_EQUIPMENT = 'medical-equipment'
      const TYPE_MEDICINAL_PRODUCTS = 'medicinal-products'
      let percent = 0
      switch (type) {
        case TYPE_MEDICINAL_PRODUCTS:
          if (coefficient <= 0.5) {
            percent = 9
          } else if (coefficient > 0.5 && coefficient <= 1) {
            percent = 8
          } else if (coefficient > 1 && coefficient <= 1.5) {
            percent = 7
          } else if (coefficient > 1.5 && coefficient <= 3) {
            percent = 7
          } else if (coefficient > 3 && coefficient <= 5) {
            percent = 6
          } else if (coefficient > 5 && coefficient <= 10) {
            percent = 4
          } else if (coefficient > 10) {
            percent = 2
          }
          break;
        case TYPE_MEDICAL_DEVICES:
          if (coefficient <= 0.5) {
            percent = 11
          } else if (coefficient > 0.5 && coefficient <= 1) {
            percent = 8
          } else if (coefficient > 1 && coefficient <= 1.5) {
            percent = 8
          } else if (coefficient > 1.5 && coefficient <= 5) {
            percent = 8
          } else if (coefficient > 5) {
            percent = 4
          }
          break;
        case TYPE_MEDICAL_EQUIPMENT:
          if (coefficient <= 1000) {
            percent = 20
          } else if (coefficient > 1000 && coefficient <= 5000) {
            percent = 10
          } else if (coefficient > 5000 && coefficient <= 50000) {
            percent = 8
          } else if (coefficient > 50000 && coefficient <= 100000) {
            percent = 5
          } else if (coefficient > 100000) {
            percent = 3
          }
          break;
      }
      return percent
    }
  },
}
