export default {
  getPatientsPayments: state => state.patients_payments,
  getCashRegisterTotal: state => state.cash_register_total,
  getTotalByLineOfWork: state => state.total_by_line_of_work,
  getTotalBySnapshot: state => state.total_by_snapshot,
  getTotalBySelling: state => state.total_by_selling,
  getTotalByDiscount: state => state.total_by_discount,
  getDebtors: state => state.debtors,
  getInsurances: state => state.insurances,
  getCashlessPayments: state => state.cashlessPayments,
  getCashRegisterSections: state => state.cashRegisterSections && state.cashRegisterSections.length
    ? state.cashRegisterSections : []
}
