import {
  entityContacts, entityDentalservicesreference, entityDiscountcards, entityMaterial, entityPaymentmethod, entityGroup,
  entityTreatmentplans, entityUser, contactsfrom, entityMaterialunittype, entityServiceTime, entityPatientType,
  entityDictionaryPatientTemplate, entityPaymentDirection, entityEmployeeLineOfWork, entityDirection,
  entityInsuranceCompany, entityLegalPerson, entityPatientAppealResult, entityDictionaryNotificationConditionCategory,
  entityEmailSettings, entityDocumentSettings, entityPatientReceptionScheduleSettings, entityCashRegisterSettings,
  entityDictionaryTreatmentFeature, entityDictionaryNds, entityPatientAppointmentStatus, entityIntegratorRoot,
  entityImportExport, entityPriceSettings, entityEmployeeSettings, entityDepartment, entityBeautyServiceType,
  entitySpecialistType, entityPricingTypeValue, entityMaterialPriceList
} from '@/libs/acl/entities'

export default [
  {
    header: 'Dictionary',
    icon: 'StarIcon',
    action: 'read',
    route: 'dictionary',
    children: [
      {
        title: 'Managing',
        action: 'read',
        route: 'managing',
        children: [
          {
            route: 'dictionary-integrator',
            title: 'Organizations',
            resource: entityIntegratorRoot,
            action: 'read',
          },
          {
            route: 'physician-protocol-constructor',
            title: 'Physician protocol constructor',
            resource: entityDictionaryPatientTemplate,
            action: 'read',
          },
          {
            route: 'dictionary-insurance-company',
            title: 'Insurance companies',
            resource: entityInsuranceCompany,
            action: 'read',
          },
          {
            route: 'dictionary-legal-person',
            title: 'Legal persons',
            resource: entityLegalPerson,
            action: 'read',
          },
          {
            route: 'dictionary-roles',
            title: 'Roles',
            resource: entityGroup,
            action: 'read',
          },
          {
            route: 'dictionary-employee',
            title: 'Employee',
            resource: entityUser,
            action: 'read',
          },
          {
            route: 'dictionary-employee-line-of-work',
            title: 'Employees line of work',
            resource: entityEmployeeLineOfWork,
            action: 'read',
          },
          {
            route: 'dictionary-department',
            title: 'Departments',
            resource: entityDepartment,
            action: 'read',
          },
          {
            route: 'dictionary-direction',
            title: 'Directions',
            resource: entityDirection,
            action: 'read',
          },
          {
            route: 'dictionary-specialist-type',
            title: 'Types of specialists',
            resource: entitySpecialistType,
            action: 'read',
          },
          {
            route: 'dictionary-pricing-type-value',
            title: 'Overhead costs',
            resource: entityPricingTypeValue,
            action: 'read',
          },
          {
            route: 'import-export',
            title: 'Import/Export',
            resource: entityImportExport,
            action: 'read',
          },
          {
            route: 'dictionary-notifications',
            title: 'Sending out notifications',
            resource: entityDictionaryNotificationConditionCategory,
            action: 'read',
          },
          {
            route: 'dictionary-user-activity-log',
            title: 'User activity log',
            resource: entityUser,
            action: 'read',
          },
          {
            title: 'Settings',
            icon: 'SettingsIcon',
            resource: entityGroup,
            action: 'read',
            route: 'settings',
            children: [
              {
                title: 'Email settings',
                route: 'dictionary-email-settings',
                resource: entityEmailSettings,
                action: 'read',
              },
              {
                title: 'Document settings',
                route: 'dictionary-document-settings',
                resource: entityDocumentSettings,
                action: 'read',
              },
              {
                title: 'Employee settings',
                route: 'dictionary-employee-settings',
                resource: entityEmployeeSettings,
                action: 'read',
              },
              {
                title: 'Patient reception schedule settings',
                route: 'dictionary-patient-reception-schedule-settings',
                resource: entityPatientReceptionScheduleSettings,
                action: 'read',
              },
              {
                title: 'Cash register settings',
                route: 'dictionary-cash-register-settings',
                resource: entityCashRegisterSettings,
                action: 'read',
              },
              {
                title: 'Modules settings',
                route: 'dictionary-modules-settings',
                resource: entityIntegratorRoot,
                action: 'read',
              },
              {
                title: 'Price settings',
                route: 'dictionary-price-settings',
                resource: entityPriceSettings,
                action: 'read',
              },
            ]
          },
        ]
      },
      {
        title: 'Patients',
        action: 'read',
        route: 'patients-dict',
        children: [
          {
            route: 'patient-type',
            title: 'Patient types',
            resource: entityPatientType,
            action: 'read',
          },
          {
            route: 'dictionary-patient-appeal-result',
            title: 'Patient appeal results',
            resource: entityPatientAppealResult,
            action: 'read',
          },
        ]
      },
      {
        title: 'Revenue',
        action: 'read',
        route: 'revenue-dict',
        children: [
          {
            route: 'dictionary-payment-direction',
            title: 'PaymentDirection',
            resource: entityPaymentDirection,
            action: 'read',
          },
          {
            route: 'dictionary-payment-method',
            title: 'PaymentMethod',
            resource: entityPaymentmethod,
            action: 'read',
          },
          {
            route: 'dictionary-discount-cards',
            title: 'DiscountCards',
            resource: entityDiscountcards,
            action: 'read',
          },
        ]
      },
      {
        title: 'Schedule',
        action: 'read',
        route: 'schedule-dict',
        children: [
          {
            route: 'dictionary-type-contact',
            title: 'TypeContact',
            resource: entityContacts,
            action: 'read',
          },
          {
            route: 'contacts-from',
            title: 'How contacted?',
            resource: contactsfrom,
            action: 'read',
          },
          {
            route: 'service-time',
            title: 'Service time',
            resource: entityServiceTime,
            action: 'read',
          },
          {
            route: 'dictionary-patient-appointment-status',
            title: 'Patient appointment statuses',
            resource: entityPatientAppointmentStatus,
            action: 'read',
          },
        ]
      },
      {
        title: 'Treatment',
        action: 'read',
        route: 'treatment',
        children: [
          {
            route: 'material-price-list',
            title: 'Material price list',
            resource: entityMaterialPriceList,
            action: 'read',
          },
          {
            route: 'dictionary-dental-services',
            title: 'DentalServices',
            resource: entityDentalservicesreference,
            action: 'read',
          },
          {
            route: 'dictionary-beauty-services-type',
            title: 'Services type',
            resource: entityBeautyServiceType,
            action: 'read',
          },
          {
            route: 'dictionary-treatment_plans',
            title: 'Complexes',
            resource: entityTreatmentplans,
            action: 'read',
          },
          {
            route: 'dictionary-treatment-features',
            title: 'Treatment features',
            resource: entityDictionaryTreatmentFeature,
            action: 'read',
          },
          {
            route: 'dictionary-materials',
            title: 'Materials',
            resource: entityMaterial,
            action: 'read',
          },
          {
            route: 'dictionary-units',
            title: 'Units',
            resource: entityMaterialunittype,
            action: 'read',
          },
          {
            route: 'dictionary-nds',
            title: 'Nds',
            resource: entityDictionaryNds,
            action: 'read',
          },
        ]
      },
    ]
  }
]
