import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import axiosIns from '@axios'
import VueViewer from 'v-viewer'

import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'
import './mixins'

// jsPdf Fonts
import './js-pdf-fonts'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/vue-select'

// Axios Mock Adapter
import '@/@fake-db/db'

// mixins
import { mixins } from '@/mixins'
import { pdfMixins } from '@/pdf-mixins'
import { patientAppointmentCalendarMixins } from '@/patient-appointment-calendar-mixins'
import { patientMixins } from '@/patient-mixins'
import { treatmentDiaryMixins } from '@/treatment-diary-mixins'

// directives
import {mouseoverClass} from '@/views/directives/mouseover-class'
import Constants from '@/constants/constants'

// VueViewer
Vue.component('viewer', VueViewer)
Vue.use(VueViewer)
require('viewerjs/dist/viewer.css')

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

Vue.mixin(mixins)
Vue.mixin(pdfMixins)
Vue.mixin(patientAppointmentCalendarMixins)
Vue.mixin(patientMixins)
Vue.mixin(treatmentDiaryMixins)

Vue.directive('mouseover-class', mouseoverClass)

axiosIns.interceptors.request.use(
    config => {
      const token = localStorage.getItem("token");
      if (token) {
        config.headers.common["Authorization"] = token;
      }
        config.headers.common["Access-Control-Allow-Origin"] = "*";
      return config;
    },
    error => {
      return Promise.reject(error);
    }
);

axiosIns.interceptors.response.use(response => response, function (error) {
  if (error.response && error.response.status === 401) {
    store.dispatch('auth/logout')
    router.push('/login')
  }
  return Promise.reject(error)
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  provide: {
    appConstants: Constants
  },
}).$mount('#app')
