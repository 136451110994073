import chatConstants from './chatConstants'
import routerConstants from './routerConstants'
import patientAppointmentConstants from './patientAppointmentConstants'
import roleConstants from './roleConstants'
import patientCalendarConstants from './patientCalendarConstants'

export default {
  chat: chatConstants,
  router: routerConstants,
  patientAppointment: patientAppointmentConstants,
  role: roleConstants,
  patientCalendar: patientCalendarConstants,
}
