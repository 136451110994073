import Vue from 'vue'

export default {
  // Nds type
  SET_NDS_TYPE (state, nds) {
    state.nds = nds
  },
  EDIT_NDS_TYPE (state, nds) {
    const index = state.nds.findIndex(u => u.id === nds.id)
    state.nds[index].value = nds.value
  },
  ADD_NDS_TYPE (state, nds) {
    state.nds.push(nds)
  },
  // Units type
  SET_UNITS_TYPE (state, units) {
    state.units = units
  },
  EDIT_UNIT_TYPE (state, unit) {
    const index = state.units.findIndex(u => u.id === unit.id)
    state.units[index].short_name = unit.short_name
    state.units[index].full_name = unit.full_name
  },
  ADD_UNIT_TYPE (state, unit) {
    state.units.push(unit)
  },
  REMOVE_UNIT_TYPE (state, unitId) {
    const index = state.units.findIndex(unit => unit.id === unitId)
    state.units.splice(index, 1)
  },
  // Discount Cards
  SET_DISCOUNTS_CARDS (state, cards) {
    state.discountCardsList = cards
  },
  EDIT_DISCOUNTS_CARDS (state, card) {
    const index = state.discountCardsList.findIndex(c => c.id === card.id)
    state.discountCardsList[index].name = card.name
    state.discountCardsList[index].percent = card.percent
  },
  ADD_DISCOUNT_CARD (state, card) {
    state.discountCardsList.push(card)
  },
  REMOVE_DISCOUNT_CARD (state, cardId) {
    const index = state.discountCardsList.findIndex(card => card.id === cardId)
    state.discountCardsList.splice(index, 1)
  },
  // Payment Method
  SET_PAYMENT_METHODS (state, methods) {
    state.paymentMethods = methods
  },
  EDIT_PAYMENT_METHOD (state, method) {
    const index = state.paymentMethods.findIndex(m => m.id === method.id)
    state.paymentMethods[index].name = method.name
    state.paymentMethods[index].extended_category = method.extended_category
  },
  ADD_PAYMENT_METHOD (state, method) {
    state.paymentMethods.push(method)
  },
  REMOVE_PAYMENT_METHOD (state, methodId) {
    const index = state.paymentMethods.findIndex(method => method.id === methodId)
    state.paymentMethods.splice(index, 1)
  },
  SET_PAYMENT_METHOD_EXTENDED_CATEGORIES (state, categories) {
    state.paymentMethodExtendedCategories = categories
  },
  // Payment Direction
  SET_PAYMENT_DIRECTION (state, directions) {
    state.paymentDirections = directions
  },
  SET_PAYMENT_DIRECTION_TYPES (state, directionTypes) {
    state.paymentDirectionTypes = directionTypes
  },
  REMOVE_PAYMENT_DIRECTION (state, paymentDirectionId) {
    const index = state.paymentDirections.findIndex(paymentDirection => paymentDirection.id === paymentDirectionId)
    state.paymentDirections.splice(index, 1)
  },
  EDIT_PAYMENT_DIRECTION (state, paymentDirection) {
    const index = state.paymentDirections.findIndex(pd => pd.id === paymentDirection.id)
    state.paymentDirections[index].name = paymentDirection.name
    state.paymentDirections[index].type = paymentDirection.type
  },
  ADD_PAYMENT_DIRECTION (state, paymentDirection) {
    state.paymentDirections.push(paymentDirection)
  },
  // Contacts
  SET_CONTACTS (state, contacts) {
    state.contacts = contacts
  },
  EDIT_CONTACT (state, contact) {
    const index = state.contacts.findIndex(c => c.id === contact.id)
    state.contacts[index].name = contact.name
  },
  ADD_CONTACT (state, contact) {
    state.contacts.push(contact)
  },
  REMOVE_CONTACT (state, contactId) {
    const index = state.contacts.findIndex(contact => contact.id === contactId)
    state.contacts.splice(index, 1)
  },
  // Employee
  SET_EMPLOYEE (state, employee) {
    state.employee = employee
  },
  SET_EMPLOYEE_BY_CLINIC_PER_PAGE(state, items) {
    state.employeeByClinicPerPage = items
  },
  SET_EMPLOYEE_BY_CLINIC_PER_PAGE_COUNT(state, count) {
    state.employeeByClinicPerPageCount = count
  },
  EDIT_EMPLOYEE (state, employee) {
    const index = state.employee.findIndex(e => e.id === employee.id)
    Object.keys(employee).forEach(key => {
      state.employee[index][key] = employee[key]
    })
  },
  ADD_EMPLOYEE (state, employee) {
    state.employee.push(employee)
  },
  REMOVE_EMPLOYEE (state, employeeId) {
    const index = state.employee.findIndex(employee => employee.id === employeeId)
    state.employee.splice(index, 1)
  },
  // Employee
  SET_ROLES (state, roles) {
    state.roles = roles
  },
  EDIT_ROLE (state, role) {
    const index = state.roles.findIndex(e => e.id === role.id)
    state.roles[index].name = role.name
  },
  ADD_ROLE (state, role) {
    state.roles.push(role)
  },
  REMOVE_ROLE (state, roleId) {
    const index = state.roles.findIndex(role => role.id === roleId)
    state.roles.splice(index, 1)
  },
  // Permissions
  SET_PERMISSIONS (state, permissions) {
    state.permissions = permissions
  },
  SET_PERMISSIONS_FOR_USER (state, permissions) {
    state.user_permissions = permissions
  },
  emptyAbilities(state) {
    state.abilities = []
  },
  setRootAbilities(state) {
    state.abilities = [{ action: 'manage',subject: 'all' }]
  },
  appendAbilities(state, abilities) {
    state.abilities = state.abilities.concat(abilities)
  },
  setAbilities(state, permissions) {
    const abilityByEntity = {}
    permissions.forEach(permission => {
      const splitPermission = permission.split('_')
      if (!abilityByEntity.hasOwnProperty(splitPermission[1])) {
        abilityByEntity[splitPermission[1]] = []
      }
      let action = ''
      switch (splitPermission[0]) {
        case 'add':
          action = 'create'
          break
        case 'delete':
          action = 'delete'
          break
        case 'change':
          action = 'update'
          break
        case 'view':
          action = 'read'
          break
      }
      abilityByEntity[splitPermission[1]].push(action)
    })
    Object.keys(abilityByEntity).forEach(entity => {
      abilityByEntity[entity].forEach(action => {
        state.abilities.push({
          action: action,
          subject: entity
        })
      })
    })
  },
  // Materials
  SET_MATERIAL_GROUPS (state, groups) {
    state.materialGroups = groups.map(group => {
      group.expanded = false
      group.materials = []
      return group
    })
  },
  SET_MATERIALS_BY_GROUP(state, data) {
    const index = state.materialGroups.findIndex(g => g.id === data.groupId)
    state.materialGroups[index].expanded = true
    state.materialGroups[index].materials = data.list.results
    state.materialGroups[index].count = data.list.count
  },
  material_group_added (state, group) {
    group.expanded = false
    group.materials = []
    state.materialGroups.push(group)
  },
  material_group_edited (state, group) {
    const index = state.materialGroups.findIndex(g => g.id === group.id)
    state.materialGroups[index].name = group.name
  },
  MATERIAL_GROUP_DELETED(state, group) {
    const index = state.materialGroups.findIndex(g => g.id === group.id)
    state.materialGroups.splice(index, 1)
  },
  material_edited (state, material) {
    const indexGroup = state.materialGroups.findIndex(g => g.id === material.material_group_id)
    const indexMaterial = state.materialGroups[indexGroup].materials.findIndex(m => material.id)
    if (indexMaterial) {
      state.materialGroups[indexGroup].materials[indexMaterial] = material
    } else {
      state.materialGroups[indexGroup].materials.push(material)
    }
  },
  MATERIAL_DELETED(state, material) {
    const indexGroup = state.materialGroups.findIndex(g => g.id === material.material_group_id)
    const indexMaterial = state.materialGroups[indexGroup].materials.findIndex(m => m.id === material.id)
    state.materialGroups[indexGroup].materials.splice(indexMaterial, 1)
  },
  MATERIAL_GROUP_HIDDEN(state, group) {
    const indexGroup = state.materialGroups.findIndex(g => g.id === group.id)
    state.materialGroups[indexGroup].expanded = false
  },
  MATERIAL_LOT_ADDED(state, item) {
    state.materialLotsPerPage.push(item)
    state.materialLotsPerPageCount += 1
  },
  MATERIAL_LOT_EDITED(state, item) {
    const index = state.materialLotsPerPage.findIndex(element => element.id === item.id)
    Vue.set(state.materialLotsPerPage, index, item)
  },
  SET_MATERIAL_LOTS(state, items) {
    state.materialLotsPerPage = items
  },
  SET_MATERIAL_LOTS_COUNT(state, count) {
    state.materialLotsPerPageCount = count
  },
  MATERIAL_LOT_DELETED(state, item) {
    const index = state.materialLotsPerPage.findIndex(lot => lot.id === item.id)
    state.materialLotsPerPage.splice(index, 1)
    state.materialLotsPerPageCount -= 1
  },
  MATERIAL_LOT_CANCEL_PLACEMENT(state, data) {
    const idx = state.materialLotsPerPage.findIndex(item => item.id === data.id)
    state.materialLotsPerPage[idx].posted = false
  },
  MATERIAL_LOT_PLACE(state, data) {
    const idx = state.materialLotsPerPage.findIndex(item => item.id === data.id)
    state.materialLotsPerPage[idx].posted = true
  },
  SET_LOT_WITH_MATERIAL_ITEMS(state, items) {
    state.lotWithMaterialItems = items
  },
  SET_LOT_WITH_MATERIAL_ITEMS_COUNT(state, count) {
    state.lotWithMaterialItemsCount = count
  },
  // Diagnosis
  SET_DIAGNOSIS_GROUPS (state, groups) {
    state.diagnosisGroupsList = groups
  },
  DIAGNOSIS_DELETE(state, diagnosisId) {
    const index = state.diagnosis.findIndex(d => d.id === diagnosisId)
    state.diagnosis.splice(index, 1)
  },
  SET_DIAGNOSIS_GROUPS_MAIN (state, groups) {
    state.diagnosisGroupsMain = groups.map(g => {
      g.children = []
      return g
    })
  },
  SET_DIAGNOSIS_SUB_GROUPS (state, groups) {
    state.diagnosisSubGroups = groups
  },
  SET_DIAGNOSIS_GROUPS_ALL (state, groups) {
    state.diagnosisGroupsAll = groups
  },
  diagnosis_group_added (state, group) {
    if (group.parent_group_id === null) {
      state.diagnosisGroupsMain.push(group)
    } else {
      state.diagnosisSubGroups.push(group)
    }
  },
  diagnosis_group_edited (state, group) {
    const index = state.diagnosisGroupsMain.findIndex(g => g.id === group.id)
    if (
      index !== -1
      && state.diagnosisGroupsMain[index].parent_group_id === null
      && group.parent_group_id !== null
    ) {
      state.diagnosisGroupsMain.splice(index, 1)
    } else if (index !== -1) {
      state.diagnosisGroupsMain[index].name = group.name
      state.diagnosisGroupsMain[index].parent_group_id = group.parent_group_id
    } else if (index === -1 && group.parent_group_id === null) {
      state.diagnosisGroupsMain.push(group)
    }
  },
  DIAGNOSIS_GROUP_DELETED(state, group) {
    if (group.parent_group_id) {
      const index = state.diagnosisSubGroups.findIndex(g => g.id === group.id)
      state.diagnosisSubGroups.splice(index, 1)
    } else {
      const index = state.diagnosisGroupsMain.findIndex(g => g.id === group.id)
      state.diagnosisGroupsMain.splice(index, 1)
    }
  },
  SET_DIAGNOSIS (state, diagnosis) {
    state.diagnosis = diagnosis
  },
  SET_PATIENT_TEMPLATES_SEARCH (state, templates) {
    state.searchTemplates = templates
  },
  SET_PATIENT_TEMPLATES_SEARCH_TOTAL_COUNT (state, count) {
    state.searchTemplatesCount = count
  },
  set_diagnosis_total_count(state, count) {
    state.diagnosisCount = count
  },
  SET_PLANS (state, plans) {
    state.plansList = plans
  },
  SET_PLANS_COUNT (state, count) {
    state.plansCount = count
  },
  set_plan_to_edit(state, plan) {
    state.planToEdit = plan
  },
  set_plan_to_edit_data(state, data) {
    state.planToEdit = data
  },
  empty_plan_to_edit(state) {
    state.planToEdit = null
  },
  // Dental services
  SET_PATIENT_TEMPLATES_GROUPS_MAIN (state, groups) {
    state.patientCardTemplateMainGroups = groups.map(g => {
      g.children = []
      return g
    })
  },
  SET_PATIENT_TEMPLATES_GROUPS (state, groups) {
    state.patientCardTemplateGroups = groups
  },
  SET_SERVICES_GROUPS_MAIN (state, groups) {
    state.servicesGroupsMain = groups.map(g => {
      g.children = []
      return g
    })
  },
  SET_SERVICES_GROUPS (state, groups) {
    state.servicesGroups = groups
  },
  SET_SERVICES (state, services) {
    state.services = services
  },
  SET_SERVICE_TYPES (state, serviceTypes) {
    state.serviceTypes = serviceTypes
  },
  SET_DENTAL_SERVICES_WITH_TIME(state, items) {
    state.dentalServicesWithTimePerPage = items
  },
  SET_DENTAL_SERVICES_WITH_TIME_TOTAL_COUNT(state, count) {
    state.dentalServicesWithTimePerPageCount = count
  },
  SET_SERVICES_TOTAL_COUNT(state, count) {
    state.servicesCount = count
  },
  SET_ALL_SERVICES_GROUPS(state, services) {
    state.servicesGroupsAll = services
  },
  service_up(state, service) {
    const idx = state.services.findIndex(s => s.id === service.id)
    if (idx === 0) {
      return;
    }
    state.services.splice(idx - 1, 0, state.services.splice(idx, 1)[0])
  },
  service_down(state, service) {
    const idx = state.services.findIndex(s => s.id === service.id)
    if (idx === state.services.length) {
      return;
    }
    state.services.splice(idx + 1, 0, state.services.splice(idx, 1)[0])
  },
  service_group_added(state, group) {
    if (group.parent_group_id) {
      state.servicesGroups.push(group)
    } else {
      state.servicesGroupsMain.push(group)
    }
  },
  ADD_PATIENT_TEMPLATE(state, group) {
    if (group.parent_group_id) {
      state.patientCardTemplateGroups.push(group)
    } else {
      state.patientCardTemplateMainGroups.push(group)
    }
  },
  edit_patient_template(state, service) {
    const index = state.patientCardTemplateGroups.findIndex(s => s.id === service.id)
    if (index !== -1) {
      Object.keys(state.patientCardTemplateGroups[index]).forEach(key => {
        state.patientCardTemplateGroups[index][key] = service[key]
      })
    } else {
      state.patientCardTemplateGroups.push(service)
    }
  },
  TEMPLATE_PATIENT_DELETE(state, patientCardTemplateId) {
    const index = state.patientCardTemplateGroups.findIndex(tg => tg.id === patientCardTemplateId)
    state.patientCardTemplateGroups.splice(index, 1)
  },
  service_group_edited(state, group) {
    const index = state.servicesGroupsMain.findIndex(g => g.id === group.id)
    if (
      index !== -1
      && state.servicesGroupsMain[index].parent_group_id === null
      && group.parent_group_id !== null
    ) {
      state.servicesGroupsMain.splice(index, 1)
    } else if (index !== -1) {
      state.servicesGroupsMain[index].name = group.name
      state.servicesGroupsMain[index].parent_group_id = group.parent_group_id
    } else if (index === -1 && group.parent_group_id === null) {
      state.servicesGroupsMain.push(group)
    }
  },
  service_added(state, service) {
    state.services.push(service)
  },
  service_edited(state, service) {
    const index = state.services.findIndex(s => s.id === service.id)
    if (index !== -1) {
      Object.keys(state.services[index]).forEach(key => {
        state.services[index][key] = service[key]
      })
    } else {
      state.services.push(service)
    }
  },
  DENTAL_SERVICE_DELETE(state, dentalServiceId) {
    const index = state.services.findIndex(s => s.id === dentalServiceId)
    state.services.splice(index, 1)
  },
  SERVICE_GROUP_DELETED(state, group) {
    if (group.parent_group_id) {
      const index = state.servicesGroups.findIndex(g => g.id === group.id)
      state.servicesGroups.splice(index, 1)
    } else {
      const index = state.servicesGroupsMain.findIndex(g => g.id === group.id)
      state.servicesGroupsMain.splice(index, 1)
    }
  },
  // Bites
  SET_BITES (state, bites) {
    state.bitesList = bites
  },
  bite_added(state, bite) {
    state.bitesList.push(bite)
  },
  bite_edited(state, bite) {
    const index = state.bitesList.findIndex(b => b.id === bite.id)
    state.bitesList[index].name = bite.name
  },
  bite_removed(state, biteId) {
    const index = state.bitesList.findIndex(bite => bite.id === biteId)
    state.bitesList.splice(index, 1)
  },
  // Plans
  PLAN_REMOVED(state, planId) {
    const index = state.plansList.findIndex(plan => plan.id === planId)
    state.plansList.splice(index, 1)
  },
  plan_added(state, plan) {
    state.plansList.push(plan)
  },
  plan_edited(state, plan) {
    const index = state.plansList.findIndex(p => p.id === plan.id)
    Object.keys(state.plansList[index]).forEach(key => {
      state.plansList[index][key] = plan[key]
    })
  },
  SET_PLANS_WITH_TIME(state, items) {
    state.plansWithTimePerPage = items
  },
  SET_PLANS_WITH_TIME_TOTAL_COUNT(state, count) {
    state.plansWithTimePerPageCount = count
  },
  setIntegrators(state, integrators) {
    state.integrators = integrators
  },
  VIBER_CHAT_ENABLE(state, id) {
    const idx = state.integrators.findIndex(item => item.id === id)
    state.integrators[idx].viber_api_enabled = true
  },
  VIBER_CHAT_DISABLE(state, id) {
    const idx = state.integrators.findIndex(item => item.id === id)
    state.integrators[idx].viber_api_enabled = false
  },
  setServiceTime(state, serviceTime) {
    state.serviceTime = serviceTime
  },
  setServiceTimes(state, serviceTime) {
    state.serviceTimes = serviceTime
  },
  setPatientType(state, patientType) {
    state.patientType = patientType
  },
  setPatientTypes(state, patientType) {
    state.patientTypes = patientType
  },
  setIntegratorsCount(state, count) {
    state.integratorsCount = count
  },
  setServiceTimeCount(state, count) {
    state.serviceTimeCount = count
  },
  setPatientTypeCount(state, count) {
    state.patientTypeCount = count
  },
  SET_INTEGRATOR(state, integrator) {
    state.integrator = integrator
  },
  setReserve(state, reserve) {
    state.reserve = reserve
  },
  emptyIntegrator(state) {
    state.integrator = null
  },
  emptyServiceTime(state) {
    state.serviceTime = null
  },
  emptyPatientType(state) {
    state.patientType = null
  },
  INTEGRATOR_ADDED(state, integrator) {
    state.integrators.push(integrator)
  },
  INTEGRATOR_EDITED(state, integrator) {
    const index = state.integrators.findIndex(element => element.id === integrator.id)
    Vue.set(state.integrators, index, integrator)
  },
  PLUS_INTEGRATOR_SMS_NOTIFICATION_BALANCE(state, item) {
    const index = state.integrators.findIndex(element => element.id === item.id)
    state.integrators[index].sms_notification_balance = item.sms_notification_balance
  },
  serviceTimeAdded(state, serviceTime) {
    state.serviceTimes.push(serviceTime)
  },
  patientTypeAdded(state, patientType) {
    state.patientTypes.push(patientType)
  },
  integratorDeleted(state, id) {
    state.integrators.splice(
      state.integrators.findIndex(item => id === item.id),
      1
    )
  },
  serviceTimeDeleted(state, id) {
    state.serviceTimes.splice(
      state.serviceTimes.findIndex(item => id === item.id),
      1
    )
  },
  serviceTimeEdited(state, serviceTime) {
    const index = state.serviceTimes.findIndex(element => element.id === serviceTime.id)
    Vue.set(state.serviceTimes, index, serviceTime)
  },
  patientTypeEdited(state, patientType) {
    const index = state.patientTypes.findIndex(element => element.id === patientType.id)
    Vue.set(state.patientTypes, index, patientType)
  },
  PATIENT_TYPE_DELETE(state, id) {
    const index = state.patientTypes.findIndex(item => item.id === id)
    state.patientTypes.splice(index, 1)
  },
  setItems(state, config) {
    state[config.key] = config.data
  },
  itemUpdated(state, config) {
    const index = state[config.key].findIndex(element => element.id === config.data.id)
    Vue.set(state[config.key], index, config.data)
  },
  itemAdded(state, config) {
    state[config.key].push(config.data)
  },
  itemRemoved(state, config) {
    state[config.key].splice(
      state[config.key].findIndex(item => config.data.id === item.id),
      1
    )
  },
  emptyItem(state, config) {
    state[config.key] = null
  },
  setItem(state, config) {
    state[config.key] = config.data
  },
  // Advisory Opinion
  SET_ADVISORY_OPINION(state, advisoryOpinions) {
    state.advisoryOpinions = advisoryOpinions
  },
  EDIT_ADVISORY_OPINION(state, advisoryOpinion) {
    const index = state.advisoryOpinions.findIndex(item => item.id === advisoryOpinion.id)
    state.advisoryOpinions[index] = advisoryOpinion
  },
  ADD_ADVISORY_OPINION(state, advisoryOpinion) {
    state.advisoryOpinions.push(advisoryOpinion)
  },
  SET_ADVISORY_OPINION_COUNT(state, count) {
    state.advisoryOpinionsCount = count
  },
  ADVISORY_OPINION_DELETE(state, advisoryOpinionId) {
    const index = state.advisoryOpinions.findIndex(ad => ad.id === advisoryOpinionId)
    state.advisoryOpinions.splice(index, 1)
  },
  // INSURANCE COMPANIES
  INSURANCE_COMPANY_ADDED(state, insuranceCompany) {
    state.insuranceCompanies.push(insuranceCompany)
    state.insuranceCompaniesCount += 1
  },
  INSURANCE_COMPANY_EDITED(state, insuranceCompany) {
    const index = state.insuranceCompanies.findIndex(element => element.id === insuranceCompany.id)
    Vue.set(state.insuranceCompanies, index, insuranceCompany)
  },
  INSURANCE_COMPANY_DELETE(state, insuranceCompanyId) {
    const index = state.insuranceCompanies.findIndex(ad => ad.id === insuranceCompanyId)
    state.insuranceCompanies.splice(index, 1)
    state.insuranceCompaniesCount -= 1
  },
  SET_INSURANCE_COMPANIES(state, insuranceCompanies) {
    state.insuranceCompanies = insuranceCompanies
  },
  SET_INSURANCE_COMPANIES_COUNT(state, count) {
    state.insuranceCompaniesCount = count
  },
  // Notification condition category
  SET_NOTIFICATION_CONDITION_CATEGORIES_PER_PAGE(state, items) {
    state.notificationConditionCategoriesPerPage = items
  },
  SET_NOTIFICATION_CONDITION_CATEGORIES(state, items) {
    state.notificationConditionCategories = items
  },
  SET_NOTIFICATION_CONDITION_CATEGORIES_COUNT(state, count) {
    state.notificationConditionCategoriesTotalCount = count
  },
  NOTIFICATION_CONDITION_CATEGORY_ADD(state, item) {
    state.notificationConditionCategoriesPerPage.push(item)
    state.notificationConditionCategoriesTotalCount += 1
  },
  NOTIFICATION_CONDITION_CATEGORY_EDIT(state, item) {
    const index = state.notificationConditionCategoriesPerPage.findIndex(element => element.id === item.id)
    Vue.set(state.notificationConditionCategoriesPerPage, index, item)
  },
  NOTIFICATION_CONDITION_CATEGORY_DELETE(state, id) {
    const index = state.notificationConditionCategoriesPerPage.findIndex(ad => ad.id === id)
    state.notificationConditionCategoriesPerPage.splice(index, 1)
    state.notificationConditionCategoriesTotalCount -= 1
  },
  // Legal person
  SET_LEGAL_PERSONS(state, items) {
    state.legalPersons = items
  },
  SET_LEGAL_PERSONS_COUNT(state, count) {
    state.legalPersonsCount = count
  },
  LEGAL_PERSON_ADD(state, item) {
    state.legalPersons.push(item)
    state.legalPersonsCount += 1
  },
  LEGAL_PERSON_EDIT(state, item) {
    const index = state.legalPersons.findIndex(element => element.id === item.id)
    Vue.set(state.legalPersons, index, item)
  },
  LEGAL_PERSON_DELETE(state, id) {
    const index = state.legalPersons.findIndex(item => item.id === id)
    state.legalPersons.splice(index, 1)
    state.legalPersonsCount -= 1
  },
  // Contacts from
  SET_CONTACTS_FROM(state, items) {
    state.contactsFromList = items
  },
  // Patient appeal result
  SET_PATIENT_APPEAL_RESULTS(state, items) {
    state.patientAppealResults = items
  },
  SET_PATIENT_APPEAL_RESULTS_PER_PAGE(state, items) {
    state.patientAppealResultsPerPage = items
  },
  SET_PATIENT_APPEAL_RESULT_COUNT(state, count) {
    state.patientAppealResultsCount = count
  },
  PATIENT_APPEAL_RESULT_ADD(state, item) {
    state.patientAppealResultsPerPage.push(item)
    state.patientAppealResultsCount += 1
  },
  PATIENT_APPEAL_RESULT_EDIT(state, item) {
    const index = state.patientAppealResultsPerPage.findIndex(element => element.id === item.id)
    Vue.set(state.patientAppealResultsPerPage, index, item)
  },
  PATIENT_APPEAL_RESULT_DELETE(state, id) {
    const index = state.patientAppealResultsPerPage.findIndex(item => item.id === id)
    state.patientAppealResultsPerPage.splice(index, 1)
    state.patientAppealResultsCount -= 1
  },
  // Physician protocol fields
  PHYSICIAN_PROTOCOL_FIELDS_SET(state, items) {
    state.physicianProtocolFields = items
  },
  PHYSICIAN_PROTOCOL_FIELD_ADDED(state, item) {
    state.physicianProtocolFields.push(item)
    state.physicianProtocolFields = state.physicianProtocolFields.sort(
      (a, b) => a.name.toLowerCase() > b.name.toLowerCase()
        ? 1 : (b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0)
    )
  },
  PHYSICIAN_PROTOCOL_FIELD_EDIT(state, item) {
    const index = state.physicianProtocolFields.findIndex(element => element.id === item.id)
    Vue.set(state.physicianProtocolFields, index, item)
  },
  PHYSICIAN_PROTOCOL_FIELD_DELETE(state, id) {
    const index = state.physicianProtocolFields.findIndex(item => item.id === id)
    state.physicianProtocolFields.splice(index, 1)
    state.physicianProtocolFieldValues = []
  },
  // Physician protocol field values
  PHYSICIAN_PROTOCOL_FIELD_VALUES_SET(state, items) {
    state.physicianProtocolFieldValues = items
  },
  PHYSICIAN_PROTOCOL_FIELD_VALUE_ADDED(state, item) {
    state.physicianProtocolFieldValues.push(item)
    state.physicianProtocolFieldValues = state.physicianProtocolFieldValues.sort(
      (a, b) => a.value.toLowerCase() > b.value.toLowerCase()
        ? 1 : (b.value.toLowerCase() > a.value.toLowerCase() ? -1 : 0)
    )
  },
  PHYSICIAN_PROTOCOL_FIELD_VALUE_EDIT(state, item) {
    const index = state.physicianProtocolFieldValues.findIndex(element => element.id === item.id)
    Vue.set(state.physicianProtocolFieldValues, index, item)
  },
  PHYSICIAN_PROTOCOL_FIELD_VALUE_DELETE(state, id) {
    const index = state.physicianProtocolFieldValues.findIndex(item => item.id === id)
    state.physicianProtocolFieldValues.splice(index, 1)
  },
  // Physician protocol section
  PHYSICIAN_PROTOCOL_SECTIONS_SET(state, items) {
    state.physicianProtocolSections = items
  },
  PHYSICIAN_PROTOCOL_SECTION_ADDED(state, item) {
    state.physicianProtocolSections.push(item)
    state.physicianProtocolSections = state.physicianProtocolSections.sort(
      (a, b) => a.name.toLowerCase() > b.name.toLowerCase()
        ? 1 : (b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0)
    )
  },
  PHYSICIAN_PROTOCOL_SECTION_EDIT(state, item) {
    const index = state.physicianProtocolSections.findIndex(element => element.id === item.id)
    Vue.set(state.physicianProtocolSections, index, item)
  },
  PHYSICIAN_PROTOCOL_SECTION_DELETE(state, id) {
    const index = state.physicianProtocolSections.findIndex(item => item.id === id)
    state.physicianProtocolSections.splice(index, 1)
    state.physicianProtocolSubsections = []
  },
  // Physician protocol subsection
  PHYSICIAN_PROTOCOL_SUBSECTIONS_SET(state, items) {
    state.physicianProtocolSubsections = items
  },
  PHYSICIAN_PROTOCOL_SUBSECTION_ADDED(state, item) {
    state.physicianProtocolSubsections.push(item)
    state.physicianProtocolSubsections = state.physicianProtocolSubsections.sort(
      (a, b) => a.name.toLowerCase() > b.name.toLowerCase()
        ? 1 : (b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0)
    )
  },
  PHYSICIAN_PROTOCOL_SUBSECTION_EDIT(state, item) {
    const index = state.physicianProtocolSubsections.findIndex(element => element.id === item.id)
    Vue.set(state.physicianProtocolSubsections, index, item)
  },
  PHYSICIAN_PROTOCOL_SUBSECTION_DELETE(state, id) {
    const index = state.physicianProtocolSubsections.findIndex(item => item.id === id)
    state.physicianProtocolSubsections.splice(index, 1)
  },
  // Physician protocol
  PHYSICIAN_PROTOCOLS_SET_PER_PAGE(state, items) {
    state.physicianProtocolPerPage = items
  },
  PHYSICIAN_PROTOCOLS_SET_PER_PAGE_COUNT(state, count) {
    state.physicianProtocolPerPageCount = count
  },
  PHYSICIAN_PROTOCOL_DELETE(state, id) {
    const index = state.physicianProtocolPerPage.findIndex(item => item.id === id)
    state.physicianProtocolPerPage.splice(index, 1)
  },
  // Analyses Synevo
  ANALYSES_SYNEVO_SET_PER_PAGE(state, items) {
    state.analysesSynevoPerPage = items
  },
  ANALYSES_SYNEVO_SET_PER_PAGE_COUNT(state, count) {
    state.analysesSynevoPerPageCount = count
  },
  // Treatment features
  SET_TREATMENT_FEATURES_PER_PAGE(state, items) {
    state.treatmentFeaturesPerPage = items
  },
  SET_TREATMENT_FEATURES_PER_PAGE_COUNT(state, count) {
    state.treatmentFeaturesPerPageCount = count
  },
  TREATMENT_FEATURE_ADD(state, item) {
    state.treatmentFeaturesPerPage.push(item)
    state.treatmentFeaturesPerPageCount += 1
  },
  TREATMENT_FEATURE_EDIT(state, item) {
    const index = state.treatmentFeaturesPerPage.findIndex(element => element.id === item.id)
    Vue.set(state.treatmentFeaturesPerPage, index, item)
  },
  TREATMENT_FEATURE_DELETE(state, id) {
    const index = state.treatmentFeaturesPerPage.findIndex(item => item.id === id)
    state.treatmentFeaturesPerPage.splice(index, 1)
    state.treatmentFeaturesPerPageCount -= 1
  },
  SET_TREATMENT_FEATURES_FOR_DROPDOWN(state, items) {
    state.treatmentFeaturesForDropdown = items
  },
  // User service for time property
  SET_USER_SERVICE_FOR_TIME_PROPERTY_PER_PAGE(state, items) {
    state.userServiceForTimePropertyPerPage = items
  },
  SET_USER_SERVICE_FOR_TIME_PROPERTY_PER_PAGE_COUNT(state, count) {
    state.userServiceForTimePropertyPerPageCount = count
  },
  USER_SERVICE_FOR_TIME_PROPERTY_ADD(state, item) {
    state.userServiceForTimePropertyPerPage.push(item)
    state.userServiceForTimePropertyPerPageCount += 1
  },
  USER_SERVICE_FOR_TIME_PROPERTY_EDIT(state, item) {
    const index = state.userServiceForTimePropertyPerPage.findIndex(element => element.id === item.id)
    Vue.set(state.userServiceForTimePropertyPerPage, index, item)
  },
  USER_SERVICE_FOR_TIME_PROPERTY_DELETE(state, id) {
    const index = state.userServiceForTimePropertyPerPage.findIndex(item => item.id === id)
    state.userServiceForTimePropertyPerPage.splice(index, 1)
    state.userServiceForTimePropertyPerPageCount -= 1
  },
  // User dental service property
  SET_USER_DENTAL_SERVICE_PROPERTY_PER_PAGE(state, items) {
    state.userDentalServicePropertyPerPage = items
  },
  SET_USER_DENTAL_SERVICE_PROPERTY_PER_PAGE_COUNT(state, count) {
    state.userDentalServicePropertyPerPageCount = count
  },
  USER_DENTAL_SERVICE_PROPERTY_ADD(state, item) {
    state.userDentalServicePropertyPerPage.push(item)
    state.userDentalServicePropertyPerPageCount += 1
  },
  USER_DENTAL_SERVICE_PROPERTY_EDIT(state, item) {
    const index = state.userDentalServicePropertyPerPage.findIndex(element => element.id === item.id)
    Vue.set(state.userDentalServicePropertyPerPage, index, item)
  },
  USER_DENTAL_SERVICE_PROPERTY_DELETE(state, id) {
    const index = state.userDentalServicePropertyPerPage.findIndex(item => item.id === id)
    state.userDentalServicePropertyPerPage.splice(index, 1)
    state.userDentalServicePropertyPerPageCount -= 1
  },
  // User treatment plan property
  SET_USER_TREATMENT_PLAN_PROPERTY_PER_PAGE(state, items) {
    state.userTreatmentPlanPropertyPerPage = items
  },
  SET_USER_TREATMENT_PLAN_PROPERTY_PER_PAGE_COUNT(state, count) {
    state.userTreatmentPlanPropertyPerPageCount = count
  },
  USER_TREATMENT_PLAN_PROPERTY_ADD(state, item) {
    state.userTreatmentPlanPropertyPerPage.push(item)
    state.userTreatmentPlanPropertyPerPageCount += 1
  },
  USER_TREATMENT_PLAN_PROPERTY_EDIT(state, item) {
    const index = state.userTreatmentPlanPropertyPerPage.findIndex(element => element.id === item.id)
    Vue.set(state.userTreatmentPlanPropertyPerPage, index, item)
  },
  USER_TREATMENT_PLAN_PROPERTY_DELETE(state, id) {
    const index = state.userTreatmentPlanPropertyPerPage.findIndex(item => item.id === id)
    state.userTreatmentPlanPropertyPerPage.splice(index, 1)
    state.userTreatmentPlanPropertyPerPageCount -= 1
  },
  // User activity log items
  SET_USER_ACTIVITY_LOG_ITEMS_PER_PAGE(state, items) {
    state.userActivityLogItemsPerPage = items
  },
  SET_USER_ACTIVITY_LOG_ITEMS_PER_PAGE_COUNT(state, count) {
    state.userActivityLogItemsPerPageCount = count
  },
  // Pricing type groups
  SET_PRICING_TYPE_GROUPS(state, items) {
    state.pricingTypeGroups = items
  },
  ADD_PRICING_TYPE_GROUP(state, item) {
    state.pricingTypeGroups.push(item)
    state.pricingTypeGroups = state.pricingTypeGroups.sort((a, b) => a.name.localeCompare(b.name))
  },
  EDIT_PRICING_TYPE_GROUP(state, item) {
    const index = state.pricingTypeGroups.findIndex(group => group.id === item.id)
    Vue.set(state.pricingTypeGroups, index, item)
    state.pricingTypeGroups = state.pricingTypeGroups.sort((a, b) => a.name.localeCompare(b.name))
  },
  REMOVE_PRICING_TYPE_GROUP(state, id) {
    const index = state.pricingTypeGroups.findIndex(item => item.id === id)
    state.pricingTypeGroups.splice(index, 1)
  },
  SET_PRICING_TYPE_GROUP_ITEMS(state, items) {
    state.pricingTypeGroupItems = items
  },
  // Patient appointment status
  SET_PATIENT_APPOINTMENT_STATUS_PER_PAGE(state, items) {
    state.patientAppointmentStatusesPerPage = items
  },
  SET_PATIENT_APPOINTMENT_STATUS_PER_PAGE_COUNT(state, count) {
    state.patientAppointmentStatusesPerPageCount = count
  },
  SET_PATIENT_APPOINTMENT_STATUS(state, items) {
    state.patientAppointmentStatuses = items
  },
  REMOVE_PATIENT_APPOINTMENT_STATUS(state, id) {
    const index = state.patientAppointmentStatusesPerPage.findIndex(item => item.id === id)
    state.patientAppointmentStatusesPerPage.splice(index, 1)
    state.patientAppointmentStatusesPerPageCount -= 1
  },
  EDIT_PATIENT_APPOINTMENT_STATUS(state, item) {
    const index = state.patientAppointmentStatusesPerPage.findIndex(pa => pa.id === item.id)
    Vue.set(state.patientAppointmentStatusesPerPage, index, item)
  },
  ADD_PATIENT_APPOINTMENT_STATUS(state, item) {
    state.patientAppointmentStatusesPerPage.push(item)
    state.patientAppointmentStatusesPerPageCount += 1
  },
  EDIT_PATIENT_APPOINTMENT_STATUS_IS_MAIN_ATTR(state, data) {
    state.patientAppointmentStatusesPerPage.map(app => app.is_main = data.id === app.id ? data.is_main : false)
  },
  // FAQ
  SET_FAQ_QUESTIONS(state, items) {
    state.faqQuestions = items
  },
  ADD_FAQ_QUESTION(state, item) {
    state.faqQuestions.push(item)
    state.faqQuestions = state.faqQuestions.sort((a, b) => a.title.localeCompare(b.title))
  },
  EDIT_FAQ_QUESTION(state, item) {
    const index = state.faqQuestions.findIndex(pd => pd.id === item.id)
    Vue.set(state.faqQuestions, index, item)
  },
  FAQ_QUESTION_DELETE(state, id) {
    const index = state.faqQuestions.findIndex(item => item.id === id)
    state.faqQuestions.splice(index, 1)
  },
  // Beauty service type
  SET_BEAUTY_SERVICE_TYPE_PER_PAGE: (state, items) => state.beautyServiceTypePerPage = items,
  SET_BEAUTY_SERVICE_TYPE_PER_PAGE_COUNT: (state, count) => state.beautyServiceTypePerPageCount = count,
  ADD_BEAUTY_SERVICE_TYPE(state, item) {
    state.beautyServiceTypePerPage.push(item)
    state.beautyServiceTypePerCount += 1
  },
  EDIT_BEAUTY_SERVICE_TYPE(state, item) {
    const index = state.beautyServiceTypePerPage.findIndex(d => d.id === item.id)
    Vue.set(state.beautyServiceTypePerPage, index, item)
  },
  REMOVE_BEAUTY_SERVICE_TYPE(state, itemId) {
    const index = state.beautyServiceTypePerPage.findIndex(item => item.id === itemId)
    state.beautyServiceTypePerPage.splice(index, 1)
    state.beautyServiceTypePerCount -= 1
  },
  // Specialist type
  SET_SPECIALIST_TYPES: (state, items) => state.specialistTypes = items,
  SET_SPECIALIST_TYPES_PER_PAGE: (state, items) => state.specialistTypesPerPage = items,
  SET_SPECIALIST_TYPES_PER_PAGE_COUNT: (state, count) => state.specialistTypesPerPageCount = count,
  ADD_SPECIALIST_TYPE(state, direction) {
    state.specialistTypesPerPage.push(direction)
    state.specialistTypesPerPageCount += 1
  },
  EDIT_SPECIALIST_TYPE(state, item) {
    const index = state.specialistTypesPerPage.findIndex(d => d.id === item.id)
    Vue.set(state.specialistTypesPerPage, index, item)
  },
  REMOVE_SPECIALIST_TYPE(state, itemId) {
    const index = state.specialistTypesPerPage.findIndex(direction => direction.id === itemId)
    state.specialistTypesPerPage.splice(index, 1)
    state.specialistTypesPerPageCount -= 1
  },
  // Service specialist type property
  SET_SERVICE_SPECIALIST_TYPE_PROPERTY_PER_PAGE(state, items) {
    state.serviceSpecialistTypePerPage = items
  },
  SET_SERVICE_SPECIALIST_TYPE_PROPERTY_PER_PAGE_COUNT(state, count) {
    state.serviceSpecialistTypePerPageCount = count
  },
  SERVICE_SPECIALIST_TYPE_PROPERTY_ADD(state, item) {
    state.serviceSpecialistTypePerPage.push(item)
    state.serviceSpecialistTypePerPageCount += 1
  },
  SERVICE_SPECIALIST_TYPE_PROPERTY_EDIT(state, item) {
    const index = state.serviceSpecialistTypePerPage.findIndex(element => element.id === item.id)
    Vue.set(state.serviceSpecialistTypePerPage, index, item)
  },
  SERVICE_SPECIALIST_TYPE_PROPERTY_DELETE(state, id) {
    const index = state.serviceSpecialistTypePerPage.findIndex(item => item.id === id)
    state.serviceSpecialistTypePerPage.splice(index, 1)
    state.serviceSpecialistTypePerPageCount -= 1
  },
  // Material price list
  UPDATE_MATERIAL_PRICE_LIST(state, item) {
    const index = state.materialPriceList.findIndex(priceList => priceList.id === -1 || priceList.id === item.id)
    if (index > -1) {
      state.materialPriceList[index] = item
    }
  },
  SET_MATERIAL_PRICE_LIST(state, items) {
    state.materialPriceList = items
  },
  DELETE_MATERIAL_PRICE_LIST(state, id) {
    const index = state.materialPriceList.findIndex(item => item.id === id)
    state.materialPriceList.splice(index, 1)
  },
  SET_MATERIAL_PRICE_LIST_ITEMS(state, items) {
    state.materialPriceListItems = items
  },
  SET_MATERIAL_PRICE_LIST_ITEMS_COUNT(state, count) {
    state.materialPriceListItemsCount = count
  },
  UPDATE_MATERIAL_PRICE_LIST_ITEM(state, item) {
    const index = state.materialPriceListItems.findIndex(priceListItem => priceListItem.id === item.id)
    if (index > -1) {
      Vue.set(state.materialPriceListItems, index, item)
    }
  },
}
