export const pdfMixins = {
  methods: {
    getServicesTableTitleForActOfServicesRendered(clinicName) {
      return 'Настоящий акт составлен о том, что «' + clinicName +
        '» оказали, а Заказчик принял услуги.'
    },
    textCenter(doc, text, y) {
      let textOffset = (doc.internal.pageSize.width - this.getTextWidth(doc, text)) / 2
      doc.text(textOffset, y, text)
    },
    getTextWidth(doc, text) {
      return text ? doc.getStringUnitWidth(text) * doc.internal.getFontSize() / doc.internal.scaleFactor : 0
    }
  }
}
