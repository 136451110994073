export default {
  // Reports
  SET_REPORT_FORM_039(state, reports) {
    state.reportForm039 = reports
  },
  SET_REPORT_FORM_037(state, reports) {
    state.reportForm037 = reports
  },
  // Outpatient Surgeries
  SET_OUTPATIENT_SURGERIES(state, items) {
    state.outpatientSurgeries = items
  },
}
