import {entityPatientOutpatientSurgery, entityPatient} from '@/libs/acl/entities'

export default [
  {
    path: '/reports/form-039',
    name: 'report-form-039',
    component: () => import('@/views/reports/ReportForm039'), meta: {
      resource: entityPatient,
      action: 'read',
      title: 'ReportForm039'
    }
  },
  {
    path: '/reports/form-037',
    name: 'report-form-037',
    component: () => import('@/views/reports/ReportForm037'), meta: {
      resource: entityPatient,
      action: 'read',
      title: 'ReportForm037'
    }
  },
  {
    path: '/reports/outpatient-surgeries',
    name: 'outpatient-surgeries',
    component: () => import('@/views/reports/OutpatientSurgeries'), meta: {
      resource: entityPatientOutpatientSurgery,
      action: 'read',
      title: 'Outpatient surgeries'
    }
  },
  {
    path: '/reports/employee',
    name: 'report-employee',
    component: () => import('@/views/reports/ReportEmployee'),
  },
  {
    path: '/reports/financial',
    name: 'report-financial',
    component: () => import('@/views/reports/ReportFinancial'),
  },
  {
    path: '/reports/metrics',
    name: 'report-metrics',
    component: () => import('@/views/reports/ReportMetrics'),
  },
  {
    path: '/reports/dynamics',
    name: 'report-dynamics',
    component: () => import('@/views/reports/ReportDynamics'),
  },
]
