import { patientMixins } from '@/patient-mixins'
import { mixins } from '@/mixins'

export const patientAppointmentCalendarMixins = {
  methods: {
    getTitle(schedule) {
      const doctor = schedule.doctor ? '[Мастер: ' + schedule.doctor.full_name + ']' : ''
      const age = patientMixins.methods.getPatientAge(schedule.patient)
      const patient = schedule.patient
        ? schedule.patient.full_name + ' - №' + schedule.patient.patient_card_number + (age ? ' - ' + age : '')
        : ''
      const phone = (
        !mixins.methods.hidePatientPhoneNumberForDoctor() && schedule.patient && schedule.patient.full_phone
        && (
          ['root', 'sys_admin'].includes(mixins.methods.getUserRoleType())
          || !mixins.methods.getPatientContactViewPasswordSendingMethod()
        )
      )
        ? 'Телефон: ' + schedule.patient.full_phone : ''
      const note = schedule.note ? 'Примечание: ' + schedule.note : ''
      const phrases = []

      if (doctor) {
        phrases.push(doctor)
      }

      if (patient) {
        phrases.push(patient)
      }

      if (phone) {
        phrases.push(phone)
      }

      if (note) {
        phrases.push(note)
      }

      return phrases.join(' ')
    },
    maskPhone(phone) {
      let result = ''
      try {
        [...phone].forEach(l => result += l)
      } catch (e) {
        result = ''
      }
      return result
    },
    getTimeInterval() {
      const settings = mixins.methods.getUserCalendarSettingsFromStorage()
      return settings && settings.timeInterval ? settings.timeInterval : 10
    },
    getTimeStages(integrator) {
      let minutesFrom = 480 // in minutes 08:00
      let minutesTo = 1310 // in minutes 21:50
      if (integrator) {
        minutesFrom = integrator.work_time_from ? parseInt(integrator.work_time_from) : minutesFrom
        minutesTo = integrator.work_time_to ? parseInt(integrator.work_time_to) : minutesTo
      }
      const interval = patientAppointmentCalendarMixins.methods.getTimeInterval()
      const oneHour = 60
      let currentStage = minutesFrom
      const formattedStages = []
      while(currentStage <= (minutesTo + interval)) {
        const currentStageHours = Math.ceil(currentStage / oneHour)
        const currentStageMinutes = currentStage % oneHour
        let currentStageAsNumber = 0;
        let formattedCurrentStage = '';
        if (currentStageMinutes > 0) {
          formattedCurrentStage = (currentStageHours - 1) + ':' + (
            currentStageMinutes < 10 ? '0' + currentStageMinutes : currentStageMinutes
          )
          currentStageAsNumber = ((currentStageHours - 1) * oneHour) + currentStageMinutes;
        } else {
          formattedCurrentStage = currentStageHours + ':' + currentStageMinutes + '0'
          currentStageAsNumber = (currentStageHours * oneHour) + currentStageMinutes;
        }
        currentStage += interval
        formattedStages.push({
          current_stage_as_text: formattedCurrentStage,
          current_stage_as_number: currentStageAsNumber
        })
      }
      return formattedStages
    }
  }
}
