import {entityDentalStatusTeeth, entityPatient, entityTreatmentdiarydentalservices} from '@/libs/acl/entities'

export default [
  {
    path: '/apps/patient_cards',
    name: 'patient-cards',
    component: () => import('@/views/patient_cards/ListPatient'),
    meta: {
      resource: entityPatient,
      action: 'read',
      title: 'Patients'
    }
  },
  {
    path: '/apps/patient_cards/patient-view/:patientId',
    name: 'app-user-view',
    component: () => import('@/views/patient_cards/PatientView.vue'),
    meta: {
      breadcrumb: [
        { title: 'Главная', url: '/' },
        { title: 'Список клиентов', url: '/apps/patient_cards' },
        { title: 'Клиент:', extendedTitle: true, active: true },
        { title: 'Просмотр', active: true }
      ],
      pageTitle: 'Просмотр карточки клиента',
      rule: 'editor',
      resource: entityPatient,
      action: 'read',
      title: 'Patient card view'
    }
  },
  {
    path: '/apps/patient_cards/patient-create',
    name: 'app-user-create',
    component: () => import('@/views/patient_cards/create/PatientCreate.vue'),
    props: true,
    meta: {
      breadcrumb: [
        { title: 'Главная', url: '/' },
        { title: 'Список клиентов', url: '/apps/patient_cards' },
        { title: 'Создать карточку', active: true }
      ],
      pageTitle: 'Создание карточки клиента',
      rule: 'editor',
      resource: entityPatient,
      action: 'create',
      title: 'Patient card creation'
    }
  },
  {
    path: '/apps/patient_cards/patient-edit/:patientId',
    name: 'app-user-edit',
    component: () => import('@/views/patient_cards/create/PatientCreate.vue'),
    meta: {
      breadcrumb: [
        { title: 'Главная', url: '/' },
        { title: 'Список клиентов', url: '/apps/patient_cards' },
        { title: 'Клиент:', extendedTitle: true, active: true },
        { title: 'Изменить карточку', active: true }
      ],
      pageTitle: 'Изменить карточку',
      rule: 'editor',
      resource: entityPatient,
      action: 'update',
      title: 'Patient card edit'
    }
  },
  {
    path: '/apps/patient_cards/patient-edit/:patientId/dental-status-add',
    name: 'app-dental-status-add',
    component: () => import('@/views/patient_cards/create/PatientDentalStatusTabForm.vue'),
    meta: {
      breadcrumb: [
        // { title: 'Главная', url: '/' },
        // { title: 'Список клиентов', url: '/apps/patient_cards' },
        // { title: 'Добавить стоматологический статус', active: true }
      ],
      pageTitle: 'Добавить стоматологический статус',
      rule: 'editor',
      resource: entityDentalStatusTeeth,
      action: 'create',
      title: 'Patient dental status creation'
    }
  },
  {
    path: '/apps/patient_cards/patient-edit/:patientId/dental-status-edit',
    name: 'app-dental-status-edit',
    component: () => import('@/views/patient_cards/create/PatientDentalStatusTabForm.vue'),
    props: true,
    meta: {
      breadcrumb: [],
      pageTitle: 'Редактировать стоматологический статус',
      rule: 'editor',
      resource: entityDentalStatusTeeth,
      action: 'create',
      title: 'Patient dental status edit'
    }
  },
  {
    path: '/apps/patient_cards/patient-edit/:patientId/services-rendered-add',
    name: 'app-services-rendered-add',
    component: () => import('@/views/patient_cards/create/services-rendered/ServicesRenderedForm.vue'),
    meta: {
      breadcrumb: [],
      pageTitle: 'Добавить оказанные услуги',
      rule: 'add',
      resource: entityTreatmentdiarydentalservices,
      action: 'create',
      title: 'Patient act of services rendered creation'
    }
  },
  {
    path: '/apps/patient_cards/patient-edit/:patientId/dental-treatment-diary-medicine-add',
    name: 'app-treatment-diary-medicine-add',
    component: () => import('@/views/patient_cards/create/treatment_diary_medicine/TreatmentDiaryMedicineForm'),
    meta: {
      breadcrumb: [],
      pageTitle: 'Добавить оказанные услуги',
      rule: 'add',
      resource: entityTreatmentdiarydentalservices,
      action: 'create',
      title: 'Patient act of services rendered creation'
    }
  },
  {
    path: '/apps/patient_cards/patient-edit/:patientId/services-rendered-edit',
    name: 'app-services-rendered-edit',
    component: () => import('@/views/patient_cards/create/services-rendered/ServicesRenderedForm.vue'),
    props: true,
    meta: {
      breadcrumb: [],
      pageTitle: 'Редактирование акта оказанных услуг',
      rule: 'add',
      resource: entityTreatmentdiarydentalservices,
      action: 'update',
      title: 'Patient act of services rendered edit'
    }
  },
  {
    path: '/apps/patient_cards/patient-edit/:patientId/dental-treatment-diary-medicine-edit',
    name: 'app-treatment-diary-medicine-edit',
    component: () => import('@/views/patient_cards/create/treatment_diary_medicine/TreatmentDiaryMedicineForm.vue'),
    props: true,
    meta: {
      breadcrumb: [],
      pageTitle: 'Редактирование акта оказанных услуг',
      rule: 'add',
      resource: entityTreatmentdiarydentalservices,
      action: 'update',
      title: 'Patient act of services rendered edit'
    }
  }
]
