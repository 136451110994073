export default {
  // Analytics -> Revenue
  SET_ANALYTICS_REVENUE_TOTAL_BY_DIRECTIONS(state, data) {
    state.analyticsRevenueTotalByDirections = data
  },
  // Analytics -> Patient appointment schedule
  SET_ANALYTICS_PATIENT_APPOINTMENT_SCHEDULE_TOTAL_BY_HOW_DID_KNOW(state, data) {
    state.analyticsPatientAppointmentScheduleTotalByHowDidKnow = data
  },
  SET_ANALYTICS_PATIENT_APPOINTMENT_SCHEDULE_TOTAL_BY_HOW_TO_CONTACT(state, data) {
    state.analyticsPatientAppointmentScheduleTotalByHowToContact = data
  },
  SET_ANALYTICS_PATIENT_APPOINTMENT_SCHEDULE_TOTAL_BY_STATUSES(state, data) {
    state.analyticsPatientAppointmentScheduleTotalByStatuses = data
  },
  // Analytics -> Patients
  SET_ANALYTICS_PATIENTS_TOTAL_BY_DENTAL_SERVICES(state, data) {
    state.analyticsPatientsTotalByDentalServices = data
  },
  SET_ANALYTICS_PATIENTS_TOTAL_PATIENTS_COUNT(state, data) {
    state.analyticsPatientsTotalPatientsCount = data
  },
  // Analytics -> Leads
  SET_ANALYTICS_LEADS_INFO(state, data) {
    state.analyticsLeadsInfo = data
  },
}
